@import "src/variables";

@mixin header() {
	margin: 0;
	padding: 0 0 10px 0;
	color: $main-black;
	text-align: center;
	letter-spacing: 0.02em;
	font-size: 29px;
	font-family: YesevaOne-Regular, serif;
}
.radioButton{
	opacity: 0;
    position: absolute;
	 z-index: -1 !important
}

.top-op-header{
	position: fixed;
    width: 99%;
    float: left;
    background: #fff;
    z-index: 15;
}

.section-pi{
	margin-top: 140px;
}

.top-op-imgprocess{
	width: 100%;
	float: left;
}

.imgActive{
	opacity: 1;
}

.imgNonActive{
	opacity: 0.5;
}

.help-link{
	color: #333 !important;
}

.order-process-component {
	position: relative;
	flex: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-family: "Avenir-Medium", serif;

	.steps-content-buttons-wrapper {
		flex: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.steps {
			flex: none;
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			// padding: 30px 0 50px 0;

			.step-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;

				&.ready {
					.step {
						background: #aaaaaa;
					}

					.description {
						color: #aaaaaa;
					}
				}

				&.active,
				&.ready.active {
					.step {
						background: $main-gold;

						&.doctorConsult {
							background: url("../../static/images/_doctor_gold.png") no-repeat center center;
							background-size: contain;
						}
					}

					.description {
						color: $main-gold;
					}
				}

				.step {
					width: 45px;
					height: 25px;
					background: #aaaaaa;
					display: inline-block;
					@media (max-width: 455px) {
						width: 35px;
						height: 20px;
					}

					&.cart {
						mask: url("../../static/images/_cart.svg") no-repeat center center;
					}

					&.account {
						mask: url("../../static/images/_account.svg") no-repeat center center;
					}

					&.shipping {
						mask: url("../../static/images/_shipping.svg") no-repeat center center;
					}

					&.payment {
						mask: url("../../static/images/_payment.svg") no-repeat center center;
					}

					&.identification {
						mask: url("../../static/images/_identification.svg") no-repeat center center;
					}

					&.doctorConsult {
						background: url("../../static/images/_doctor_gray.png") no-repeat center center;
						background-size: contain;
					}

					&.confirm {
						mask: url("../../static/images/_confirm.svg") no-repeat center center;
					}

					@media (max-width: 370px) {
						width: 36px;
						height: 20px;
						padding-left: 2px;
					}
				}

				.description {
					display: block;
					font-size: 11px;
					text-align: center;
					padding: 8px 0 0 0;
					color: #aaaaaa;
					@media (max-width: 455px) {
						font-size: 9px;
					}
				}
			}

			img {
				height: auto;

				&.arrow-right {
					width: 15px;
					@media (max-width: 455px) {
						width: 12px;
						height: 20px;
					}
				}
			}
		}

		.content {
			flex: auto;
			margin: 0 0 0px 0;

			.header {
				@include header;
			}

			.cart-summary {
				margin-top: 20px;
				margin-bottom: 60px;
			}

			.alert {
				display: flex;
				align-items: center;
				justify-content: left;
				height: 25px;
				color: #e26161;
				font-size: 12.8px;
			}

			.content-sub-alert {
				height: calc(100% - 25px);

				.radio-input-container {
					font-family: "Avenir-Heavy", serif;
					display: flex;
					flex-direction: row-reverse;
					justify-content: flex-end;
					min-height: 30px;
					align-items: center;
					color: #000000;
					cursor: pointer;
					width: 100%;
					overflow: hidden;
					padding: 5px 0;
					line-height: 1.2em;
					font-size: 14px;

					.add-card-title {
						font-size: 14px;
						color: #000000;
					}

					input {
						position: absolute;
						opacity: 0;
						height: 0;
						width: 0;
						font-family: "Avenir-Heavy", serif;

						&:-webkit-autofill {
							-webkit-box-shadow: 0 0 0 1000px #ffffff inset;
							box-shadow: 0 0 0 1000px #ffffff inset;
						}

						&:checked ~ .checkmark {
							background: $main-gold;
						}
					}

					.checkmark {
						flex: none;
						height: 13px;
						width: 13px;
						background-color: #f0f0f0;
						border-radius: 50%;
						border: 1px solid #a7a7a7;
						margin-left: 0;

						&::after {
							content: "";
							position: absolute;
							display: none;
						}
					}

					span {
						margin-right: 0.5em;
						margin-left: 0.5em;
					}
				}

				.selected-info {
					text-align: center;
					font-size: 16px;
					line-height: 25px;
					color: $main-black;
					font-family: Avenir-Book, serif;
				}

				.subheader {
					font-size: 18px;
					line-height: 25px;
					color: $main-black;
					font-family: Avenir-Heavy, serif;
					margin: 20px 0 15px 0;
				}

				.text {
					font-family: Avenir-Book, serif;
					color: $main-black;
					font-size: 16px;
					line-height: 22px;
				}

				.shipping-method {
					font-family: Avenir-Book, serif;
					color: $main-black;
					font-size: 18px;
				}

				.new-shipping-address-container {
					margin-top: 20px;
				}

				// .shipping-addresses-wrapper {
				// 	border-bottom: 1px solid $main-gold;
				// }

				.billing-cards-wrapper {
					border-bottom: 1px solid $main-gold;
					margin-top: 20px;
				}
			}
		}

		.back-next-buttons {
			flex: none;
			display: block;
			margin-top: 40px;
			padding-bottom: 100px;

			// button {
			// 	font-family: "Avenir-Medium", serif;
			// 	letter-spacing: 0.1em;
			// 	font-size: 16px;
			// 	line-height: 18px;
			// 	padding: 0;
			// 	margin: 0;
			// 	outline: none;
			// 	cursor: pointer;
			// 	box-sizing: border-box;
			// 	flex: 1;
			// 	height: 50px;
			// 	border-width: 2px;
			// 	border-style: solid;
			// 	border-radius: 0;
			// 	border-color: $main-gold;

			// 	&.light {
			// 		background-color: #ffffff;
			// 		color: $main-gold;
			// 	}

			// 	&.dark {
			// 		background-color: $main-gold;
			// 		color: #ffffff;
			// 	}

			// 	&.checkout {
			// 		position: fixed;
			// 		bottom: 19px;
			// 		width: 85%;
			// 		background-color: $main-gold;
			// 		color: #ffffff;
			// 		height: 60px;
			// 		padding: 0 50px;
			// 		align-items: center;
			// 		border: none;
			// 		outline: none;
			// 		font-family: "Avenir-Book", serif;
			// 		font-weight: 300;
			// 		font-size: 26px;
			// 		line-height: 26px;
			// 		cursor: pointer;
			// 		text-transform: uppercase;
			// 		-webkit-backface-visibility: hidden;
			// 		backface-visibility: hidden;
			// 		@media (max-width: 450px) {
			// 			width: 65%;
			// 		}
			// 		@media (max-width: 320px) {
			// 			width: 60%;
			// 			padding: 0;
			// 		}
			// 	}
			// }
		}
	}

	.success-upload-id {
		display: flex;
		justify-content: flex-start;
		color: #4bb543;
		width: 100%;
		font-size: 20px;
		margin: 10px 0;

		img {
			height: 20px;
			margin: 0 10px;
		}
	}

	.privacy-policy-block {
		font-family: Avenir-Book, serif;
		font-size: 14px;
		color: $main-text-gray;
		margin-top: 25px;
		line-height: 22px;

		a {
			color: $main-text-gray;
			font-weight: bold;
		}

		&.gray2 {
			color: $main-text-gray-2;
		}
	}

	&__title {
		margin: 5px 0 0 0;
		font-family: "Avenir-Heavy", serif !important;
		font-size: 18px !important;
		line-height: 25px !important;
		color: $main-black !important;

		&_icon {
			display: flex;
			justify-content: space-between;

			span {
				font-family: Avenir-Book, serif;
				line-height: 25px;
				font-size: 18px;
				color: #64676c;
				display: inline-flex;
				align-items: center;

				img {
					margin: 0 12px 0 0;
					position: relative;
					top: -1.5px;
				}
			}
		}
	}

	&__addBillingCard {
		margin: 4px 0 0 0;
	}
}
