@import "../../variables";

.right-area {
	box-sizing: border-box;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 15;
	top: 0;
	left: 0;
	flex-direction: row;
	opacity: 0;
	display: flex;
	background: #ffffff;
	visibility: hidden;
	font-family: "Avenir-Medium", serif;

	&.shown {
		opacity: 100;
		visibility: visible;
	}

	@media (max-width: 559px) {
		top: 0;
		height: 100vh;
		&.extra-height {
			top: 0;
			height: 100vh;
		}
	}

	.left {
		background: #050402;
		width: calc(50vw);
		height: calc(100vh);
		padding: 0;
		overflow: hidden;
		@media (max-width: 959px) {
			display: none;
		}

		.shop {
			color: $light-gold;
			cursor: pointer;
			position: fixed;
			top: 0;
			left: 0;
			margin-left: 5%;
			margin-top: 27px;
			text-decoration: none;
			font-family: "Avenir-Heavy", serif;

			&:hover {
				text-decoration: underline;
			}
		}

		.left-images {
			display: flex;
			flex-direction: column;
			height: 100%;

			&.nonstandart {
				@media (min-width: 1651px) {
					flex-direction: row-reverse;
					.man-img {
						height: 100%;
					}
				}
			}

			.man-img {
				flex: none;

				img {
					width: 100%;
				}

				.menuWideImg {
					display: none;
					@media (max-width: 1650px) {
						display: block;
					}
				}

				.menuNarrowImg {
					display: block;
					width: auto;
					height: 100%;
					@media (max-width: 1650px) {
						display: none;
					}
				}
			}

			.logo-img {
				flex: auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin: 10px 0;

				img {
					height: 135px;
					max-height: 100%;
				}
			}
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		width: calc(50vw - 100px);
		height: auto;
		padding: 50px;
		overflow: auto;
		border-left: 0;
		position: relative;

		@media (max-width: 1240px) {
			padding: 25px;
			width: calc(50vw - 50px);
		}
		@media (max-width: 959px) {
			width: 100vw;
			padding: 35px;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		}
		@media (max-width: 455px) {
			border: 0;
			border-left: 0;
		}
		@media (max-width: 355px) {
			padding: 35px 10px;
		}

		.back {
			margin: 0;
			cursor: pointer;
			width: 25px;
			height: 25px;
			border: 1px solid $main-gold;
			border-radius: 50%;
			position: absolute;
			top: 20px;
			right: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 1;
			background: #ffffff;

			img {
				width: 50%;
				height: 50%;
			}
		}

		.login-register-wrapper {
			flex: auto;
		}
	}

	&.cart-layout {
		flex-direction: column;

		.left {
			background: #050402;
			width: 100vw;
			height: 10vh;
			@media (max-width: 959px) {
				display: none;
			}

			.left-images {
				flex-direction: row-reverse;

				.man-img {
					width: 80%;
				}
			}
		}

		.right {
			display: flex;
			box-sizing: border-box;
			flex-direction: column;
			width: 100vw;
			height: 90vh;
			padding: 105px;
			overflow: auto;
			border: none;
			border-left: 0;
			@media (max-width: 959px) {
				width: 100vw;
				padding: 35px;
				overflow: auto;
				-webkit-overflow-scrolling: touch;
			}
			@media (max-width: 355px) {
				padding: 35px 10px;
			}

			.back {
				top: 30px;
				right: 40px;
			}

			.login-register-wrapper {
				flex: auto;
			}
		}
	}

	&__checkoutBtn {
		position: fixed;
		bottom: 19px;
		width: 85%;
		background-color: $main-gold;
		color: #ffffff;
		height: 60px;
		padding: 0 50px;
		align-items: center;
		border: none;
		outline: none;
		font-family: "Avenir-Book", serif;
		font-weight: 300;
		font-size: 26px;
		line-height: 26px;
		cursor: pointer;
		text-transform: capitalize;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		left: 105px;
		@media (max-width: 1260px) {
			width: 80%;
		}
		@media (max-width: 1024px) {
			width: 65%;
		}
		@media (max-width: 959px) {
			left: 35px;
			width: 65%;
		}
		@media (max-width: 450px) {
			width: 65%;
		}
		@media (max-width: 320px) {
			padding: 0;
			left: 10px;
		}
	}
}
