@import '../../variables';

.patients-list-component {
	position: relative;
	min-height: 100px;
	background: transparent;
	font-family: "Avenir-Medium", serif;
	font-size: 14px;
	.search-row {
		margin-bottom: 40px;
		input {
			margin-left: 10px;
		}
	}
	.order-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 75px;
		width: 90%;
		margin: 0 auto 10px auto;
		.content {
			flex: auto;
			background: #ffffff;
			margin: 0 2px;
			padding: 0 10px;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.row1 {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 40%;
				.id {
					font-family: "Avenir-Heavy", serif;
					padding: 0 10px 0 0;
				}
				.date {
					padding: 0 10px 0 0;
				}
				.items {
					text-decoration: underline;
				}
			}
			.row2 {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 40%;
				.patient-name {
					padding: 0 10px 0 10px;
					font-family: "Avenir-Heavy", serif;
				}
				.patient-birthday {
					padding: 0 0 0 10px;
					font-family: "Avenir-Heavy", serif;
				}
			}
		}
		.button {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 2px;
			padding: 0 10px;
			font-family: "Avenir-Medium", serif;
			font-size: 12px;
			letter-spacing: 1px;
			line-height: 12px;
			cursor: pointer;
			height: 100%;
			min-width: 90px;
			text-transform: uppercase;
			&.reject {
				color: #000000;
				background: #b4b4b4;
			}
			&.approve {
				color: #ffffff;
				background: $main-gold;
			}
			&.orders {
				color: #ffffff;
				background: $main-gold;
				margin-left: auto;
				width: 110px;
				font-size: 10px;
				line-height: 10px;
			}
			&.message {
				color: #ffffff;
				background: $main-gold;
				margin-left: 10px;
				width: 110px;
				font-size: 10px;
				line-height: 10px;
			}
		}
	}
}
