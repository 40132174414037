@import '../../variables';

.pharmacist-pending-orders-component {
	position: relative;
	box-sizing: border-box;
	min-height: 100px;
	background: transparent;
	font-family: "Avenir-Medium", serif;
	font-size: 11px;
	.top-title {
		max-width: 1000px;
		margin: 20px auto 10px auto;
		font-size: 16px;
		display: flex;
		align-items: center;
		flex-direction: row;
		font-family: "Avenir-Heavy", serif;
		p {
			color: $main-gold;
			padding-right: 5px;
		}
		.horizontal-line {
			flex: auto;
			height: 2px;
			background-color: $main-gold;
		}
	}
	.no-orders {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 75px;
		width: 100%;
	}
}
