@import '../../variables';

.doctor-dashboard-component {
	position: relative;
	min-height: calc(100vh - 70px);
	z-index: 0;
	background: #dbdbdb;
	padding: 70px 0 20px 0;
	font-family: "Avenir-Medium", serif;
	.menu-section {
		position: fixed;
		top: 0;
		z-index: 2;
		background: #dbdbdb;
		height: 70px;
		transition: 0.5s linear;
		width: calc(100% - 40px);
		padding: 0 20px;
		.box {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			.left {
				flex: 1;
				font-size: 14px;
				justify-content: center;
				span {
					margin-right: auto;
				}
			}
			.logo {
				display: flex;
				flex: 1;
				justify-content: center;
				padding: 10px 0;
				.AW-logo-black {
					display: inline-block;
					height: 50px;
					cursor: pointer;
				}
				@media (max-width: 959px) {
					order: 1;
				}
			}
			.right-buttons {
				flex: 1;
				justify-content: center;
				display: flex;
				flex-direction: row;
				align-items: center;
				color: #1a1a1a;
				width: auto;
				span {
					margin-left: auto;
					.logout {
						cursor: pointer;
						white-space: nowrap;
						font-family: "Avenir-Heavy", serif;
						&:hover {
							text-decoration: underline;
						}
					}
				}
				@media (max-width: 959px) {
					width: auto;
					order: 3;
				}
			}
		}
	}
	.tabs-section {
		padding: 80px 0 0 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		.link {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 2px;
			padding: 0 10px;
			letter-spacing: 1px;
			font-family: "Avenir-Medium", serif;
			font-size: 13px;
			line-height: 13px;
			cursor: pointer;
			height: 35px;
			width: 150px;
			border: 2px solid $main-gold;
			color: $main-gold;
			&.active {
				background-color: $main-gold;
				color: #ffffff;
			}
		}
	}
	.content-section {
		padding: 40px 20px 0 20px;
	}
}
