@font-face {
	font-family: "Avenir-Light";
	src: url("./Avenir-Light.ttf");
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "Avenir-Book";
	src: url("./Avenir-Book.ttf");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Avenir-Medium";
	src: url("./Avenir-Medium.ttf");
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: "Avenir-Heavy";
	src: url("./Avenir-Heavy.ttf");
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: "Geometria";
	src: url("./Geometria.ttf");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "Geometria-Medium";
	src: url("./Geometria-Medium.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "Geometria-Bold";
	src: url("./Geometria-Bold.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "YesevaOne-Regular";
	src: url("./YesevaOne-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: "RNSSanz-SemiBold";
	src: url("./RNSSanz-SemiBold.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: "RNSSanz-ExtraBold";
	src: url("./RNSSanz-ExtraBold.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: "Josefin-Sans";
	src: url("./JosefinSans-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}
