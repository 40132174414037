@import '../../../../variables';

.pending-users-admin {
	.user-row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 60px;
		width: 90%;
		margin: 0 auto 8px auto;

		.content {
			flex: auto;
			background: #ffffff;
			margin: 0 4px;
			padding: 0 10px;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.row1 {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 30px;
				.patient-name {
					padding: 0 10px 0 10px;
					font-family: "Avenir-Heavy", serif;
				}
				.patient-birthday {
					padding: 0 10px 0 10px;
					font-family: "Avenir-Heavy", serif;
				}
				.patient-phone {
					padding: 0 10px 0 10px;
					font-family: "Avenir-Heavy", serif;
				}
				.patient-email {
					padding: 0 0 0 10px;
					font-family: "Avenir-Heavy", serif;
				}
				.buttons {
					display: flex;
					flex-direction: row;
					margin-left: auto;
					height: 30px;
				}
			}
		}
	}
}
