@import '../../../../variables';

.reports-admin {
	.pt{
		padding-top: .5rem;
	}
	.title {
		width: 90%;
		margin: 0 auto 8px auto;
		font-size: 26px;
		margin-bottom: 20px;
	}
	.shipped-orders-report {
		width: 90%;
		display: flex;
		align-items: center;
		border-bottom: 2px solid $main-gold;
		padding-bottom: 20px;
		margin: 0 auto 8px auto;
		.date {
			font-family: "Avenir-Book", serif;
			display: inline-block;
			margin-right: 7px;
		}

		.custom-date-input,
		.reset-btn,
		.download-button {
			padding: 8px 10px;
			border-radius: 2px;
			border: 2px solid $main-gold;
			outline: none;
			cursor: pointer;
			background-color: $main-gold;
			color: #fff;
			font-family: "Avenir-Heavy", serif;
			margin-right: 20px;
		}

		.reset-btn {
			margin-left: auto;
		}

		.react-datepicker__navigation {
			top: 5px;
		}
	}
	.users-no-orders-report {
		width: 90%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 2px solid $main-gold;
		padding-bottom: 20px;
		margin: 20px auto 8px auto;

		.title {
			margin-bottom: 0;
		}

		.download-button {
			padding: 8px 10px;
			border-radius: 2px;
			border: 2px solid $main-gold;
			outline: none;
			cursor: pointer;
			background-color: $main-gold;
			color: #fff;
			font-family: "Avenir-Heavy", serif;
			margin-right: 20px;
			flex-shrink: 0;
		}
	}
}
