

#product{
  .column-45 {
    position: -webkit-sticky;
    position: sticky;
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .heading-2 {
    margin-top: 0px;
    font-size: 45px;
    font-weight: 400;
  }

  .paragraph-7 ul{
    list-style: initial;
    padding-left: 20px !important;
  }

  .modal div h2.product-head{
    padding-left: 10px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin: 0 im !important;
    line-height: 20px !important;
  }
  .backgroundFhl{
    background: url('../../static/images/hair-loss-blog.jpg') no-repeat;
  }
  .backgroundED{
    background: url('../../static/images/ed-101.jpg') no-repeat;
  }
  .backgroundViagra{
    background: url('../../static/images/viagara-and-cialis.jpg') no-repeat;
  }
  .backgroundFCD{
    background: url('../../static/images/fcd-blog.jpg') no-repeat;
  }
  .backgroundFacne{
    background: url('../../static/images/facne-blog.jpg') no-repeat;
  }
  .backgroundAllergy{
		background: url('../../static/images/blog-allergies.png') no-repeat;
	}

  .mob-hide{
    display: block;
  }

  .mob-show{
    display: none;
  }

  .div-block-21 {
		padding-top: 60px;
		padding-bottom: 220px;
		// background-image: url('../images/viagara-and-cialis.png');
		background-position: 50% 50% !important;
		background-size: cover !important;
	  }
  
  .subname {
    font-family: "Avenir-Heavy", serif;
    font-size: 20px;
    line-height: 44px;
    @media (max-width: 1100px) {
      font-size: 20px;
      line-height: 44px;
    }
    @media (max-width: 700px) {
      font-size: 20px;
      line-height: 44px;
    }
  }
  .form-block-2 {
    margin-bottom: 0px;
  }
  
  .heading-5 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 22px;
  }
  
  .heading-3 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
  }
  
  .paragraph {
    font-size: 16px;
  }
  
  .paragraph-7 {
    margin-bottom: 30px;
    font-size: 16px;
  }
  
  .image-2 {
    margin-bottom: 0px;
  }

  .div-block-22 {
    display: flex;
    margin-top: 40px;
    justify-content: flex-start;
    align-items: center;
    }
  
  .button-15 {
    display: block;
    margin: 10px 0px 0px;
    padding: 10px 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 2px solid #c49b65;
    background-color: rgba(56, 152, 236, 0);
    color: #c49b65;
    text-align: center;
    text-decoration: none;
  }
  
  .button-15:hover {
    background-color: rgba(196, 155, 101, 0.2);
  }
  
  .button-9 {
    display: inline-block;
    margin-top: 20px;
    padding: 20px 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px none #c49b65;
    background-color: #c49b65;
    font-size: 18px;
    text-align: center;
    text-transform: none;
    color:#fff;
    text-decoration: none;
  }
  
  .button-9:hover {
    background-color: #c49b65;
    color: #fff;
    font-weight: 400;
    white-space: normal;
  }
  
  .button-8 {
    display: block;
    margin-right: 0px;
    margin-left: 0px;
    padding: 10px 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 2px solid #c49b65;
    background-color: rgba(255, 255, 255, 0);
    color: #c49b65;
    font-size: 14px;
    text-align: center;
    text-transform: none;
    white-space: normal;
    text-decoration: none;
  }
  
  .button-8:hover {
    background-color: rgba(196, 155, 101, 0.2);
  }
  
  .div-block-19 {
    position: static;
    top: auto;
    display: block;
    margin-top: 60px;
    padding: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
  }
  
  .container-6 {
    position: relative;
    top: auto;
  }
  
  .text-block-7 {
    margin-top: 10px;
  }
  
  .bold-text {
    display: block;
  }
  
  .columns-30 {
    position: static;
    top: 0px;
  }
  
  .select-field-2 {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  
   .section.product {
    position: static;
    margin-bottom: 0px;
    padding-top: 140px;
    padding-bottom: 60px;
    background-color: #f0e0cb;
    font-family: 'Avenir-Book', sans-serif;
   }
  
  .heading-4 {
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 16px;
  }
  
  .button-14 {
    display: block;
    margin-right: 0px;
    padding: 10px 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid none solid solid;
    border-width: 2px;
    border-color: #c49b65;
    background-color: rgba(56, 152, 236, 0);
    color: #c49b65;
    text-align: center;
    text-decoration: none;
  }
  
  .button-14:hover {
    background-color: rgba(196, 155, 101, 0.2);
    color: #c49b65 !important;
    text-decoration: none;
  }
  
  .button-8:hover {
    background-color: rgba(196, 155, 101, 0.2);
    color: #c49b65 !important;
    text-decoration: none;
  }
  
  .button-15:hover {
    background-color: rgba(196, 155, 101, 0.2);
    color: #c49b65 !important;
    text-decoration: none;
  }
  
  .button-9:hover {
    background-color: rgb(196, 155, 101);
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
  
  .button-13:hover {
    background-color: rgba(196, 155, 101, 0.2);
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
  
  .button-17:hover{
    background-color: rgba(196, 155, 101, 0.2);
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
  
  .column-103 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .heading-6 {
    margin-top: 0px;
    font-size: 38px;
    font-weight: 400;
    text-align: left;
  }
  
  .column-99 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .section-3 {
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #fff;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .column-100 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .heading-74 {
    margin-left: 40px;
    font-size: 20px;
    font-weight: 400;
  }
  
  .columns-64 {
    margin-top: 10px;
  }
  
  .paragraph-8 {
    margin-top: 10px;
    margin-bottom: 40px;
    font-size: 16px;
  }
  
  .column-107 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .paragraph-12 {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  
  .paragraph-11 {
    display: block;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  
  .paragraph-9 {
    display: block;
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 14px;
    text-align: left;
  }
  
  .section-4 {
    position: static;
    margin-bottom: 0px;
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #f0e0cb;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .paragraph-2 {
    // margin-bottom: 20px;
    font-size: 16px;
    text-align: left;
    ul{
      margin: auto;
      width: 95%;
      padding: 10px;
      list-style-type: disc;
      line-height: 30px;
    }
  }
  
  
  .heading-57 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 33px;
  }
  
  .heading-55 {
    margin-top: 40px;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 33px;
  }
  
  .div-block-26 {
    position: relative;
    margin-top: 0px;
    padding: 20px;
    background-color: #fff;
  }
  
  .container-3 {
    margin-bottom: 0px;
  }
  
  .heading-54 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
  }
  
  .div-block-24 {
    position: relative;
    display: inline-block;
    padding: 20px;
    background-color: #fff;
  }
  
  .div-block-25 {
    position: relative;
    display: block;
    margin-top: 0px;
    padding: 20px;
    background-color: #fff;
  }
  
  .container-4 {
    padding: 0px;
  }
  
  .column-48 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .heading-45 {
    margin-top: 10px;
    font-size: 16px;
    line-height: 33px;
    font-weight: 400;
  }
  
  .div-block-49 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f0e0cb;
  }
  
  .section-11 {
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .div-block-38 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 0px;
    background-color: #f0e0cb;
  }
  
  .image-18 {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .columns-25 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .column-49 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .heading-53 {
    margin-top: 0px;
    font-weight: 400;
  }
  
  .heading-44 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
  }
  
  .paragraph-6 {
    margin-bottom: 0px;
    margin-left: 20px;
    font-size: 18px;
  }
  
  .div-block-34 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }
  
  .heading-37 {
    font-size: 20px;
  }
  
  .div-block-31 {
    position: relative;
  }
  
  .heading-39 {
    margin-top: 0px;
    font-size: 38px;
    font-weight: 400;
  }
  
  .container-5 {
    font-size: 20px;
  }
  
  .div-block-46 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }
  
  .section-7 {
    margin-bottom: 100px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .heading-34 {
    display: block;
    font-size: 20px;
    text-align: left;
  }
  
  .div-block-48 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }
  
  .text-span-3 {
    text-decoration: underline;
  }
  
  .button-17 {
    display: inline-block;
    margin-top: 20px;
    border: 2px solid #c49b65;
    background-color: #fff;
    color: #c49b65;
    font-size: 18px;
    padding: 9px 15px;
    text-decoration: none;
  }
  
  .button-17:hover {
    background-color: rgba(196, 155, 101, 0.2);
  }
  
  .div-block-30 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }
  
  .column-14 {
    text-align: center;
  }
  
  .slide-nav {
    position: relative;
    margin-top: -30px;
    padding-top: 0px;
    font-weight: 400;
  }
  
  .text-block-4 {
    margin-top: 20px;
    padding-bottom: 0px;
    font-size: 14px;
  }
  
  .slider {
    border-radius: 0px;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .column-16 {
    text-align: center;
  }
  
  .columns-65 {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  
  .icon {
    color: #c49b65;
  }
  
  .text-block {
    margin-top: 20px;
  }
  
  .heading-26 {
    margin-top: 100px;
    font-size: 28px;
    line-height: 33px;
    font-weight: 400;
    text-align: center;
  }
  
  .text-block-2 {
    margin-top: 20px;
  }
  
  .slide-3 {
    background-color: #f0e0cb;
  }
  
  .slide {
    position: relative;
    margin-top: 0px;
    border-radius: 10px;
    background-color: #f0e0cb;
  }
  
  .mask {
    border-radius: 0px;
  }
  
  .columns-2 {
    display: block;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .heading-23 {
    margin-top: 100px;
    font-size: 28px;
    line-height: 33px;
    font-weight: 400;
    text-align: center;
  }
  
  .section-5 {
    position: static;
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .heading-27 {
    margin-top: 0px;
    margin-bottom: 40px;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
  }
  
  .div-block-5 {
    padding: 20px;
    cursor: pointer;
    color: #000;
  }
  
  .div-block-5:hover {
    box-shadow: 0 1px 1px 1px #ded8d8;
  }
  
  .image-6 {
    margin-top: 0px;
  }
  
  .heading-49 {
    font-size: 30px;
    line-height: 33px;
    font-weight: 400;
  }
  
  .heading-46 {
    margin-top: 0px;
    font-size: 38px;
    font-weight: 400;
  }
  
  .container-7 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .section-8 {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #f0e0cb;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .column-30 {
    display: block;
  }
  
  .column-28 {
    text-align: right;
  }
  
  .columns-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .image-19 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .button-11 {
    margin-top: 20px;
    display: inline-block;
    padding: 20px 40px;
    border: 2px none #c49b65;
    background-color: #c49b65;
    font-size: 18px;
    color: #fff;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
  }
  
  .button-11:hover {
    background-color: #c49b65;
    color: #fff;
  }
  
  .heading-52 {
    margin-top: 0px;
    margin-bottom: 40px;
    font-weight: 400;
    text-align: left;
  }
  
  .heading-48 {
    padding-left: 0px;
    font-size: 30px;
    font-weight: 400;
  }
  
  .text-block-6 {
    padding-bottom: 0px;
    padding-left: 20px;
    text-align: right;
  }
  
  .div-block-20 {
    display: block;
    padding-top: 60px;
    padding-bottom: 220px;
    background-image: url("../../static/images/ed-101.jpg");
  // background: transparent url("../../static/images/eye.svg") no-repeat center center;
    background-position: 0% 0% !important;
    background-size: cover !important;
  }
  
  .paragraph-4 {
    margin-top: 20px;
  }
  
  
  .section-10 {
    margin-top: 100px;
    padding-top: 0px;
    padding-bottom: 140px;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .div-block-17 {
    padding: 0px 0px 0px 10px;
    background-color: #fff;
  }
  
  .div-block-23 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .div-block-60 {
		display: block;
		padding-top: 60px;
		padding-bottom: 220px;
		// background-image: url('/static/images/ed-101.png');
		background-position: 0% 0% !important;
		background-size: cover !important;
	  }
  
  .columns-66 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
  }
  
  .button-13 {
    border: 2px solid #c49b65;
    background-color: #fff;
    color: #c49b65;
    font-size: 16px;
    padding: 9px 15px;
    text-decoration: none;
  }
  
  .button-13:hover {
    background-color: rgba(196, 155, 101, 0.2);
    color: #c49b65;
  }
  
  .text-span-4 {
    text-decoration: underline;
  }
  
  .div-block-55 {
    position: fixed;
    z-index: 4;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: rgba(54, 50, 50, 0.42);
  }
  
  .div-block-56 {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 600px;
    height: auto;
    margin: 140px auto auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    font-family: 'Avenir-Book', sans-serif;
    display: table;
  }
  
  .div-block-45 {
    position: fixed;
    /* left: auto; */
    top: 141px;
    /* right: 0; */
    /* bottom: auto; */
    padding: 20px 0px 0px 0px;
    cursor: pointer;
    width: 560px;
    overflow: hidden;
    text-align: end;
    background: #fff;
  }
  
  .div-block-43 {
    position: fixed;
    z-index: 4;
    display: block;
    overflow: visible;
    width: 100vw;
    height: 100vh;
    background-color: rgba(54, 50, 50, 0.42);
  }
  
  .div-block-44 {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    overflow: auto;
    width: 600px;
    height: 350px;
    margin: 140px auto auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .heading-60 {
    padding: 0px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .heading-68 {
    display: block;
    font-size: 18px;
  }
  
  .heading-77 {
    font-size: 18px;
  }
  
  .paragraph-13 {
    display: block;
  }
  
  .div-block-58 {
    position: fixed;
    z-index: 4;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: rgba(54, 50, 50, 0.42);
  }
  
  .div-block-36 {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    width: 600px;
    height: 350px;
    margin: 140px auto auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .div-block-37 {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    padding: 15px;
    cursor: pointer;
  }
  
  .heading-59 {
    margin-top: 20px;
    padding: 0px;
    font-size: 20px;
    font-weight: 400;
  }
  
  .button-19 {
    margin-top: 20px;
    margin-bottom: 0px;
    border: 2px solid #c49b65;
    background-color: #fff;
    color: #c49b65;
    font-size: 16px;
    cursor: pointer;
  }
  
  .button-19:hover {
    background-color: rgba(196, 155, 101, 0.2);
  }
  
  .text-block-8 {
    padding-left: 10px;
    border-radius: 0px;
    background-color: #000;
    color: #fff;
  }
  
  .text-block-info{
    margin-top: 10px;
    font-size: 12px;
    text-align: left;
  }

  .text-block-9 {
    padding-left: 10px;
    background-color: #000;
    color: #fff;
  }
  
  .heading-78 {
    font-size: 18px;
  }
  
  .heading-79 {
    font-size: 18px;
  }
  
  .heading-80 {
    font-size: 18px;
  }
  
  .heading-81 {
    font-size: 18px;
  }
  
  .heading-82 {
    font-size: 18px;
  }
  
  .heading-83 {
    font-size: 18px;
  }
  
  .heading-84 {
    font-size: 18px;
  }
  
  .heading-85 {
    font-size: 18px;
  }
  
  .heading-86 {
    font-size: 18px;
  }
  
  .heading-87 {
    font-size: 18px;
  }
  
  .heading-88 {
    margin-left: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  
  .heading-89 {
    margin-left: 40px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }
  
  .heading-90 {
    margin-left: 40px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }
  
  .heading-91 {
    margin-left: 40px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }
  
  .columns-67 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .heading-92 {
    margin-top: 100px;
    font-size: 28px;
    font-weight: 400;
  }
  
  .heading-93 {
    margin-top: 100px;
    font-size: 28px;
    font-weight: 400;
  }
  
  .link {
    color: #000;
    font-size: 16px;
  }
  
  .div-block-59 {
    position: relative;
    display: inline-block;
    padding: 20px 20px 40px;
    background-color: #fff;
  }
  
  .heading-94 {
    margin-top: 40px;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 33px;
  }
  
  .heading-95 {
    font-size: 20px;
  }
  
  .container-11 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .section-12 {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #f0e0cb;
    font-family: 'Avenir-Book', sans-serif;
  }
  
  .column-108 {
    text-align: right;
  }
  
  .column-109 {
    display: block;
  }
  
  .image-20 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .faqAccordion.accordion-item{
    border: none;
    border-radius: 0px;
  }

  .faqAccordion .accordion-button{
    padding: 0px;
    justify-content: space-between;
  }

  .faqAccordion .accordion-header{
    margin: 0px;
  }

  .faqAccordion .accordion-button::after{
    display: none;
  }

  .faqAccordion .accordion-body{
    padding: 0px;
  }

  .faqAccordion .accordion-button:not(.collapsed){
    background-color: transparent;
    border-color: transparent;
    color: var(--bs-accordion-btn-color);
    box-shadow: none;
  }

  .accordion-button:focus{
    box-shadow: none;
    outline: none;
  }
  
  @media screen and (max-width: 991px) {
    .div-block-45{
			width: 560px;
		}
    .column-45 {
      padding-right: 10px;
      padding-left: 10px;
    }
  
    .button-15 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-right: 15px;
      padding-left: 15px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    text-decoration: none;
    }
  
    .button-8 {
      padding: 10px 15px;
    }
  
    .bold-text {
      display: inline;
    }
  
    .button-14 {
      margin-right: 0px;
      padding: 10px;
    }
  
    .button-14:hover{
      text-decoration: none;
    }
  
    .div-block-26 {
      padding: 20px;
    }
  
    .div-block-24 {
      display: block;
      padding: 20px;
    }
  
    .div-block-25 {
      padding: 20px;
    }
  
    .div-block-49 {
      padding: 30px 20px;
    }
  
    .div-block-38 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .slide-nav {
      margin-top: -31px;
    }
  
    .slider {
      padding-bottom: 0px;
    }
  
    .heading-26 {
      margin-top: 60px;
    }
  
    .slide {
      position: relative;
    }
  
    .heading-23 {
      margin-top: 60px;
    }
  
    .container-7 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    .heading-48 {
      margin-top: 0px;
    }
  
    .heading-92 {
      margin-top: 60px;
      line-height: 33px;
    }
  
    .heading-93 {
      margin-top: 60px;
      line-height: 33px;
    }
  
    .div-block-59 {
      display: block;
      padding: 20px;
    }
  
    .container-11 {
      padding: 0px 10px;
    }
  
    .column-108 {
      padding-right: 0px;
    }
  
    .column-109 {
      padding-right: 10px;
      padding-left: 0px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .mob-hide{
			display: none;
		}

		.mob-show{
			display: block;
			margin-top: 30px;
		}
    .div-block-45{
			width: 360px;
		}
    .column-45 {
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .heading-2 {
      margin-top: 20px;
    }
  
    .button-15 {
      display: block;
      text-align: center;
    }
  
    .button-8 {
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
    text-decoration: none;
    }
  
    .container-6 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .column-89 {
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .section {
      padding-top: 80px;
    }
  
    .button-14 {
      margin-right: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-right-style: solid;
      border-bottom-style: none;
    }
  
    .heading-74 {
      line-height: 30px;
    }
  
    .columns-60 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  
    .columns-64 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  
    .container-8 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .paragraph-12 {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  
    .paragraph-11 {
      margin-top: 10px;
    }
  
    .paragraph-9 {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  
    .image-16 {
      display: inline-block;
    }
  
    .column-52 {
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .div-block-26 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .container-3 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .heading-54 {
      display: block;
    }
  
    .div-block-24 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .div-block-25 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .column-51 {
      padding-right: 0px;
      padding-bottom: 20px;
      padding-left: 0px;
    }
  
    .container-4 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .column-48 {
      display: block;
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .div-block-49 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 20px;
    }
  
    .div-block-38 {
      margin-bottom: 20px;
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .columns-25 {
      display: block;
    }
  
    .column-49 {
      display: block;
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .div-block-34 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  
    .heading-37 {
      line-height: 30px;
    }
  
    .container-5 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .heading-34 {
      line-height: 30px;
    }
  
    .column-14 {
      margin-bottom: 0px;
    }
  
    .image-14 {
      margin-top: 0px;
    }
  
    .text-block {
      margin-top: 20px;
    }
  
    .heading-26 {
      margin-top: 60px;
      font-weight: 400;
    }
  
    .heading-23 {
      margin-top: 60px;
      font-weight: 400;
    }
  
    .div-block-5 {
      text-align: center;
    }
  
    .heading-46 {
      margin-top: 0px;
    }
  
    .container-7 {
      padding: 20px;
    }
  
    .heading-52 {
      margin-bottom: 40px;
    }
  
    .heading-48 {
      margin-top: 20px;
    }
  
    .column-71 {
      margin-bottom: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .div-block-17 {
      padding-left: 0px;
    }
  
    .columns-66 {
      display: block;
    }
  
    .div-block-55 {
      display: block;
    }
  
    .div-block-56 {
      width: 400px;
    }
  
    .div-block-44 {
      width: 400px;
    }
  
    .div-block-36 {
      width: 400px;
      height: 420px;
    }
  
    .button-19 {
      margin-top: 20px;
    }
  
    .heading-89 {
      line-height: 30px;
    }
  
    .columns-67 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  
    .div-block-59 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .heading-94 {
      display: block;
    }
  
    .container-11 {
      padding: 0px 20px;
    }
  
    .column-108 {
      padding-right: 0px;
    }
  
    .column-109 {
      padding-left: 0px;
    }
  }
  
  @media screen and (max-width: 479px) {

    .mob-hide{
			display: none;
		}

		.mob-show{
			display: block;
			margin-top: 30px;
		}

    .div-block-45{
			width: 260px;
		}
    .column-45 {
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .heading-5 {
      line-height: 33px;
    }
  
    .button-15 {
      margin-top: 10px;
      margin-left: 0px;
    }
  
    .button-8 {
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
    }
  
    .container-6 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .column-89 {
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .button-14 {
      margin-right: 0px;
    }
  
    .column-103 {
      margin-top: 20px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
  
    .column-100 {
      margin-top: 30px;
    }
  
    .columns-60 {
      display: block;
    }
  
    .columns-64 {
      display: block;
      margin-top: 0px;
    }
  
    .column-107 {
      margin-top: 20px;
    }
  
    .div-block-49 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .div-block-38 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .heading-37 {
      line-height: 30px;
    }
  
    .heading-34 {
      line-height: 30px;
    }
  
    .slide-nav {
      position: absolute;
      margin-top: 0px;
      font-weight: 400;
    }
  
    .text-block {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  
    .heading-26 {
      margin-top: 40px;
      font-weight: 400;
    }
  
    .heading-23 {
      margin-top: 40px;
      font-size: 28px;
      font-weight: 400;
    }
  
    .container-10 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .div-block-5 {
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .container-7 {
      margin-top: 0px;
      padding-top: 40px;
      text-align: center;
    }
  
    .column-28 {
      text-align: center;
    }
  
    .columns-5 {
      display: block;
    }
  
    .image-19 {
      padding-top: 40px;
    }
  
    .div-block-56 {
      width: 300px;
    }
  
    .div-block-44 {
      width: 300px;
    }
  
    .div-block-36 {
      width: 300px;
      height: 500px;
    }
  
    .heading-90 {
      line-height: 30px;
    }
  
    .columns-67 {
      display: block;
      margin-top: 0px;
    }
  
    .heading-92 {
      margin-top: 40px;
      font-size: 28px;
      line-height: 33px;
      font-weight: 400;
    }
  
    .heading-93 {
      margin-top: 40px;
      font-size: 25px;
    }
  
    .container-11 {
      margin-top: 0px;
      padding-top: 0px;
      text-align: center;
    }
  
    .column-108 {
      padding-left: 0px;
      text-align: center;
    }
  
    .column-109 {
      padding-right: 0px;
    }
  
    .image-20 {
      padding-top: 40px;
    }
  }
}

