@import '../../../../variables';

.order-item {
	display: flex;
	flex-wrap: wrap;
	padding: 20px 0 0 0;
	border-top: 1px solid #e9e9e9;
	margin-top: 20px;
	.info-tooltip {
		max-width: 320px;
		font-family: "Avenir-Book", serif;
	}
	.top-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 20px;
		@media (max-width: 750px) {
			flex-direction: column;
			align-items: flex-start;
		}
		.status {
			display: flex;
			font-family: "Avenir-Heavy", serif;
			font-size: 16px;
			line-height: 22px;
			&-title {
				color: #000;
				margin-right: 5px;
			}
			&-text {
				color: $main-gold;
			}
		}
	}

	.center-row {
		display: flex;
		width: 100%;
		font-family: "Avenir-Book", serif;
		font-size: 16px;
		line-height: 22px;
		padding-bottom: 0;
		color: #000;
		border-bottom: none;
		@media (max-width: 750px) {
			flex-direction: column;
			align-items: flex-start;
		}
		.id {
			display: flex;
			margin-right: 30px;
			@media (max-width: 750px) {
				margin-bottom: 20px;
			}
			&-title {
				margin-right: 5px;
				white-space: nowrap;
			}
			&-text {
				white-space: nowrap;
			}
		}
		.subscription-id {
			display: flex;
			margin-right: 30px;
			@media (max-width: 750px) {
				margin-bottom: 20px;
			}
			&-title {
				margin-right: 5px;
				white-space: nowrap;
			}
			&-text {
				white-space: nowrap;
			}
		}
		.product-name {
			display: flex;

			&-title {
				margin-right: 5px;
				white-space: nowrap;
			}
		}
	}
	.bottom-row {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin-top: 30px;
		align-items: flex-end;

		.get-order-receipt {
			padding: 7px 15px;
			border: 2px solid $main-gold;
			height: 32px;
			margin-right: 45px;
			box-sizing: border-box;
			cursor: pointer;
			display: flex;
			align-items: center;
			span {
				font-family: "Avenir-Book", serif;
				font-size: 10px;
				line-height: 14px;
				color: $main-gold;
			}
			img {
				height: 15px;
				margin-right: 4px;
			}
		}
		.order-price {
			font-family: "Avenir-Book", serif;
			text-align: right;
			min-width: 90px;
			&-title {
				font-size: 11px;
				line-height: 15px;
				color: $main-gold;
			}
			&-text {
				font-size: 24px;
				line-height: 33px;
				color: #000;
			}
		}
		@media (max-width: 480px) {
			flex-direction: column;
			// align-items: center;
			.order-price {
				margin-top: 30px;
			}
			.get-order-receipt {
				margin-right: 0;
			}
		}
	}
	&.solo {
		padding: 20px 50px;
		margin-top: 0;
		border-top: 2px solid $main-gold;
		border-bottom: none;
		@media (max-width: 650px) {
			padding: 10px 0;
		}
	}
}
