@import '../../variables';

.privacy-policy-terms-of-use-component {
	&.pop-up {
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 102;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: "Avenir-Medium", serif;
	}
	&.static {
		position: relative;
		padding: 100px 0 30px 0;
		min-height: 100px;
		font-family: "Avenir-Medium", serif;
		background-color: #dbdbdb;
	}
	.box {
		&.pop-up {
			width: 80vw;
			height: 90vh;
			overflow-y: auto;
			border: 15px solid #ffffff;
			background-color: #e7e7e7;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			font-size: 15px;
			letter-spacing: 0.02em;
			line-height: 1.4em;
			font-family: "Avenir-Medium", serif;
			@media (max-width: 959px) {
				width: 100vw;
				max-height: 100vh;
				min-height: unset;
			}
		}
		&.static {
			background-color: #f6f6f6;
			margin: 0 auto;
			max-width: 1200px;
			border: 10px solid #ffffff;
		}
		.close {
			&.pop-up {
				z-index: 1;
				position: absolute;
				top: calc(5vh + 5px);
				right: calc(10vw + 25px);
				cursor: pointer;
				width: 25px;
				height: 25px;
				border: 1px solid $main-gold;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				@media (max-width: 959px) {
					top: 35px;
					right: 20px;
				}
				img {
					width: 50%;
					height: 50%;
				}
			}
			&.static {
				display: none;
			}
		}
		.app-content {
			position: relative;
			width: 100%;
			.refresh-date {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				padding: 20px 36px 0 0;
				font-size: 15px;
				letter-spacing: 0.02em;
				line-height: 1.4em;
				font-family: "Avenir-Medium", serif;
			}
			.title {
				position: relative;
				text-align: center;
				padding: 20px 30px;
				font-size: 64px;
				font-family: "YesevaOne-Regular", serif;
				line-height: 74px;
				@media (max-width: 959px) {
					padding: 20px 30px 10px 30px;
					font-size: 46px;
				}
			}
			.content {
				text-align: justify;
				@media (max-width: 399px) {
					text-align: left;
				}
				padding: 0 36px;
				font-size: 15px;
				letter-spacing: 0.02em;
				line-height: 1.4em;
				font-family: "Avenir-Medium", serif;
				p {
					padding-bottom: 20px;
					a {
						text-decoration: underline;
						color: #000000;
						font-family: "Avenir-Heavy", serif;
						&:hover {
							color: #ba9257;
						}
					}
				}
				.sub-title {
					padding: 20px 0 0 0;
					font-size: 37px;
					line-height: 46px;
					font-family: "Avenir-Heavy", serif;
					letter-spacing: 0;
					text-align: left;
					@media (max-width: 959px) {
						font-size: 37px;
					}
				}
				.ordered-list {
					list-style: decimal;
					padding-left: 20px;
					padding-bottom: 10px;
					li {
						padding-bottom: 10px;
					}
				}
				.unordered-list {
					list-style: none;
					padding-left: 20px;
					li {
						position: relative;
						padding: 0 0 10px 15px;
						&::before {
							display: inline-block;
							width: 7px;
							height: 7px;
							border-radius: 50%;
							background: $main-gold;
							content: "";
							flex: none;
							margin: 6px 0 0 0;
							position: absolute;
							left: 0;
							top: 2px;
						}
					}
				}
				span {
					font-family: "Avenir-Heavy", serif;
				}
			}
		}
	}
}
