@import '../../variables';

#footer{
	.w-layout-grid {
		display: -ms-grid;
		display: grid;
		grid-auto-columns: 1fr;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
		grid-row-gap: 16px;
		grid-column-gap: 16px;
	  }
	  
	  .link-block-8 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .link-block-8:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		text-decoration: underline;
	  }
	  
	  .text-block-10 {
		color: #b99055;
	  }
	  
	  .navbar-3 {
		position: -webkit-sticky;
		position: sticky;
		left: auto;
		top: 0%;
		right: auto;
		bottom: auto;
		background-color: #000;
	  }
	  
	  .link-block-7 {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	  }
	  
	  .bold-text-5 {
		color: #fff;
	  }
	  
	  .how-it-works-2 {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .how-it-works-2:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .dropdown-link {
		background-color: #000;
		font-family: 'Avenir-Book', sans-serif;
		color: #fff;
	  }
	  
	  .about-us-2 {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .about-us-2:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .shop-2 {
		font-family: 'Avenir-Book', sans-serif;
		font-weight: 400;
	  }
	  
	  .shop-2:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		color: #c49b65;
		font-weight: 400;
		text-decoration: none;
	  }
	  
	  .shop-2:active {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .bold-text-2 {
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .icon-2 {
		color: #c49b65;
	  }
	  
	  .bold-text-3 {
		color: #b99055;
	  }
	  
	  .icon-4 {
		color: #b99055;
	  }
	  
	  .dropdown-link-8 {
		background-color: #000;
	  }
	  
	  .container-9 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .link-block-6 {
		padding: 20px;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .link-block-6:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		text-decoration: underline;
	  }
	  
	  .dropdown-link-9 {
		background-color: #000;
		font-family: 'Avenir-Book', sans-serif;
		color: #fff;
	  }
	  
	  .nav-menu-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: left;
	  }
	  
	  .image-4 {
		position: relative;
		display: block;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	  }
	  
	  .bold-text-4 {
		color: #c49b65;
	  }
	  
	  .section {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100vh;
		min-height: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #000;
		background-image: url('../../static/images/home-header-bg.jpg');
		background-position: 0px 0px;
		background-size: cover;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .container-10 {
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	  }
	  
	  .text-block {
		margin-top: 20px;
	  }
	  
	  .slider {
		border-radius: 0px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-94 {
		margin-top: 0px;
		font-size: 20px;
		line-height: 33px;
		font-weight: 400;
		text-align: left;
	  }
	  
	  .mask {
		border-radius: 0px;
	  }
	  
	  .column-16 {
		text-align: center;
	  }
	  
	  .heading-26 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 28px;
		line-height: 33px;
		font-weight: 400;
		text-align: left;
	  }
	  
	  .heading-26.v {
		margin-bottom: 10px;
		font-size: 22px;
		line-height: 33px;
	  }
	  
	  .heading-26.b {
		margin-bottom: 10px;
		font-size: 22px;
	  }
	  
	  .heading-26.f {
		margin-bottom: 10px;
		font-size: 22px;
		line-height: 33px;
	  }
	  
	  .heading-26.d {
		margin-bottom: 10px;
		font-size: 22px;
		line-height: 33px;
	  }
	  
	  .column-14 {
		text-align: center;
	  }
	  
	  .heading-93 {
		margin-top: 0px;
		font-size: 20px;
		line-height: 33px;
		font-weight: 400;
		text-align: left;
	  }
	  
	  .slide {
		position: relative;
		margin-top: 0px;
		border-radius: 10px;
		background-color: #f0e0cb;
	  }
	  
	  .slide-3 {
		background-color: #fff;
	  }
	  
	  .text-block-2 {
		margin-top: 20px;
	  }
	  
	  .columns-65 {
		margin-bottom: 0px;
		padding-bottom: 0px;
	  }
	  
	  .text-block-4 {
		margin-top: 20px;
		padding-bottom: 0px;
		font-size: 14px;
	  }
	  
	  .columns-2 {
		display: block;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .slide-nav {
		position: relative;
		margin-top: -30px;
		padding-top: 0px;
		font-weight: 400;
	  }
	  
	  .icon-5 {
		color: #c49b65;
	  }
	  
	  .heading-92 {
		margin-top: 0px;
		font-size: 20px;
		line-height: 33px;
		font-weight: 400;
	  }
	  
	  .column-114 {
		display: block;
	  }
	  
	  .column-113 {
		text-align: right;
	  }
	  
	  .columns-5 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .heading-46 {
		margin-top: 0px;
		color: #fff;
		font-size: 38px;
		font-weight: 400;
	  }
	  
	  .container-15 {
		padding-top: 0px;
		padding-bottom: 0px;
	  }
	  
	  .section-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 80vh;
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #fff;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../../static/images/need-help.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../static/images/need-help.jpg');
		background-position: 0px 0px, 50% 0%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
		background-attachment: scroll, scroll;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .button-18 {
		margin-top: 20px;
		padding: 20px 40px;
		border: 2px none #c49b65;
		background-color: #c49b65;
		font-size: 18px;
	  }
	  
	  .button-18:hover {
		background-color: #c49b65;
		color: #fff;
	  }
	  
	  .image-20 {
		padding-top: 0px;
		padding-bottom: 0px;
	  }
	  
	  .paragraph-14 {
		color: #fff;
		font-size: 16px;
	  }
	  
	  .div-block-25 {
		position: relative;
		display: block;
		margin-top: 0px;
		padding: 20px;
		background-color: #fff;
	  }
	  
	  .paragraph-9 {
		display: block;
		margin-top: 10px;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .heading-55 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		color: #000;
		font-size: 38px;
		line-height: 33px;
		font-weight: 400;
	  }
	  
	  .heading-55.g {
		margin-bottom: 10px;
		color: #000;
		font-size: 38px;
		line-height: 44px;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .heading-55.a {
		margin-bottom: 10px;
		color: #000;
		line-height: 44px;
		text-align: center;
	  }
	  
	  .heading-95 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 20px;
		line-height: 33px;
	  }
	  
	  .div-block-59 {
		position: relative;
		display: inline-block;
		padding: 20px 20px 40px;
		background-color: #fff;
	  }
	  
	  .div-block-26 {
		position: relative;
		margin-top: 0px;
		padding: 20px;
		background-color: #fff;
	  }
	  
	  .paragraph-12 {
		margin-top: 10px;
		margin-bottom: 0px;
	  }
	  
	  .container-3 {
		margin-bottom: 0px;
	  }
	  
	  .paragraph-13 {
		margin-bottom: 40px;
		font-size: 16px;
		text-align: center;
	  }
	  
	  .paragraph-11 {
		display: block;
		margin-top: 10px;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		color: #fff;
		font-size: 16px;
		text-align: center;
	  }
	  
	  .paragraph-11.g {
		color: #fff;
		text-align: center;
	  }
	  
	  .section-5 {
		position: static;
		margin-bottom: 0px;
		padding-top: 0px;
		padding-bottom: 0px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-75 {
		margin-top: 0px;
		font-size: 38px;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .section-6 {
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 100px;
		padding-bottom: 100px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .section-7 {
		padding-top: 100px;
		padding-bottom: 100px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-53 {
		margin-top: 0px;
		color: #fff;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .column-48 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .heading-45 {
		margin-top: 10px;
		color: #fdfdfd;
		font-size: 16px;
		line-height: 33px;
		font-weight: 400;
	  }
	  
	  .column-49 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	  }
	  
	  .columns-25 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 40px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .div-block-38 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		border-radius: 0px;
		background-color: rgba(255, 255, 255, 0.9);
	  }
	  
	  .div-block-49 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.9);
	  }
	  
	  .container-4 {
		padding: 0px;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	  }
	  
	  .image-18 {
		display: block;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	  }
	  
	//   .section-11 {
	// 	display: -webkit-box;
	// 	display: -webkit-flex;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	height: 80vh;
	// 	min-height: 0px;
	// 	margin-top: 0px;
	// 	margin-bottom: 0px;
	// 	padding-top: 0px;
	// 	padding-bottom: 0px;
	// 	-webkit-box-pack: center;
	// 	-webkit-justify-content: center;
	// 	-ms-flex-pack: center;
	// 	justify-content: center;
	// 	-webkit-box-align: center;
	// 	-webkit-align-items: center;
	// 	-ms-flex-align: center;
	// 	align-items: center;
	// 	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/man-coffee-window.png');
	// 	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/man-coffee-window.png');
	// 	background-position: 0px 0px, 0% 0%;
	// 	background-size: auto, cover;
	// 	background-repeat: repeat, no-repeat;
	// 	background-attachment: scroll, scroll;
	// 	font-family: 'Avenir-Book', sans-serif;
	//   }
	  
	  .heading-44 {
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 20px;
		color: #000;
		font-size: 18px;
		line-height: 20px;
		font-weight: 400;
	  }
	  
	  .paragraph-6 {
		margin-bottom: 0px;
		margin-left: 20px;
		color: #000;
		font-size: 18px;
	  }
	  
	  .section-12 {
		padding-top: 100px;
		padding-bottom: 100px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-96 {
		margin-top: 0px;
		margin-bottom: 20px;
		color: #fff;
		font-weight: 400;
	  }
	  
	  .heading-97 {
		margin-top: 0px;
		margin-bottom: 40px;
		font-weight: 400;
	  }
	  
	  .heading-98 {
		margin-top: 0px;
		font-family: 'Avenir-Book', sans-serif;
		color: #fff;
		font-size: 45px;
		font-weight: 400;
	  }
	  
	  .button-19 {
		margin-top: 40px;
		padding: 20px 40px;
		background-color: #c49b65;
		font-size: 18px;
	  }
	  
	  .heading-99 {
		display: block;
		margin-bottom: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		font-family: 'Avenir-Book', sans-serif;
		color: #fff;
		font-size: 20px;
		line-height: 33px;
	  }
	  
	  .heading-100 {
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		color: #fff;
		font-size: 20px;
		line-height: 33px;
	  }
	  
	  .heading-101 {
		display: block;
		margin-bottom: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		font-family: 'Avenir-Book', sans-serif;
		color: #fff;
		font-size: 20px;
		line-height: 33px;
	  }
	  
	  .heading-102 {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		color: #fff;
		font-size: 20px;
		line-height: 33px;
	  }
	  
	  .bold-text-6 {
		color: #fff;
		font-weight: 400;
	  }
	  
	  .paragraph-15 {
		color: #fff;
		font-size: 20px;
		line-height: 33px;
	  }
	  
	  .div-block-60 {
		padding: 20px;
		text-align: left;
	  }
	  
	  .div-block-61 {
		padding: 20px;
		text-align: center;
	  }
	  
	  .div-block-62 {
		padding: 20px;
		text-align: center;
	  }
	  
	  .div-block-63 {
		padding: 20px;
		text-align: center;
	  }
	  
	  .heading-103 {
		font-weight: 400;
	  }
	  
	  .button-17 {
		display: inline-block;
		margin-top: 20px;
		border: 2px solid #c49b65;
		background-color: #fff;
		color: #c49b65;
		font-size: 18px;
	  }
	  
	  .button-17:hover {
		background-color: rgba(196, 155, 101, 0.2);
	  }
	  
	  .text-block-12 {
		font-size: 16px;
	  }
	  
	  .container-16 {
		display: block;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .columns-66 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .div-block-64 {
		text-align: left;
	  }
	  
	  .section-13 {
		margin-top: 100px;
		margin-bottom: 100px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .paragraph-16 {
		font-size: 16px;
	  }
	  
	  .heading-104 {
		margin-top: 0px;
		font-weight: 400;
	  }
	  
	  .column-115 {
		text-align: left;
	  }
	  
	  .body {
		padding-bottom: 0px;
	  }
	  
	  .grid {
		-ms-grid-columns: 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
	  }
	  
	  .div-block-65 {
		padding: 20px;
		text-align: center;
	  }
	  
	  .div-block-65:hover {
		box-shadow: 0 1px 1px 1px #ded8d8;
	  }
	  
	  .div-block-66 {
		padding: 20px;
		text-align: center;
	  }
	  
	  .div-block-66:hover {
		box-shadow: 0 1px 1px 1px #ded8d8;
	  }
	  
	  .div-block-67 {
		padding: 20px;
		text-align: center;
	  }
	  
	  .div-block-67:hover {
		box-shadow: 0 1px 1px 1px #ded8d8;
	  }
	  
	  .div-block-68 {
		padding: 20px;
		text-align: center;
	  }
	  
	  .div-block-68:hover {
		box-shadow: 0 1px 1px 1px #ded8d8;
	  }
	  
	  .button-9 {
		display: inline-block;
		margin-top: 20px;
		padding: 20px 40px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border: 2px none #c49b65;
		background-color: #c49b65;
		font-size: 18px;
		text-align: center;
		text-transform: none;
	  }
	  
	  .button-9:hover {
		background-color: #c49b65;
		color: #fff;
		font-weight: 400;
		white-space: normal;
	  }
	  
	  .heading-39 {
		margin-top: 0px;
		font-size: 38px;
		font-weight: 400;
	  }
	  
	  .paragraph-17 {
		margin-bottom: 10px;
		font-size: 16px;
		text-align: left;
	  }
	  
	  .heading-37 {
		font-size: 20px;
	  }
	  
	  .div-block-46 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;
	  }
	  
	  .container-5 {
		font-size: 20px;
	  }
	  
	  .text-span-2 {
		color: #000;
		text-decoration: underline;
	  }
	  
	  .div-block-30 {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;
	  }
	  
	  .heading-34 {
		display: block;
		font-size: 20px;
		text-align: left;
	  }
	  
	  .div-block-31 {
		position: relative;
	  }
	  
	  .div-block-34 {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;
	  }
	  
	  .paragraph-18 {
		font-size: 16px;
		line-height: 20px;
	  }
	  
	  .bold-text-7 {
		color: #000;
		font-size: 16px;
	  }
	  
	  .div-block-48 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;
	  }
	  
	  .div-block-69 {
		margin-top: 60px;
	  }
	  
	  .image-23 {
		-webkit-filter: invert(100%);
		filter: invert(100%);
		color: #fff;
	  }
	  
	  .text-block-13 {
		margin-bottom: 20px;
		color: #fff;
		font-size: 20px;
	  }
	  
	  .columns-67 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .section-14 {
		margin-top: 0px;
		padding-top: 60px;
		padding-bottom: 20px;
		background-color: #000;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .column-124 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	  }
	  
	  .column-125 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		line-height: 30px;
	  }
	  
	  .div-block-70 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .div-block-70.cards {
		display: block;
		margin-top: 40px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	  }
	  
	  .container-20 {
		display: block;
		margin-top: 40px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .link {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial, sans-serif;
	  }
	  
	  .link:hover {
		text-decoration: underline;
	  }
	  
	  .link-2 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial, sans-serif;
	  }
	  
	  .link-2:hover {
		text-decoration: underline;
	  }
	  
	  .link-3 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial, sans-serif;
	  }
	  
	  .link-3:hover {
		text-decoration: underline;
	  }
	  
	  .link-4 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial, sans-serif;
	  }
	  
	  .link-4:hover {
		text-decoration: underline;
	  }
	  
	  .link-5 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial, sans-serif;
	  }
	  
	  .link-5:hover {
		text-decoration: underline;
	  }
	  
	  .link-6 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial, sans-serif;
	  }
	  
	  .link-6:hover {
		text-decoration: underline;
	  }
	  
	  .link-7 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial, sans-serif;
	  }
	  
	  .link-7:hover {
		text-decoration: underline;
	  }
	  
	  .link-8 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial, sans-serif;
	  }
	  
	  .link-8:hover {
		text-decoration: underline;
	  }
	  
	  .link-9 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial, sans-serif;
	  }
	  
	  .link-9:hover {
		text-decoration: underline;
	  }
	  
	  .heading-105 {
		margin-top: 0px;
		color: #fff;
		font-size: 20px;
	  }
	  
	  .heading-106 {
		margin-top: 0px;
		color: #fff;
		font-size: 20px;
	  }
	  
	  .heading-107 {
		color: #fff;
		font-size: 25px;
	  }
	  
	  .text-block-14 {
		margin-top: 20px;
		color: #7c7b7b;
		font-size: 12px;
		line-height: 10px;
		text-align: left;
	  }
	  
	  .column-126 {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		text-align: left;
	  }
	  
	  .container-21 {
		display: block;
		margin-top: 40px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-127 {
		text-align: right;
	  }
	  
	  .column-128 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	  }
	  
	  .container-22 {
		margin-top: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid #4d4d4d;
	  }
	  
	  .div-block-71 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .image-24 {
		display: block;
		margin-right: 20px;
		filter:initial !important;
	  }
	  
	  .container-23 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	  }
	  
	  .heading-108 {
		padding-left: 0px;
		color: #8f8d8d;
		font-size: 12px;
		line-height: 22px;
		font-weight: 400;
		text-align: left;
		font-family: Arial, sans-serif;
	  }
	  
	  .submit-button {
		padding-top: 10px;
		padding-bottom: 10px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		background-color: rgba(196, 155, 101, 0);
	  }
	  
	  .text-field {
		padding-left: 0px;
		border-top: 1px solid #000;
		border-right: 1px solid #000;
		border-left: 1px solid #000;
		background-color: #000;
	  }
	  
	  .text-field:hover {
		color: #fff;
	  }
	  
	  .heading-109 {
		margin-top: 0px;
		color: #fff;
		font-size: 20px;
	  }
	  
	  .form {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	  }
	  
	  .columns-68 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	  }
	  
	  .heading-110 {
		margin-top: 0px;
		color: #fff;
		font-size: 20px;
	  }
	  
	  .button-20 {
		margin-top: 0px;
		padding-top: 8px;
		padding-bottom: 8px;
		background-color: #c49b65;
	  }
	  
	  .button-20:hover {
		background-color: #242424;
		color: #fff;
	  }
	  
	  .container-24 {
		padding-bottom: 40px;
		border-bottom: 1px solid #585656;
	  }
	  
	  .link-10 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial,sans-serif;
	  }
	  
	  .link-10:hover {
		text-decoration: underline;
	  }
	  
	  .heading-111 {
		margin-top: 0px;
		color: #fff;
		font-size: 20px;
	  }
	  
	  .heading-112 {
		margin-top: 0px;
		color: #fff;
		font-size: 20px;
	  }
	  
	  .column-129 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		line-height: 30px;
	  }
	  
	  .bold-text-8 {
		color: #c49b65;
		font-size: 16px;
		text-decoration: none;
	  }
	  
	  .bold-text-9 {
		color: #c49b65;
		font-size: 16px;
		text-decoration: none;
	  }
	  
	  .bold-text-10 {
		color: #c49b65;
		font-size: 16px;
		text-decoration: none;
	  }
	  
	  .bold-text-11 {
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .link-11 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial,sans-serif;
	  }
	  
	  .link-11:hover {
		text-decoration: underline;
	  }
	  
	  .link-12 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial,sans-serif;
	  }
	  
	  .link-12:hover {
		text-decoration: underline;
	  }
	  
	  .link-13 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
	  }
	  
	  .link-13:hover {
		text-decoration: underline;
	  }
	  
	  .link-14 {
		color: #c49b65;
		font-size: 16px;
		line-height: 30px;
		text-decoration: none;
		font-family: Arial,sans-serif;
	  }
	  
	  .link-14:hover {
		text-decoration: underline;
	  }
	  
	  .heading-113 {
		margin-top: 0px;
		color: #fff;
		font-size: 20px;
	  }
	  
	  .container-25 {
		padding-top: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid #5c5959;
	  }
	  
	  .column-130 {
		display: block;
	  }
	  
	  .heading-114 {
		margin-top: 0px;
		color: #fff;
		font-size: 20px;
	  }
	  
	  .grid-2 {
		grid-auto-flow: row;
		grid-column-gap: 0px;
		-ms-grid-columns: 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
	  }
	  
	//   .div-block-72 {
	// 	display: -webkit-box;
	// 	display: -webkit-flex;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	height: 300px;
	// 	padding: 20px;
	// 	-webkit-box-orient: vertical;
	// 	-webkit-box-direction: normal;
	// 	-webkit-flex-direction: column;
	// 	-ms-flex-direction: column;
	// 	flex-direction: column;
	// 	-webkit-box-pack: end;
	// 	-webkit-justify-content: flex-end;
	// 	-ms-flex-pack: end;
	// 	justify-content: flex-end;
	// 	-webkit-box-align: start;
	// 	-webkit-align-items: flex-start;
	// 	-ms-flex-align: start;
	// 	align-items: flex-start;
	// 	background-image: url('../images/1-1-1.png');
	// 	background-position: 50% 50%;
	// 	background-size: cover;
	// 	text-align: center;
	// 	cursor: pointer;
	//   }
	  
	//   .div-block-72:hover {
	// 	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/1-1-1.png');
	// 	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/1-1-1.png');
	// 	background-position: 0px 0px, 50% 50%;
	// 	background-size: auto, cover;
	//   }
	  
	//   .div-block-73 {
	// 	display: -webkit-box;
	// 	display: -webkit-flex;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	height: 300px;
	// 	padding: 20px;
	// 	-webkit-box-orient: vertical;
	// 	-webkit-box-direction: normal;
	// 	-webkit-flex-direction: column;
	// 	-ms-flex-direction: column;
	// 	flex-direction: column;
	// 	-webkit-box-pack: end;
	// 	-webkit-justify-content: flex-end;
	// 	-ms-flex-pack: end;
	// 	justify-content: flex-end;
	// 	-webkit-box-align: start;
	// 	-webkit-align-items: flex-start;
	// 	-ms-flex-align: start;
	// 	align-items: flex-start;
	// 	background-image: url('../images/5-1-1.png');
	// 	background-position: 100% 100%;
	// 	background-size: cover;
	// 	text-align: center;
	// 	cursor: pointer;
	//   }
	  
	//   .div-block-73:hover {
	// 	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/5-1-1.png');
	// 	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/5-1-1.png');
	// 	background-position: 0px 0px, 100% 50%;
	// 	background-size: auto, cover;
	//   }
	  
	  .image-25 {
		display: none;
		padding-top: 20px;
		text-align: center;
	  }
	  
	//   .div-block-74 {
	// 	position: relative;
	// 	display: -webkit-box;
	// 	display: -webkit-flex;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	height: 300px;
	// 	padding: 20px;
	// 	-webkit-box-orient: vertical;
	// 	-webkit-box-direction: normal;
	// 	-webkit-flex-direction: column;
	// 	-ms-flex-direction: column;
	// 	flex-direction: column;
	// 	-webkit-box-pack: end;
	// 	-webkit-justify-content: flex-end;
	// 	-ms-flex-pack: end;
	// 	justify-content: flex-end;
	// 	-webkit-box-align: start;
	// 	-webkit-align-items: flex-start;
	// 	-ms-flex-align: start;
	// 	align-items: flex-start;
	// 	background-image: url('../images/16-1.png');
	// 	background-position: 100% 50%;
	// 	background-size: cover;
	// 	text-align: center;
	// 	cursor: pointer;
	//   }
	  
	//   .div-block-74:hover {
	// 	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/16-1.png');
	// 	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/16-1.png');
	// 	background-position: 0px 0px, 100% 50%;
	// 	background-size: auto, cover;
	//   }
	  
	//   .div-block-75 {
	// 	position: relative;
	// 	display: -webkit-box;
	// 	display: -webkit-flex;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	height: 300px;
	// 	padding: 20px;
	// 	-webkit-box-orient: vertical;
	// 	-webkit-box-direction: normal;
	// 	-webkit-flex-direction: column;
	// 	-ms-flex-direction: column;
	// 	flex-direction: column;
	// 	-webkit-box-pack: end;
	// 	-webkit-justify-content: flex-end;
	// 	-ms-flex-pack: end;
	// 	justify-content: flex-end;
	// 	-webkit-box-align: start;
	// 	-webkit-align-items: flex-start;
	// 	-ms-flex-align: start;
	// 	align-items: flex-start;
	// 	background-image: url('../images/12-1.png');
	// 	background-position: 100% 50%;
	// 	background-size: cover;
	// 	text-align: center;
	// 	cursor: pointer;
	//   }
	  
	//   .div-block-75:hover {
	// 	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/12-1.png');
	// 	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/12-1.png');
	// 	background-position: 0px 0px, 100% 50%;
	// 	background-size: auto, cover;
	//   }
	  
	  .image-26 {
		display: none;
		padding-top: 20px;
	  }
	  
	  .image-27 {
		display: none;
		padding-top: 20px;
	  }
	  
	  .image-28 {
		display: none;
		padding-top: 20px;
	  }
	  
	  .section-15 {
		padding-top: 0px;
	  }
	  
	  .section-16 {
		padding: 60px 15px;
		background-color: #fff;
	  }
	  
	  .heading-115 {
		margin-top: 0px;
		margin-bottom: 40px;
		font-family: 'Avenir-Book', sans-serif;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .text-span-3 {
		display: block;
	  }
	  
	  .text-span-4 {
		display: block;
	  }
	  
	  .text-span-5 {
		display: inline-block;
	  }
	  
	  .text-span-6 {
		display: inline-block;
	  }
	  
	  .grid-4 {
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto auto;
		grid-template-rows: auto auto auto;
	  }
	  
	  .div-block-76 {
		padding: 20px;
		background-color: #fff;
	  }
	  
	  .div-block-77 {
		padding: 20px;
		background-color: #fff;
	  }
	  
	  .div-block-78 {
		padding: 20px;
		background-color: #fff;
	  }
	  
	  .div-block-79 {
		margin-bottom: 20px;
		padding: 20px 20px 0px;
		background-color: #fff;
	  }
	  
	  .div-block-80 {
		margin-bottom: 20px;
		padding: 20px 20px 0px;
		background-color: #fff;
	  }
	  
	  .div-block-81 {
		padding: 20px 20px 0px;
		background-color: #fff;
	  }
	  
	  .columns-69 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-131 {
		text-align: center;
	  }
	  
	  .columns-70 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-132 {
		text-align: center;
	  }
	  
	  .column-133 {
		text-align: center;
	  }
	  
	  .columns-71 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .columns-72 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	  }
	  
	  .column-140 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .container-28 {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	  }
	  
	  .div-block-82 {
		background-color: #8ad5d5;
	  }
	  
	  .heading-116 {
		color: #000;
	  }
	  
	  .link-block-9 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .link-block-10 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .image-29 {
		margin-right: 0px;
	  }
	  
	  .image-30 {
		margin-right: 0px;
	  }
	  
	  .image-31 {
		margin-right: 0px;
	  }
	  
	  .image-32 {
		margin-right: 10px;
	  }
	  
	  .image-33 {
		margin-right: 10px;
	  }
	  
	  .image-34 {
		margin-right: 10px;
	  }
	  
	  .image-35 {
		margin-right: 10px;
	  }
	  
	  .section-17 {
		padding: 100px 15px;
	  }
	  
	  .div-block-83 {
		position: relative;
		padding: 20px;
		box-shadow: 0 1px 1px 1px #ded8d8;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .div-block-83.x.b {
		margin-bottom: 0px;
	  }
	  
	  .div-block-83.x.g {
		margin-bottom: 0px;
	  }
	  
	  .div-block-83.s {
		margin-bottom: 0px;
	  }
	  
	  .div-block-84 {
		position: relative;
		padding: 20px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .div-block-85 {
		padding: 20px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .div-block-86 {
		padding: 20px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .grid-5 {
		grid-column-gap: 9px;
		grid-row-gap: 15px;
	  }
	  
	  .columns-73 {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	  }
	  
	  .text-block-15 {
		color: #fff;
	  }
	  
	  .link-15 {
		font-family: 'Avenir-Book', sans-serif;
		color: #000;
		font-size: 18px;
		text-align: left;
		text-decoration: none;
	  }
	  
	  .link-15:hover {
		text-decoration: underline;
	  }
	  
	  .div-block-87 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	  }
	  
	  .slider-2 {
		border-radius: 0px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-117 {
		margin-top: 100px;
		font-size: 28px;
		line-height: 33px;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .heading-118 {
		margin-top: 100px;
		font-size: 28px;
		line-height: 33px;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .heading-119 {
		margin-top: 100px;
		font-size: 28px;
		font-weight: 400;
	  }
	  
	  .slide-4 {
		position: relative;
		margin-top: 0px;
		border-radius: 10px;
		background-color: #f0e0cb;
	  }
	  
	  .slide-5 {
		background-color: #f0e0cb;
	  }
	  
	  .heading-120 {
		margin-top: 100px;
		font-size: 28px;
		font-weight: 400;
	  }
	  
	  .text-block-16 {
		margin-right: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 20px;
		line-height: 30px;
	  }
	  
	  .div-block-88 {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .columns-74 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-144 {
		position: -webkit-sticky;
		position: sticky;
		top: 10px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	  }
	  
	  .container-29 {
		position: static;
	  }
	  
	  .columns-75 {
		position: static;
		top: 0px;
	  }
	  
	  .column-145 {
		position: -webkit-sticky;
		position: sticky;
		top: 20%;
	  }
	  
	  .container-31 {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	  }
	  
	  .link-block-11 {
		margin-right: 20px;
		padding: 10px;
	  }
	  
	  .link-block-11:hover {
		background-color: #242424;
	  }
	  
	  .link-block-12 {
		margin-right: 20px;
		padding: 10px;
	  }
	  
	  .link-block-12:hover {
		background-color: #242424;
	  }
	  
	  .link-block-13 {
		margin-right: 20px;
		padding: 10px;
	  }
	  
	  .link-block-13:hover {
		background-color: #242424;
	  }
	  
	  .link-block-14 {
		margin-right: 20px;
		padding: 10px;
	  }
	  
	  .link-block-14:hover {
		background-color: #242424;
	  }
	  
	  .grid-6 {
		-ms-grid-columns: 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
	  }
	  
	  .column-148 {
		padding: 0px 5px;
	  }
	  
	  .column-149 {
		padding: 0px 5px 0px 0px;
	  }
	  
	  .column-150 {
		padding: 0px;
	  }
	  
	  .column-151 {
		padding: 0px;
	  }
	  
	  .column-152 {
		text-align: center;
	  }
	  
	  .container-32 {
		text-align: center;
	  }
	  
	  .image-36 {
		padding-top: 40px;
	  }
	  
	//   .section-18 {
	// 	display: -webkit-box;
	// 	display: -webkit-flex;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	height: 80vh;
	// 	padding-top: 0px;
	// 	padding-bottom: 0px;
	// 	-webkit-box-pack: center;
	// 	-webkit-justify-content: center;
	// 	-ms-flex-pack: center;
	// 	justify-content: center;
	// 	-webkit-box-align: center;
	// 	-webkit-align-items: center;
	// 	-ms-flex-align: center;
	// 	align-items: center;
	// 	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/man-in-crowd.png');
	// 	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/man-in-crowd.png');
	// 	background-position: 0px 0px, 100% 50%;
	// 	background-size: auto, cover;
	// 	background-repeat: repeat, repeat;
	// 	background-attachment: scroll, scroll;
	//   }
	  
	  .container-33 {
		color: #fff;
	  }
	  
	  .container-34 {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	  }
	  
	  .text-span-7 {
		color: hsla(0, 0%, 100%, 0.35);
		font-size: 38px;
	  }
	  
	  .text-span-8 {
		color: hsla(0, 0%, 100%, 0.45);
	  }
	  
	  .text-span-9 {
		color: hsla(0, 0%, 100%, 0.59);
		font-size: 50px;
	  }
	  
	//   .section-19 {
	// 	display: -webkit-box;
	// 	display: -webkit-flex;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	height: 80vh;
	// 	padding-bottom: 0px;
	// 	-webkit-box-pack: center;
	// 	-webkit-justify-content: center;
	// 	-ms-flex-pack: center;
	// 	justify-content: center;
	// 	-webkit-box-align: center;
	// 	-webkit-align-items: center;
	// 	-ms-flex-align: center;
	// 	align-items: center;
	// 	background-image: url('../images/image-37-1.png');
	// 	background-position: 50% 0%;
	// 	background-size: cover;
	// 	-webkit-filter: grayscale(100%);
	// 	filter: grayscale(100%);
	//   }
	  
	//   .section-19.x.k {
	// 	background-image: url('../images/products-on-marble-1.png');
	// 	background-position: 50% 100%;
	// 	background-size: cover;
	//   }
	  
	  .section-20 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100vh;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .columns-76 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-153 {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	  }
	  
	  .text-span-10 {
		display: block;
		font-family: 'Avenir-Book', sans-serif;
		color: #000;
		font-size: 38px;
		font-weight: 400;
	  }
	  
	  .section-21 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100vh;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .section-22 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100vh;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .heading-121 {
		margin-top: 0px;
	  }
	  
	  .paragraph-19 {
		display: block;
	  }
	  
	  .container-35 {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	  }
	  
	  .heading-122 {
		margin-top: 0px;
	  }
	  
	  .text-span-11 {
		color: hsla(0, 0%, 100%, 0.59);
		font-size: 50px;
	  }
	  
	  .heading-123 {
		margin-top: 0px;
		font-family: 'Avenir-Book', sans-serif;
		color: transparent;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .text-span-12 {
		color: rgba(51, 51, 51, 0.59);
		font-size: 50px;
	  }
	  
	  .grid-7 {
		-ms-grid-columns: 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
	  }
	  
	  .bold-text-12 {
		font-weight: 400;
	  }
	  
	  .link-block-15 {
		padding: 10px;
	  }
	  
	  .link-block-15:hover {
		background-color: #242424;
	  }
	  
	  .div-block-89 {
		padding: 20px;
		background-color: hsla(0, 0%, 100%, 0.9);
	  }
	  
	  .div-block-90 {
		padding: 20px;
		background-color: hsla(0, 0%, 100%, 0.9);
	  }
	  
	  .bold-text-13 {
		line-height: 55px;
	  }
	  
	  .bold-text-14 {
		color: #fff;
	  }
	  
	  .bold-text-15 {
		color: hsla(0, 0%, 100%, 0.52);
	  }
	  
	  .bold-text-16 {
		color: #000;
	  }
	  
	  .div-block-91 {
		padding: 20px;
		background-color: hsla(0, 0%, 100%, 0);
		opacity: 1;
	  }
	  
	  .bold-text-17 {
		color: #fff;
	  }
	  
	  .bold-text-18 {
		color: hsla(0, 0%, 100%, 0.52);
	  }
	  
	  .bold-text-19 {
		color: #000;
	  }
	  
	  .section-23 {
		height: auto;
	  }
	  
	//   .column-158 {
	// 	display: -webkit-box;
	// 	display: -webkit-flex;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	height: 80vh;
	// 	padding: 20px;
	// 	-webkit-box-pack: center;
	// 	-webkit-justify-content: center;
	// 	-ms-flex-pack: center;
	// 	justify-content: center;
	// 	-webkit-box-align: center;
	// 	-webkit-align-items: center;
	// 	-ms-flex-align: center;
	// 	align-items: center;
	// 	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/Untitled-design-12.png');
	// 	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/Untitled-design-12.png');
	// 	background-position: 0px 0px, 50% 50%;
	// 	background-size: auto, cover;
	// 	-webkit-filter: grayscale(100%);
	// 	filter: grayscale(100%);
	//   }
	  
	//   .column-159 {
	// 	display: -webkit-box;
	// 	display: -webkit-flex;
	// 	display: -ms-flexbox;
	// 	display: flex;
	// 	height: 80vh;
	// 	padding: 20px;
	// 	-webkit-box-pack: center;
	// 	-webkit-justify-content: center;
	// 	-ms-flex-pack: center;
	// 	justify-content: center;
	// 	-webkit-box-align: center;
	// 	-webkit-align-items: center;
	// 	-ms-flex-align: center;
	// 	align-items: center;
	// 	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/products-on-marble-1.png');
	// 	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/products-on-marble-1.png');
	// 	background-position: 0px 0px, 50% 50%;
	// 	background-size: auto, cover;
	// 	-webkit-filter: grayscale(100%);
	// 	filter: grayscale(100%);
	//   }
	  
	  .bold-text-20 {
		color: #fff;
	  }
	  
	  .bold-text-21 {
		color: hsla(0, 0%, 100%, 0.52);
	  }
	  
	  .grid-8 {
		-ms-grid-columns: 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
	  }
	  
	  .column-162 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	  }
	  
	  .text-block-17 {
		margin-top: 10px;
	  }
	  
	  .text-block-18 {
		margin-top: 10px;
	  }
	  
	  .text-block-19 {
		margin-top: 10px;
	  }
	  
	  .text-block-20 {
		margin-top: 10px;
	  }
	  
	  .text-block-21 {
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 5px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 20px;
		line-height: 22px;
		text-align: center;
	  }
	  
	  .div-block-92 {
		display: block;
		margin-top: 10px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	  }
	  
	  .link-16 {
		font-family: 'Avenir-Book', sans-serif;
		color: #000;
		font-size: 18px;
		text-decoration: none;
	  }
	  
	  .link-16:hover {
		text-decoration: underline;
	  }
	  
	  .grid-9 {
		-ms-grid-columns: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr;
	  }
	  
	  .div-block-93 {
		text-align: center;
	  }
	  
	  @media screen and (max-width: 991px) {
		.link-block-8 {
		  display: block;
		  text-align: left;
		}
	  
		.icon {
		  color: #c49b65;
		}
	  
		.menu-button {
		  background-color: #000;
		}
	  
		.menu-button.w--open {
		  background-color: #000;
		}
	  
		.how-it-works-2 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.about-us-2 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.shop-2 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.link-block-6 {
		  display: block;
		}
	  
		.nav-menu-2 {
		  display: block;
		  background-color: #000;
		}
	  
		.text-block-11 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.section {
		  background-position: 50% 50%;
		}
	  
		.slider {
		  padding-bottom: 0px;
		}
	  
		.heading-94 {
		  margin-top: 60px;
		}
	  
		.heading-26 {
		  margin-top: 60px;
		}
	  
		.heading-26.v {
		  margin-top: 0px;
		}
	  
		.heading-26.b {
		  margin-top: 0px;
		}
	  
		.heading-26.f {
		  margin-top: 0px;
		}
	  
		.heading-26.d {
		  margin-top: 0px;
		}
	  
		.heading-93 {
		  margin-top: 60px;
		  line-height: 33px;
		}
	  
		.slide {
		  position: relative;
		}
	  
		.slide-nav {
		  margin-top: -31px;
		}
	  
		.heading-92 {
		  margin-top: 60px;
		  line-height: 33px;
		}
	  
		.column-114 {
		  padding-right: 10px;
		  padding-left: 0px;
		}
	  
		.column-113 {
		  padding-right: 0px;
		}
	  
		.container-15 {
		  padding: 0px 10px;
		}
	  
		.section-2 {
		  background-position: 50% 50%;
		}
	  
		.div-block-25 {
		  padding: 20px;
		}
	  
		.div-block-59 {
		  display: block;
		  padding: 20px;
		}
	  
		.div-block-26 {
		  padding: 20px;
		}
	  
		.div-block-38 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-49 {
		  padding: 20px;
		}
	  
		.heading-99 {
		  line-height: 33px;
		  text-align: left;
		}
	  
		.div-block-72 {
		  background-position: 0% 50%;
		}
	  
		// .div-block-72:hover {
		//   background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('../images/1-1-1.png');
		//   background-image: linear-gradient(180deg, null, null), url('../images/1-1-1.png');
		//   background-position: 0px 0px, 0% 50%;
		// }
	  
		.div-block-73 {
		  background-position: 50% 50%;
		}
	  
		// .div-block-73:hover {
		//   background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('../images/5-1-1.png');
		//   background-image: linear-gradient(180deg, null, null), url('../images/5-1-1.png');
		//   background-position: 0px 0px, 50% 50%;
		// }
	  
		.div-block-74 {
		  background-position: 50% 50%;
		}
	  
		// .div-block-74:hover {
		//   background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('../images/16-1.png');
		//   background-image: linear-gradient(180deg, null, null), url('../images/16-1.png');
		//   background-position: 0px 0px, 50% 50%;
		// }
	  
		.div-block-75 {
		  background-position: 50% 50%;
		}
	  
		// .div-block-75:hover {
		//   background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('../images/12-1.png');
		//   background-image: linear-gradient(180deg, null, null), url('../images/12-1.png');
		//   background-position: 0px 0px, 50% 50%;
		// }
	  
		.link-15 {
		  display: block;
		  margin-left: 10px;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  
		.slider-2 {
		  padding-bottom: 0px;
		}
	  
		.heading-117 {
		  margin-top: 60px;
		}
	  
		.heading-118 {
		  margin-top: 60px;
		}
	  
		.heading-119 {
		  margin-top: 60px;
		  line-height: 33px;
		}
	  
		.slide-4 {
		  position: relative;
		}
	  
		.heading-120 {
		  margin-top: 60px;
		  line-height: 33px;
		}
	  
		.text-block-16 {
		  margin-right: 10px;
		}
	  
		.div-block-88 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-75 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.column-146 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.section-19 {
		  background-position: 50% 50%;
		}
	  
		.column-162 {
		  -webkit-box-pack: center;
		  -webkit-justify-content: center;
		  -ms-flex-pack: center;
		  justify-content: center;
		}
	  }
	  
	  @media screen and (max-width: 767px) {
		.icon {
		  color: #b99055;
		}
	  
		.how-it-works-2 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.about-us-2 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.shop-2 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.icon-2 {
		  margin-right: 20px;
		}
	  
		.icon-4 {
		  margin-right: 20px;
		}
	  
		.container-9 {
		  padding-right: 0px;
		  padding-left: 20px;
		}
	  
		.section {
		  padding-top: 0px;
		  padding-bottom: 0px;
		  background-position: 50% 50%;
		}
	  
		.container-10 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.text-block {
		  margin-top: 20px;
		}
	  
		.heading-94 {
		  margin-top: 60px;
		  font-weight: 400;
		}
	  
		.heading-26 {
		  margin-top: 60px;
		  font-weight: 400;
		}
	  
		.heading-26.f {
		  font-size: 22px;
		  line-height: 33px;
		}
	  
		.column-14 {
		  margin-bottom: 0px;
		}
	  
		.image-14 {
		  margin-top: 0px;
		}
	  
		.column-114 {
		  padding-left: 0px;
		}
	  
		.column-113 {
		  padding-right: 0px;
		}
	  
		.heading-46 {
		  margin-top: 0px;
		}
	  
		.container-15 {
		  padding: 0px 20px;
		}
	  
		.section-2 {
		  background-position: 50% 50%;
		}
	  
		.div-block-25 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding: 20px;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.paragraph-9 {
		  margin-top: 10px;
		  margin-bottom: 0px;
		  text-align: left;
		}
	  
		.heading-55.g {
		  text-align: left;
		}
	  
		.heading-55.a {
		  text-align: left;
		}
	  
		.heading-95 {
		  display: block;
		}
	  
		.div-block-59 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding: 20px;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.column-51 {
		  padding-right: 0px;
		  padding-bottom: 20px;
		  padding-left: 0px;
		}
	  
		.div-block-26 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding: 20px;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.paragraph-12 {
		  margin-top: 10px;
		  margin-bottom: 0px;
		}
	  
		.container-3 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.paragraph-13 {
		  text-align: left;
		}
	  
		.paragraph-11 {
		  margin-top: 10px;
		  text-align: left;
		}
	  
		.paragraph-11.g {
		  text-align: left;
		}
	  
		.column-109 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.image-16 {
		  display: inline-block;
		}
	  
		.heading-75 {
		  text-align: left;
		}
	  
		.section-7 {
		  padding-bottom: 100px;
		}
	  
		.heading-53 {
		  text-align: left;
		}
	  
		.column-48 {
		  display: block;
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-49 {
		  display: block;
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.columns-25 {
		  display: block;
		}
	  
		.div-block-38 {
		  margin-bottom: 20px;
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-49 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding: 20px;
		}
	  
		.container-4 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		// .section-11 {
		//   background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/man-coffee-window.png');
		//   background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/man-coffee-window.png');
		//   background-position: 0px 0px, 50% 50%;
		//   background-size: auto, cover;
		//   background-repeat: repeat, repeat;
		//   background-attachment: scroll, scroll;
		// }
	  
		.heading-102 {
		  color: #fff;
		}
	  
		.heading-103 {
		  margin-top: 0px;
		}
	  
		.container-16 {
		  padding-right: 20px;
		  padding-left: 20px;
		  text-align: center;
		}
	  
		.columns-66 {
		  display: block;
		}
	  
		.column-115 {
		  display: block;
		  padding-right: 0px;
		  padding-left: 0px;
		  -webkit-box-pack: center;
		  -webkit-justify-content: center;
		  -ms-flex-pack: center;
		  justify-content: center;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		  text-align: center;
		}
	  
		.container-17 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-116 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-117 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-118 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-119 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-18 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-120 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-121 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-122 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-19 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-123 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.image-21 {
		  display: block;
		}
	  
		.grid {
		  grid-auto-flow: column;
		  -ms-grid-columns: 1fr 1fr;
		  grid-template-columns: 1fr 1fr;
		  -ms-grid-rows: auto auto;
		  grid-template-rows: auto auto;
		}
	  
		.image-22 {
		  padding-top: 40px;
		}
	  
		.heading-37 {
		  line-height: 30px;
		}
	  
		.container-5 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading-34 {
		  line-height: 30px;
		}
	  
		.div-block-34 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-67 {
		  display: block;
		}
	  
		.column-124 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-125 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.div-block-70 {
		  margin-top: 20px;
		}
	  
		.container-20 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading-105 {
		  margin-top: 20px;
		  margin-bottom: 0px;
		}
	  
		.heading-106 {
		  margin-top: 20px;
		  margin-bottom: 0px;
		}
	  
		.column-126 {
		  margin-top: 0px;
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-128 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-22 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading-110 {
		  margin-top: 20px;
		  margin-bottom: 0px;
		}
	  
		.container-24 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading-111 {
		  margin-bottom: 0px;
		}
	  
		.heading-112 {
		  margin-bottom: 0px;
		}
	  
		.column-129 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.heading-113 {
		  margin-top: 20px;
		  margin-bottom: 0px;
		}
	  
		.container-25 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-130 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.div-block-72 {
		  margin-bottom: 20px;
		}
	  
		.div-block-73 {
		  margin-bottom: 20px;
		}
	  
		.div-block-74 {
		  margin-bottom: 20px;
		}
	  
		.section-16 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading-115 {
		  text-align: left;
		}
	  
		.container-26 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-79 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		}
	  
		.div-block-80 {
		  display: block;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		}
	  
		.columns-69 {
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.columns-70 {
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.columns-71 {
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.columns-72 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		}
	  
		.column-137 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-138 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-139 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-140 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding-right: 0px;
		  padding-left: 0px;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  
		.container-27 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.container-28 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-141 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.section-17 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-83.x.b {
		  margin-top: 20px;
		  margin-bottom: 0px;
		}
	  
		.div-block-83.x.f {
		  display: none;
		  margin-top: 20px;
		  margin-bottom: 20px;
		}
	  
		.div-block-83.x.f.dd {
		  display: block;
		}
	  
		.div-block-83.x.g {
		  margin-top: 20px;
		  margin-bottom: 20px;
		}
	  
		.div-block-83.s {
		  display: block;
		  margin-top: 20px;
		}
	  
		.column-142 {
		  text-align: left;
		}
	  
		.column-143 {
		  text-align: center;
		}
	  
		.link-15 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  
		.heading-117 {
		  margin-top: 60px;
		  font-weight: 400;
		}
	  
		.heading-118 {
		  margin-top: 60px;
		  font-weight: 400;
		}
	  
		.column-145 {
		  position: static;
		  display: block;
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-30 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-147 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-31 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.container-32 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.section-18 {
		  padding-top: 0px;
		  padding-bottom: 0px;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		  background-position: 0% 50%;
		}
	  
		.container-34 {
		  display: block;
		  padding-right: 20px;
		  padding-left: 20px;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  
		.section-19 {
		  padding-top: 0px;
		  padding-bottom: 0px;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.section-19.x.k {
		  background-position: 50% 50%;
		}
	  
		.container-35 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.container-36 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-154 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-155 {
		  display: none;
		  padding-right: 0px;
		  padding-left: 0px;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.heading-123 {
		  text-align: left;
		}
	  
		.column-156 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.columns-77 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-78 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-79 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		}
	  
		.container-37 {
		  padding-right: 20px;
		  padding-left: 0px;
		}
	  
		.column-157 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.grid-7 {
		  display: block;
		}
	  
		.bold-text-12 {
		  text-align: left;
		}
	  
		.columns-80 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		}
	  
		.columns-81 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.column-160 {
		  display: none;
		}
	  
		.column-161 {
		  display: none;
		}
	  
		.column-162 {
		  padding-right: 0px;
		  padding-left: 0px;
		  -webkit-box-align: start;
		  -webkit-align-items: flex-start;
		  -ms-flex-align: start;
		  align-items: flex-start;
		}
	  
		.text-block-21 {
		  margin-right: 10px;
		  margin-bottom: 0px;
		}
	  
		.div-block-92 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.column-163 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-164 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-165 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.grid-9 {
		  display: block;
		}
	  
		.div-block-93 {
		  text-align: left;
		}
	  
		.div-block-94 {
		  text-align: left;
		}
	  }
	  
	  @media screen and (max-width: 479px) {
		.menu-button {
		  position: relative;
		  margin-left: 0px;
		  -webkit-box-ordinal-group: 1;
		  -webkit-order: 0;
		  -ms-flex-order: 0;
		  order: 0;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  
		.menu-button.w--open {
		  background-color: #000;
		}
	  
		.container-9 {
		  display: block;
		  padding-right: 0px;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		  -webkit-box-pack: justify;
		  -webkit-justify-content: space-between;
		  -ms-flex-pack: justify;
		  justify-content: space-between;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.image-4 {
		  margin-top: 20px;
		}
	  
		.section {
		  height: auto;
		  min-height: 0px;
		  padding-top: 80px;
		  padding-bottom: 40px;
		  background-position: 50% 50%;
		}
	  
		.text-block {
		  margin-bottom: 0px;
		  padding-bottom: 0px;
		}
	  
		.heading-94 {
		  margin-top: 40px;
		  font-size: 28px;
		  font-weight: 400;
		}
	  
		.heading-26 {
		  margin-top: 40px;
		  font-weight: 400;
		}
	  
		.heading-93 {
		  margin-top: 40px;
		  font-size: 25px;
		}
	  
		.slide-nav {
		  position: absolute;
		  margin-top: 0px;
		  font-weight: 400;
		}
	  
		.heading-92 {
		  margin-top: 40px;
		  font-size: 28px;
		  line-height: 33px;
		  font-weight: 400;
		}
	  
		.column-114 {
		  padding-right: 0px;
		}
	  
		.column-113 {
		  padding-left: 0px;
		  text-align: center;
		}
	  
		.columns-5 {
		  display: block;
		}
	  
		.container-15 {
		  margin-top: 0px;
		  padding-top: 0px;
		  text-align: center;
		}
	  
		.image-20 {
		  padding-top: 40px;
		}
	  
		.paragraph-9 {
		  color: #fff;
		}
	  
		.heading-53 {
		  text-align: left;
		}
	  
		.div-block-38 {
		  padding-right: 20px;
		  padding-left: 20px;
		  background-color: rgba(255, 255, 255, 0.9);
		}
	  
		.div-block-49 {
		  padding-right: 20px;
		  padding-left: 20px;
		  background-color: rgba(255, 255, 255, 0.9);
		}
	  
		// .section-11 {
		//   display: -webkit-box;
		//   display: -webkit-flex;
		//   display: -ms-flexbox;
		//   display: flex;
		//   height: auto;
		//   padding-top: 60px;
		//   padding-bottom: 60px;
		//   background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/man-coffee-window.png');
		//   background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/man-coffee-window.png');
		//   background-position: 0px 0px, 50% 50%;
		//   background-size: auto, cover;
		//   background-repeat: repeat, repeat;
		//   background-attachment: scroll, scroll;
		// }
	  
		.heading-99 {
		  text-align: left;
		}
	  
		.heading-102 {
		  line-height: 33px;
		}
	  
		.div-block-60 {
		  text-align: center;
		}
	  
		.div-block-61 {
		  text-align: center;
		}
	  
		.section-13 {
		  margin-top: 0px;
		  margin-bottom: 0px;
		  padding-top: 100px;
		  padding-bottom: 100px;
		}
	  
		.heading-104 {
		  margin-top: 0px;
		}
	  
		.grid {
		  grid-column-gap: 0px;
		}
	  
		.image-22 {
		  margin-top: 0px;
		  padding-top: 60px;
		}
	  
		.heading-37 {
		  line-height: 30px;
		}
	  
		.heading-34 {
		  line-height: 30px;
		}
	  
		.section-14 {
		  padding-bottom: 10px;
		}
	  
		.container-20 {
		  margin-top: 40px;
		}
	  
		.image-24 {
		  padding-bottom: 0px;
		}
	  
		.div-block-73 {
		  margin-top: 0px;
		}
	  
		.div-block-74 {
		  margin-top: 0px;
		}
	  
		.div-block-75 {
		  margin-top: 0px;
		}
	  
		.grid-3 {
		  display: block;
		  grid-auto-flow: row;
		}
	  
		.column-134 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-135 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-136 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.columns-72 {
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.column-140 {
		  margin-bottom: 40px;
		}
	  
		.column-141 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.heading-117 {
		  margin-top: 40px;
		  font-size: 28px;
		  font-weight: 400;
		}
	  
		.heading-118 {
		  margin-top: 40px;
		  font-weight: 400;
		}
	  
		.heading-119 {
		  margin-top: 40px;
		  font-size: 25px;
		}
	  
		.heading-120 {
		  margin-top: 40px;
		  font-size: 28px;
		  line-height: 33px;
		  font-weight: 400;
		}
	  
		.section-18 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  background-position: 50% 50%;
		}
	  
		// .section-19 {
		//   background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.45)), to(rgba(0, 0, 0, 0.45))), url('../images/image-37-1.png');
		//   background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url('../images/image-37-1.png');
		//   background-position: 0px 0px, 0% 50%;
		// }
	  
		.section-19.x.k {
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		  background-position: 50% 100%;
		  background-size: cover;
		}
	  
		.columns-77 {
		  display: block;
		}
	  
		.columns-78 {
		  display: block;
		}
	  
		.columns-79 {
		  display: block;
		}
	  
		.column-157 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.div-block-90 {
		  background-color: hsla(0, 0%, 100%, 0.9);
		}
	  
		.div-block-91 {
		  background-color: hsla(0, 0%, 100%, 0);
		}
	  
		.columns-80 {
		  display: block;
		}
	  
		.columns-81 {
		  display: block;
		}
	  }
	  
	  #w-node-_10caeb6f-06a3-bd6c-e665-6cbf74c4db18-806d9d79 {
		-ms-grid-column-align: center;
		justify-self: center;
	  }
	  .error {
		border-bottom: 1px solid red !important; 
	 }

	 .validation-error{
		margin-top: 10px;
		padding: 10px;
		background-color: #ffdede;
		margin-bottom: 10px;
	 }

	 .success-message{
		padding: 20px;
		text-align: center;
		background-color: #dddddd;
		margin-bottom: 10px;
	 }
	  

}
