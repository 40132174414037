@import "src/variables";

.SecurityLabel {
  margin-top: 72px;
  margin-bottom: 24px;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
      height: 19px;
    }

    .label {
      padding-left: 10px;
      font-family: "Avenir-Book", serif;
      font-size: 12px;
      color: $main-black;
      padding-top: 3px;
    }
  }
}
