@import '../../../../variables';
@import '../../styles';

.patient-checkout-details-component {
	.top-title {
		width: 100%;
		padding: 15px 0;
		display: flex;
		flex-direction: row;
		letter-spacing: 0.04em;
		font-size: 0.8em;
		p {
			color: $main-gold;
			font-size: 16px;
			padding-right: 15px;
		}
		.horizontal-line {
			flex: auto;
			border-top: 1px solid $main-gold;
			margin-top: 8px;
		}
	}

	.shipping-part,
	.credit-part,
	.billing-part,
	.identification-part {
		padding: 10px;
		background-color: #efefef;
		color: $main-black;
		font-family: "Avenir-Book", serif;
	}
	.identification-part {
		color: #e26161;
	}
}

.column-title-edit{
	display: flex;
	align-items: center;
}

@media screen and (max-width: 479px) {
	.div-block-17{
		padding-left: 0px;
		padding-right: 0px;
	}
}