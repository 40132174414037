@import '../../../../variables';

.admin-dashboard-promo-codes {
	padding: 0 30px;
	.add-section {
		padding-bottom: 15px;
		border-bottom: 1px solid #969595;
		.add-promo-title {
			font-size: 24px;
			font-family: "Avenir-Medium", serif;
		}
		.add-code-form {
			display: flex;
			align-items: flex-start;
			margin-top: 15px;
			justify-content: space-between;
			.field-title {
				margin-right: 10px;
			}
			.name-input {
				display: flex;
				align-items: center;
			}
			.promo-dates {
				.date-input {
					display: flex;
					justify-content: space-between;
					align-items: center;
					&:first-child {
						margin-bottom: 10px;
					}
				}
			}
			.promo-type-and-value {
				.promo-type-select {
					display: flex;
					margin-bottom: 10px;
					align-items: center;
					span {
						flex-shrink: 0;
					}
					select {
						width: 100%;
					}
				}
				.value-input {
					display: flex;
					align-items: center;
				}
			}
			.submit-btn {
				font-family: "Avenir-Medium", serif;
				color: #fff;
				padding: 10px;
				border-radius: 4px;
				background-color: #4bb543;
				cursor: pointer;
				align-self: center;
			}
		}
		.validation-error {
			font-family: "Avenir-Book", serif;
			color: #e26161;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
		}
	}
	.codes-section {
		display: flex;
		flex-direction: column;
		.promocode-item {
			box-sizing: border-box;
			display: flex;
			width: 100%;
			height: 70px;
			align-items: center;
			justify-content: space-between;
			background-color: #fff;
			margin: 15px 0;
			padding: 15px;
			.delete-btn {
				font-family: "Avenir-Medium", serif;
				color: #fff;
				padding: 10px;
				border-radius: 4px;
				background-color: #e26161;
				cursor: pointer;
			}
		}
	}
}
