$info-strip-height: 60px;

/* Colors */
$main-black: #404040;
$main-gold: #8B6227;
$main-text-gray: #686868;
$main-text-gray-2: #666666;

$light-gold: #bb9157;

:root {
    --main-gold: #8B6227; 
}