@import '../../variables';

.Tooltip {
	display: inline-block;
	position: relative;
	cursor: pointer;
	height: 20px;

	&__icon {
		mask: url("../../static/images/icon_help.svg") no-repeat center center;
		width: 20px;
		height: 20px;
		display: inline-block;
		background-color: #64676C;
		transform: scale(1.36);
	}

	&__crossWrapper {
		border: 1px solid $main-gold;
		border-radius: 50%;
		width: 15px;
		height: 15px;
		position: absolute;
		top: 0;
		right: 0;
		padding: 4px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-color: white;
	}

	&__cross {
		mask: url("../../static/images/cross-white.svg") no-repeat center center;
		display: inline-flex;
		background-color: #979797;
		width: 12px;
		height: 11px;
	}

	&__content {
		position: relative;
		width: 100%;
	}

	&__top {
		min-width: 320px;
		top: -20px;
		left: 45px;
		transform: translate(-50%, -100%);
		padding: 10px 20px;
		color: #444444;
		background-color: #D8D8D8;
		font-weight: normal;
		font-size: 13px;
		border-radius: 25px;
		position: absolute;
		z-index: 99999999;
		box-sizing: border-box;
		box-shadow: 0 1px 8px rgba(0, 0, 0, 0.17);
		display: none;

		b {
			font-family: Avenir-Heavy;
		}

		p {
			font-family: Avenir-Book;
		}

		&_visible {
			display: block;
		}
	}

	&__arrow {
		position: absolute;
		top: 100%;
		left: 32%;
		width: 34px;
		height: 32px;
		overflow: hidden;

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			background: url("../../static/images/tooltip_arrow.png") no-repeat center center;
			width: 40px;
			height: 50px;
			display: inline-block;
			transform: translate(-48%, -40%);
			box-shadow: 0 1px 8px rgba(0, 0, 0, 0.17);
		}
	}
}
