@import '../../variables';

@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';

.rbc-calendar {
	.rbc-allday-cell {
		display: none;
	}

	.rbc-time-content {
		border-top: none;
	}

	.rbc-btn-group {
		button:disabled {
			opacity: .4;
		}
	}
}
