@import '../../variables';

.doctorConsultations {
	display: flex;
	flex-flow: column nowrap;
	background-color: white;
	font-family: "Avenir-Medium", serif;
}

.doctorConsultations__btnSwitch {
	&,
	&:focus {
		margin: 10px;
		max-width: 250px;
		min-width: 200px;
		width: max-content;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
		letter-spacing: 1px;
		font-size: 13px;
		line-height: 13px;
		cursor: pointer;
		height: 35px;
		border: 2px solid $main-gold;
		color: $main-gold;
		outline: none;
	}
}
