@import '../../variables';

	//new styles

	// .right-area .right{
	// 	width: 100% !important;
	// 	padding: 0px !important;
	// }

	// .right-area .left{
	// 	display: none;
	// }

	.googleImg{
		width: 30px;
	}

#lr{  

	a:hover{
		text-decoration: none;
	}

	.button-9:hover{
		color:#fff;
	}

	.button-11:hover{
		color: #fff;
	}

	button.button-11.w-button{
		width: 100%;
	}

	.w-checkbox {
		display: block;
		margin-bottom: 5px;
		padding-left: 20px;
	  }
	  
	  .w-checkbox::before {
		content: ' ';
		display: table;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
	  }
	  
	  .w-checkbox::after {
		content: ' ';
		display: table;
		-ms-grid-column-span: 1;
		grid-column-end: 2;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		clear: both;
	  }
	  
	  .w-checkbox-input {
		float: left;
		margin-bottom: 0px;
		margin-left: -20px;
		margin-right: 0px;
		margin-top: 4px;
		line-height: normal;
	  }
	  
	  .w-checkbox-input--inputType-custom {
		border-top-width: 1px;
		border-bottom-width: 1px;
		border-left-width: 1px;
		border-right-width: 1px;
		border-top-color: #ccc;
		border-bottom-color: #ccc;
		border-left-color: #ccc;
		border-right-color: #ccc;
		border-top-style: solid;
		border-bottom-style: solid;
		border-left-style: solid;
		border-right-style: solid;
		width: 12px;
		height: 12px;
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
	  }
	  
	  .w-checkbox-input--inputType-custom.w--redirected-checked {
		background-color: #3898ec;
		border-top-color: #3898ec;
		border-bottom-color: #3898ec;
		border-left-color: #3898ec;
		border-right-color: #3898ec;
		background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	  }
	  
	  .w-checkbox-input--inputType-custom.w--redirected-focus {
		box-shadow: 0px 0px 3px 1px #3898ec;
	  }
	  
	  .w-form-formradioinput--inputType-custom {
		border-top-width: 1px;
		border-bottom-width: 1px;
		border-left-width: 1px;
		border-right-width: 1px;
		border-top-color: #ccc;
		border-bottom-color: #ccc;
		border-left-color: #ccc;
		border-right-color: #ccc;
		border-top-style: solid;
		border-bottom-style: solid;
		border-left-style: solid;
		border-right-style: solid;
		width: 12px;
		height: 12px;
		border-bottom-left-radius: 50%;
		border-bottom-right-radius: 50%;
		border-top-left-radius: 50%;
		border-top-right-radius: 50%;
	  }
	  
	  .w-form-formradioinput--inputType-custom.w--redirected-focus {
		box-shadow: 0px 0px 3px 1px #3898ec;
	  }
	  
	  .w-form-formradioinput--inputType-custom.w--redirected-checked {
		border-top-width: 4px;
		border-bottom-width: 4px;
		border-left-width: 4px;
		border-right-width: 4px;
		border-top-color: #3898ec;
		border-bottom-color: #3898ec;
		border-left-color: #3898ec;
		border-right-color: #3898ec;
	  }
	  
	  .section {
		margin-top: 0px;
		padding-top: 100px;
		padding-bottom: 0px;
		font-family: 'Josefin Sans', sans-serif;
	  }
	  
	  .text-field {
		padding-top: 30px;
		padding-bottom: 30px;
		border-radius: 5px;
	  }
	  
	  .text-field:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-2 {
		padding-top: 30px;
		padding-bottom: 30px;
		border-radius: 5px;
		background-color: #fff;
	  }
	  
	  .text-field-2:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .submit-button {
		display: block;
		margin-top: 40px;
		padding-right: 141px;
		padding-left: 181px;
		font-size: 18px;
	  }
	  
	  .paragraph {
		margin-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 14px;
	  }
	  
	  .checkbox-label {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .button {
		display: block;
		margin-top: 10px;
		margin-bottom: 140px;
		padding: 15px;
		border-radius: 5px;
		font-family: 'Josefin Sans', sans-serif;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .button-2 {
		display: block;
		padding-right: 10px;
		padding-left: 10px;
		// border-bottom: 3px solid #000;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
		color: #000;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .button-3 {
		display: block;
		padding-right: 10px;
		padding-left: 10px;
		border-bottom: 3px solid #d9d9d9;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
		color: #000;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
		text-decoration: none;
	  }
	  
	  .container {
		margin-top: 0px;
	  }
	  
	  .heading {
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 30px;
		font-weight: 600;
	  }
	  
	  .form {
		margin-top: 0px;
	  }
	  
	  .checkbox-field {
		margin-top: 40px;
		margin-bottom: 40px;
		font-weight: 400;
	  }
	  
	  .button-4 {
		padding-right: 140px;
		padding-left: 140px;
		font-size: 18px;
	  }
	  
	  .heading-2 {
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 30px;
		font-weight: 600;
	  }
	  
	  .paragraph-2 {
		margin-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 14px;
		font-weight: 400;
	  }
	  
	  .text-field-3 {
		padding-top: 30px;
		padding-bottom: 30px;
		border-radius: 5px;
	  }
	  
	  .text-field-3:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-4 {
		margin-bottom: 40px;
		padding-top: 30px;
		padding-bottom: 30px;
		border-radius: 5px;
	  }
	  
	  .text-field-4:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .button-5 {
		display: block;
		padding-right: 10px;
		padding-left: 10px;
		border-bottom: 3px solid #d9d9d9;
		background-color: #fff;
		color: #000;
		font-size: 18px;
		text-align: center;
	  }
	  
	  .button-6 {
		display: block;
		padding-right: 10px;
		padding-left: 10px;
		border-bottom: 3px solid #000;
		background-color: #fff;
		color: #000;
		font-size: 18px;
		text-align: center;
	  }
	  
	  .section-2 {
		padding-top: 0px;
		padding-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .section-3 {
		padding-top: 140px;
		padding-bottom: 140px;
	  }
	  
	  .paragraph-3 {
		font-size: 12px;
	  }
	  
	  .paragraph-4 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 14px;
	  }
	  
	  .text-block {
		margin-top: 60px;
		margin-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		font-weight: 700;
	  }
	  
	  .text-field-5 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-5:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-6 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-6:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-7 {
		display: block;
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 0px;
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-7:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-8 {
		margin-bottom: 0px;
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-8:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-9 {
		margin-bottom: 0px;
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-9:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .column {
		position: static;
		right: auto;
		display: inline-block;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		grid-auto-columns: 1fr;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	  }
	  
	  .columns {
		display: inline-block;
		margin-top: 0px;
		padding-right: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	  }
	  
	  .heading-3 {
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 30px;
		font-weight: 600;
	  }
	  
	  .text-block-2 {
		padding-right: 0px;
	  }
	  
	  .column-2 {
		padding-right: 0px;
	  }
	  
	  .select-field {
		display: inline-block;
		padding-right: 10px;
		padding-left: 10px;
	  }
	  
	  .text-block-3 {
		margin-top: 60px;
		margin-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		font-weight: 700;
	  }
	  
	  .paragraph-5 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 14px;
	  }
	  
	  .field-label {
		margin-top: 60px;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .text-block-4 {
		margin-top: 60px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		font-weight: 700;
	  }
	  
	  .text-block-5 {
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .submit-button-2 {
		display: block;
		padding-right: 10px;
		padding-left: 10px;
		font-size: 18px;
	  }
	  
	  .section-4 {
		padding-top: 100px;
		padding-bottom: 140px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .columns-2 {
		padding-right: 0px;
	  }
	  
	  .column-3 {
		padding-right: 0px;
	  }
	  
	  .text-field-10 {
		padding: 30px 10px;
	  }
	  
	  .text-field-11 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-11:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-12 {
		padding: 30px 10px;
	  }
	  
	  .div-block {
		padding-right: 21px;
	  }
	  
	  .text-field-13 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-13:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-14 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
		font-weight: 400;
	  }
	  
	  .text-field-14:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .div-block-2 {
		padding-right: 5px;
	  }
	  
	  .field-label-2 {
		margin-top: 60px;
		margin-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .text-block-6 {
		padding: 30px 0px 30px 10px;
		background-color: #f2f2f2;
	  }
	  
	  .div-block-3 {
		padding-right: 10px;
		background-color: #fff;
	  }
	  
	  .text-block-7 {
		padding: 30px 10px;
		background-color: #f2f2f2;
	  }
	  
	  .div-block-4 {
		background-color: #fff;
	  }
	  
	  .div-block-5 {
		margin-right: 5px;
		padding: 20px 10px;
		border-radius: 5px;
		background-color: hsla(0, 0%, 87.5%, 0.48);
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .div-block-6 {
		margin-left: 5px;
		border-radius: 5px;
		background-color: hsla(0, 0%, 87.5%, 0.48);
	  }
	  
	  .text-block-8 {
		padding: 20px 10px;
		border-radius: 0px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .columns-3 {
		margin-top: 20px;
		margin-bottom: 0px;
	  }
	  
	  .link {
		display: inline-block;
		margin-top: 20px;
		font-family: 'Avenir-Book', sans-serif;
		color: #000;
		text-decoration: none;
	  }
	  
	  .submit-button-3 {
		display: block;
		padding-right: 10px;
		padding-left: 10px;
	  }
	  
	  .heading-4 {
		margin-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 30px;
		font-weight: 600;
	  }
	  
	  .button-7 {
		display: block;
	  }
	  
	  .button-8 {
		display: block;
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 5px;
		background-color: #c49b65;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .button-9 {
		display: block;
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 5px;
		background-color: #c49b65;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .button-10 {
		display: block;
		margin-top: 10px;
		margin-bottom: 140px;
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 5px;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .column-4 {
		padding-right: 0px;
		padding-left: 0px;
	  }
	  
	  .button-11 {
		display: block;
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 5px;
		background-color: #c49b65;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .section-5 {
		margin-top: 0px;
		padding-top: 100px;
		padding-bottom: 140px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-block-9 {
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
	  }
	  
	  .text-block-10 {
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
	  }
	  
	  .text-block-11 {
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
	  }
	  
	  .text-block-12 {
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
	  }
	  
	  .text-block-13 {
		text-align: center;
		text-transform: uppercase;
	  }
	  
	  .columns-4 {
		margin-top: 20px;
		padding-bottom: 40px;
	  }
	  
	  .text-block-14 {
		margin-top: 0px;
		padding-left: 0px;
		font-family: 'Josefin Sans', sans-serif;
	  }
	  
	  .grid {
		margin-top: 40px;
		grid-template-areas: "Area";
		-ms-grid-columns: 0.5fr 0.5fr 0.25fr 1fr 1fr;
		grid-template-columns: 0.5fr 0.5fr 0.25fr 1fr 1fr;
		-ms-grid-rows: 53px auto;
		grid-template-rows: 53px auto;
	  }
	  
	  .column-5 {
		margin-bottom: 0px;
	  }
	  
	  .text-block-15 {
		text-align: center;
	  }
	  
	  .text-block-16 {
		border-radius: 0px;
		background-color: rgba(73, 124, 81, 0);
		text-align: center;
	  }
	  
	  .text-block-17 {
		text-align: center;
	  }
	  
	  .text-block-18 {
		text-align: center;
	  }
	  
	  .text-block-19 {
		text-align: center;
	  }
	  
	  .columns-5 {
		padding-bottom: 40px;
	  }
	  
	  .columns-6 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 10px;
		padding-bottom: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	  }
	  
	  .columns-7 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 10px;
		padding-bottom: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	  }
	  
	  .columns-8 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 30px;
		padding-bottom: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	  }
	  
	  .columns-9 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 30px;
		padding-bottom: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	  }
	  
	  .columns-10 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 30px;
		padding-bottom: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	  }
	  
	  .select-field-2 {
		border-radius: 5px;
		background-color: #f3f3f3;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .container-2 {
		margin-top: 60px;
	  }
	  
	  .heading-5 {
		margin-top: 60px;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .heading-6 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 30px;
		font-weight: 600;
	  }
	  
	  .heading-7 {
		margin-top: 10px;
		font-family: 'Avenir-Book', sans-serif;
		color: #333;
		font-size: 24px;
		line-height: 33px;
	  }
	  
	  .heading-8 {
		font-size: 16px;
	  }
	  
	  .heading-9 {
		margin-top: 60px;
		margin-bottom: 0px;
		font-family: 'Josefin Sans', sans-serif;
		font-size: 16px;
	  }
	  
	  .heading-10 {
		font-size: 16px;
	  }
	  
	  .heading-11 {
		margin-top: 60px;
		font-family: 'Josefin Sans', sans-serif;
		font-size: 16px;
	  }
	  
	  .textarea {
		margin-bottom: 0px;
		padding: 10px 10px 40px;
		border-radius: 5px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .textarea:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-15 {
		padding: 30px 10px;
		border-radius: 5px;
	  }
	  
	  .text-field-15:hover {
		background-color: hsla(0, 0%, 92.2%, 0.24);
	  }
	  
	  .section-6 {
		margin-bottom: 0px;
		padding-top: 100px;
		padding-bottom: 140px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-12 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 30px;
		font-weight: 600;
	  }
	  
	  .text-field-16 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-16:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-17 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-17:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-18 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-18:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .heading-13 {
		font-size: 16px;
	  }
	  
	  .heading-14 {
		margin-top: 30px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .heading-15 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 14px;
		line-height: 33px;
		font-weight: 600;
	  }
	  
	  .div-block-7 {
		padding-right: 5px;
	  }
	  
	  .div-block-8 {
		padding-left: 5px;
	  }
	  
	  .div-block-9 {
		padding-left: 5px;
	  }
	  
	  .container-3 {
		display: block;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 20px;
		padding-bottom: 20px;
	  }
	  
	  .navbar {
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		background-color: #fff;
		box-shadow: 1px 1px 3px 0 #959090;
	  }
	  
	  .columns-11 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: left;
	  }
	  
	  .image {
		margin-left: 0px;
		padding-left: 0px;
	  }
	  
	  .column-6 {
		display: block;
		padding-left: 0px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	  }
	  
	  .column-7 {
		text-align: center;
	  }
	  
	  .column-8 {
		text-align: left;
	  }
	  
	  .column-9 {
		text-align: center;
	  }
	  
	  .column-10 {
		text-align: right;
	  }
	  
	  .container-4 {
		margin-bottom: 40px;
	  }
	  
	  .column-11 {
		padding-right: 10px;
		padding-left: 0px;
		text-align: center;
	  }
	  
	  .div-block-10 {
		padding-top: 0px;
		background-color: #fff;
	  }
	  
	  .heading-16 {
		margin-top: 10px;
		font-family: 'Avenir-Book', sans-serif;
		color: #333;
		font-size: 16px;
	  }
	  
	  .text-span {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		font-weight: 700;
	  }
	  
	  .div-block-11 {
		padding-right: 20px;
		padding-bottom: 0px;
		padding-left: 20px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		background-color: #f0e1ca;
	  }
	  
	  .image-2 {
		display: block;
		padding-left: 0px;
	  }
	  
	  .column-12 {
		display: inline-block;
	  }
	  
	  .heading-17 {
		display: block;
		margin-top: 10px;
		margin-bottom: 0px;
		margin-left: 10px;
		padding-left: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
	  }
	  
	  .text-block-20 {
		font-size: 16px;
	  }
	  
	  .text-block-21 {
		padding-top: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		text-align: right;
	  }
	  
	  .text-block-22 {
		margin-left: 10px;
		padding-top: 0px;
		padding-left: 0px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .columns-12 {
		margin-top: 30px;
		padding-right: 0px;
		padding-left: 0px;
	  }
	  
	  .div-block-12 {
		padding: 10px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		background-color: #f2f2f2;
	  }
	  
	  .text-block-23 {
		margin-top: 20px;
		font-family: 'Avenir-Book', sans-serif;
		color: #333;
		text-align: right;
		text-decoration: underline;
	  }
	  
	  .column-13 {
		text-align: right;
	  }
	  
	  .column-14 {
		text-align: right;
	  }
	  
	  .column-15 {
		text-align: right;
	  }
	  
	  .column-16 {
		text-align: right;
	  }
	  
	  .columns-13 {
		margin-top: 20px;
	  }
	  
	  .columns-14 {
		margin-top: 20px;
	  }
	  
	  .columns-15 {
		margin-top: 30px;
	  }
	  
	  .columns-16 {
		margin-top: 20px;
	  }
	  
	  .div-block-13 {
		padding-right: 10px;
		padding-left: 10px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-18 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 16px;
	  }
	  
	  .heading-19 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-family: 'Josefin Sans', sans-serif;
		font-size: 16px;
	  }
	  
	  .column-17 {
		-webkit-align-self: baseline;
		-ms-flex-item-align: baseline;
		align-self: baseline;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
		text-align: right;
	  }
	  
	  .div-block-14 {
		margin-top: 20px;
		padding: 5px 20px;
		border-radius: 10px;
		background-color: #f2f2f2;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .columns-17 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .section-7 {
		padding-top: 100px;
		padding-bottom: 140px;
	  }
	  
	  .text-block-24 {
		margin-top: 60px;
		margin-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		text-align: right;
		text-decoration: underline;
	  }
	  
	  .heading-20 {
		font-size: 18px;
	  }
	  
	  .heading-21 {
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 20px;
		padding-left: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 24px;
		font-weight: 600;
	  }
	  
	  .text-block-25 {
		text-align: right;
	  }
	  
	  .text-block-26 {
		text-align: right;
	  }
	  
	  .text-block-27 {
		text-align: right;
	  }
	  
	  .text-block-28 {
		text-align: right;
	  }
	  
	  .text-block-29 {
		text-align: right;
	  }
	  
	  .columns-18 {
		margin-top: 20px;
	  }
	  
	  .column-18 {
		margin-top: 0px;
	  }
	  
	  .div-block-15 {
		margin-top: 40px;
		padding-right: 20px;
		padding-left: 20px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .columns-19 {
		margin-top: 20px;
	  }
	  
	  .columns-20 {
		margin-top: 20px;
	  }
	  
	  .columns-21 {
		margin-top: 20px;
	  }
	  
	  .heading-22 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 30px;
		font-weight: 600;
	  }
	  
	  .div-block-16 {
		margin-top: 40px;
		padding: 20px;
		border-radius: 10px;
		background-color: #f0e1ca;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .div-block-17 {
		margin-top: 60px;
		padding: 0px 20px 20px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-block-30 {
		text-align: right;
		text-decoration: underline;
	  }
	  
	  .heading-23 {
		margin-top: 10px;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
	  }
	  
	  .heading-24 {
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
	  }
	  
	  .heading-25 {
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
	  }
	  
	  .heading-26 {
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
	  }
	  
	  .text-block-31 {
		font-size: 16px;
	  }
	  
	  .button-12 {
		display: block;
		margin-top: 40px;
		padding: 15px;
		border-radius: 5px;
		background-color: #c49b65;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .text-block-32 {
		font-size: 18px;
		text-align: right;
	  }
	  
	  .text-block-33 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
	  }
	  
	  .text-block-34 {
		font-size: 16px;
	  }
	  
	  .div-block-18 {
		padding-right: 5px;
		padding-left: 0px;
	  }
	  
	  .column-19 {
		text-align: left;
	  }
	  
	  .image-3 {
		padding-top: 0px;
	  }
	  
	  .column-20 {
		text-align: right;
	  }
	  
	  .column-21 {
		text-align: left;
	  }
	  
	  .column-22 {
		text-align: center;
	  }
	  
	  .column-23 {
		text-align: right;
	  }
	  
	  .column-24 {
		text-align: center;
	  }
	  
	  .column-25 {
		text-align: left;
	  }
	  
	  .column-26 {
		text-align: right;
	  }
	  
	  .column-27 {
		padding-left: 0px;
	  }
	  
	  .column-28 {
		text-align: center;
	  }
	  
	  .column-29 {
		text-align: right;
	  }
	  
	  .column-30 {
		text-align: center;
	  }
	  
	  .image-4 {
		position: relative;
	  }
	  
	  .link-block {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .image-5 {
		position: relative;
	  }
	  
	  .link-block-2 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .image-6 {
		position: relative;
	  }
	  
	  .link-block-3 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .image-7 {
		position: relative;
	  }
	  
	  .div-block-19 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .image-8 {
		position: relative;
	  }
	  
	  .div-block-20 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .image-9 {
		position: relative;
	  }
	  
	  .link-block-4 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .link-block-5 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .link-block-6 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
	  }
	  
	  .container-5 {
		margin-bottom: 40px;
	  }
	  
	  .div-block-21 {
		padding-right: 5px;
		padding-left: 5px;
	  }
	  
	  .div-block-22 {
		padding-left: 5px;
	  }
	  
	  .button-13 {
		display: inline-block;
		width: 300px;
		height: 300px;
		border-radius: 5%;
	  }
	  
	  .button-14 {
		width: 50px;
		height: 50px;
		padding-top: 15px;
		border-radius: 50%;
		background-color: #ebebeb;
		color: #333;
		text-align: center;
	  }
	  
	  .div-block-23 {
		text-align: center;
	  }
	  
	  .text-block-35 {
		text-align: center;
	  }
	  
	  .form-block {
		padding-left: 0px;
		opacity: 1;
	  }
	  
	  .form-2 {
		border-radius: 0px;
		opacity: 1;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .column-31 {
		opacity: 1;
	  }
	  
	  .container-6 {
		margin-top: 40px;
	  }
	  
	  .columns-23 {
		margin-top: 10px;
		margin-bottom: 20px;
	  }
	  
	  .container-7 {
		margin-top: 40px;
	  }
	  
	  .columns-24 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 15px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .section-8 {
		padding-top: 100px;
		padding-bottom: 276px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-27 {
		margin-top: 0px;
		font-family: 'Avenir-Book', sans-serif;
		color: #c49b65;
		font-size: 30px;
		line-height: 33px;
		font-weight: 700;
		text-transform: capitalize;
	  }
	  
	  .heading-28 {
		margin-top: 60px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
	  }
	  
	  .text-block-36 {
		font-size: 16px;
	  }
	  
	  .text-block-37 {
		margin-left: 20px;
		font-size: 18px;
		font-weight: 400;
	  }
	  
	  .text-block-38 {
		margin-top: 20px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
		font-weight: 700;
	  }
	  
	  .text-block-39 {
		margin-left: 40px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
	  }
	  
	  .text-block-40 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .heading-29 {
		margin-top: 20px;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .text-block-41 {
		margin-left: 40px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
	  }
	  
	  .text-span-2 {
		text-decoration: underline;
	  }
	  
	  .text-block-42 {
		margin-top: 60px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .button-15 {
		display: block;
		margin-top: 40px;
		padding-top: 15px;
		padding-bottom: 15px;
		border: 1px solid #c49b65;
		border-radius: 5px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
		color: #c49b65;
		font-size: 18px;
		font-weight: 500;
		text-align: center;
	  }
	  
	  .div-block-24 {
		padding: 20px;
		border-radius: 10px;
		background-color: #f2f2f2;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .columns-25 {
		margin-top: 40px;
	  }
	  
	  .columns-26 {
		margin-top: 60px;
	  }
	  
	  .text-block-43 {
		margin-top: 40px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .div-block-25 {
		padding: 0px 20px;
		background-color: #cecece;
	  }
	  
	  .div-block-26 {
		padding: 20px;
		border-radius: 10px;
		background-color: #f0e1ca;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-block-44 {
		padding-left: 20px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-block-45 {
		margin-left: 20px;
		font-size: 16px;
		white-space: normal;
	  }
	  
	  .text-block-46 {
		font-size: 16px;
		text-align: right;
	  }
	  
	  .columns-27 {
		margin-top: 20px;
	  }
	  
	  .text-block-47 {
		font-size: 16px;
	  }
	  
	  .text-block-48 {
		font-size: 16px;
	  }
	  
	  .text-block-49 {
		font-size: 16px;
	  }
	  
	  .text-block-50 {
		margin-top: 20px;
		margin-left: 20px;
	  }
	  
	  .columns-28 {
		margin-top: 20px;
	  }
	  
	  .container-8 {
		margin-top: 60px;
	  }
	  
	  .container-9 {
		margin-top: 40px;
	  }
	  
	  .container-10 {
		margin-top: 40px;
	  }
	  
	  .container-11 {
		margin-top: 60px;
	  }
	  
	  .div-block-27 {
		padding: 10px 20px;
		border-radius: 5px;
		background-color: #f0e1ca;
	  }
	  
	  .submit-button-4 {
		display: block;
	  }
	  
	  .form-3 {
		display: inline-block;
	  }
	  
	  .text-block-51 {
		font-size: 16px;
	  }
	  
	  .button-16 {
		display: block;
		margin-top: 60px;
		padding-top: 15px;
		padding-bottom: 15px;
		font-size: 16px;
		text-align: center;
	  }
	  
	  .button-17 {
		display: block;
		margin-top: 40px;
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 5px;
		background-color: #c49b65;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .button-18 {
		display: block;
		margin-top: 40px;
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 5px;
		background-color: #c49b65;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .container-12 {
		margin-top: 40px;
	  }
	  
	  .container-13 {
		margin-top: 40px;
	  }
	  
	  .div-block-28 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0px;
		padding-top: 20px;
		padding-bottom: 20px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-style: solid;
		border-width: 1px;
		border-color: #d6d3d3;
		border-radius: 5px;
		background-color: hsla(0, 0%, 100%, 0.47);
	  }
	  
	  .div-block-28:hover {
		background-color: hsla(0, 0%, 92.1%, 0.27);
	  }
	  
	  .div-block-29 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 5px;
		padding-top: 20px;
		padding-bottom: 20px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border: 3px solid #d6d3d3;
		border-radius: 5px;
	  }
	  
	  .columns-29 {
		margin-top: 20px;
	  }
	  
	  .container-14 {
		margin-top: 0px;
	  }
	  
	  .div-block-30 {
		margin-right: 5px;
		padding-top: 40px;
		padding-bottom: 40px;
		border: 3px solid #d6d3d3;
		border-radius: 5px;
	  }
	  
	  .div-block-31 {
		margin-left: 5px;
		padding-top: 40px;
		padding-bottom: 40px;
		border: 3px solid #d6d3d3;
		border-radius: 5px;
	  }
	  
	  .columns-30 {
		margin-top: 20px;
	  }
	  
	  .submit-button-5 {
		display: block;
	  }
	  
	  .button-19 {
		display: block;
		margin-top: 40px;
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 5px;
		background-color: #c49b65;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .section-9 {
		margin-top: 0px;
		padding-top: 60px;
		padding-bottom: 140px;
	  }
	  
	  .form-block-2 {
		display: inline-block;
		margin-top: 60px;
		margin-bottom: 10px;
		text-align: left;
	  }
	  
	  .radio-button-field {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .radio-button-field-2 {
		margin-top: 20px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .radio-button {
		width: 20px;
		height: 20px;
		margin-top: 0px;
		background-color: #c49b65;
		cursor: pointer;
	  }
	  
	  .radio-button.shipping {
		background-color: #fff;
		cursor: pointer;
	  }
	  
	  .radio-button-2 {
		width: 20px;
		height: 20px;
		margin-top: 0px;
		cursor: pointer;
	  }
	  
	  .radio-button-2.shipping {
		background-color: #c49b65;
	  }
	  
	  .radio-button-label {
		margin-left: 20px;
	  }
	  
	  .radio-button-label-2 {
		margin-left: 20px;
	  }
	  
	  .section-10 {
		padding-top: 0px;
		padding-bottom: 10px;
	  }
	  
	  .text-field-19 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-19:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-20 {
		margin-right: 0px;
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-20:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-21 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-21:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .text-field-22 {
		padding-top: 30px;
		padding-bottom: 30px;
	  }
	  
	  .text-field-23 {
		padding-top: 30px;
		padding-bottom: 30px;
		border-radius: 5px;
		background-color: #e1e1e1;
	  }
	  
	  .submit-button-6 {
		display: block;
	  }
	  
	  .container-15 {
		padding-top: 0px;
		padding-bottom: 0px;
	  }
	  
	  .button-20 {
		display: block;
		margin-top: 40px;
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 5px;
		background-color: #c49b65;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	  }
	  
	  .column-32 {
		text-align: center;
	  }
	  
	  .column-33 {
		text-align: center;
	  }
	  
	  .column-34 {
		text-align: center;
	  }
	  
	  .column-35 {
		text-align: center;
	  }
	  
	  .div-block-32 {
		margin-right: 5px;
	  }
	  
	  .div-block-33 {
		margin-left: 5px;
	  }
	  
	  .div-block-34 {
		margin-left: 5px;
		padding: 30px 10px;
		border-radius: 5px;
		background-color: #dfdfdf;
	  }
	  
	  .div-block-35 {
		margin-right: 5px;
	  }
	  
	  .columns-31 {
		margin-top: 20px;
	  }
	  
	  .text-block-52 {
		margin-top: 20px;
		font-family: 'Josefin Sans', sans-serif;
	  }
	  
	  .nav-link {
		display: inline;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .paragraph-6 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  .select-month{
		  height: 3.8rem !important;
	  }
	  .select-field-3 {
		margin-bottom: 0px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .select-field-4 {
		margin-top: 20px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .image-11 {
		border-radius: 10px;
	  }
	  
	  .field-label-3 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .field-label-4 {
		margin-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .paragraph-7 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .paragraph-8 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .column-36 {
		font-family: 'Josefin Sans', sans-serif;
	  }
	  
	  .text-block-53 {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-block-54 {
		font-family: 'Josefin Sans', sans-serif;
		line-height: 10px;
	  }
	  
	  .text-block-55 {
		font-family: 'Josefin Sans', sans-serif;
	  }
	  
	  .text-block-56 {
		font-family: 'Josefin Sans', sans-serif;
	  }
	  
	  .text-block-57 {
		font-family: 'Josefin Sans', sans-serif;
	  }
	  
	  .text-block-58 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .text-block-59 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-left: 10px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 12px;
	  }
	  
	  .text-block-60 {
		font-family: Bitter, serif;
	  }
	  
	  .text-block-61 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
	  }
	  
	  .column-37 {
		font-family: 'Josefin Sans', sans-serif;
	  }
	  
	  .column-38 {
		padding-top: 0px;
	  }
	  
	  .text-block-62 {
		font-family: 'Josefin Sans', sans-serif;
	  }
	  
	  .columns-32 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 40px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-39 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		align-self: auto;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	  }
	  
	  .column-40 {
		display: block;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	  }
	  
	  .image-12 {
		padding-right: 10px;
	  }
	  
	  .image-13 {
		padding-right: 10px;
	  }
	  
	  .div-block-36 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-41 {
		padding-right: 0px;
	  }
	  
	  .image-14 {
		margin-right: 5px;
		padding-right: 0px;
	  }
	  
	  .image-15 {
		margin-right: 5px;
	  }
	  
	  .image-16 {
		margin-right: 5px;
	  }
	  
	  .image-17 {
		margin-right: 5px;
	  }
	  
	  .image-18 {
		margin-right: 5px;
	  }
	  
	  .text-block-63 {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .column-42 {
		text-align: center;
	  }
	  
	  .text-span-3 {
		font-size: 16px;
	  }
	  
	  .form-4 {
		display: block;
	  }
	  
	  .text-field-24 {
		display: block;
		margin-right: 0px;
		border-radius: 5px;
	  }
	  
	  .form-block-3 {
		display: inline;
	  }
	  
	  .column-43 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	  }
	  
	  .div-block-37 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	  }
	  
	  .section-11 {
		padding-top: 40px;
		padding-bottom: 140px;
	  }
	  
	  .div-block-38 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 20px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .div-block-39 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 60px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .text-field-25 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-field-25:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .div-block-41 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 10px;
		margin-bottom: 140px;
		padding-top: 11px;
		padding-bottom: 11px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border: 1px solid #000;
		border-radius: 5px;
		background-color: #fff;
		box-shadow: none;
		font-size: 18px;
	  }
	  
	  .div-block-41:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .heading-30 {
		margin-top: 0px;
		margin-bottom: 0px;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		font-size: 18px;
		line-height: 20px;
		font-weight: 600;
	  }
	  
	  .image-19 {
		margin-right: 20px;
	  }
	  
	  .div-block-42 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .button-21 {
		width: 50px;
		height: 50px;
		padding-top: 15px;
		border-radius: 50%;
		background-color: #c49b65;
		text-align: center;
	  }
	  
	  .container-16 {
		margin-top: 60px;
	  }
	  
	  .select-field-5 {
		border-radius: 5px;
		background-color: #fff;
		font-size: 12px;
	  }
	  
	  .select-field-5:hover {
		background-color: hsla(0, 0%, 92.2%, 0.27);
	  }
	  
	  .form-block-4 {
		display: inline-block;
	  }
	  
	  .div-block-43 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 40px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 12px;
	  }
	  
	  .image-20 {
		margin-right: 10px;
	  }
	  
	  .text-block-64 {
		text-align: left;
	  }
	  
	  ._455 {
		width: 50px;
		height: 50px;
		padding-top: 15px;
		border-radius: 50%;
		background-color: #ebebeb;
		color: #333;
		text-align: center;
	  }
	  
	  ._455:hover {
		background-color: #c49b65;
		color: #fff;
	  }
	  
	  .column-44 {
		text-align: center;
	  }
	  
	  .column-45 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		align-self: auto;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	  }
	  
	  .link-2 {
		display: block;
		margin-top: 60px;
		padding-top: 10px;
		padding-bottom: 10px;
		font-family: 'Josefin Sans', sans-serif;
		color: #333;
		font-size: 16px;
		font-weight: 700;
		text-decoration: none;
	  }
	  
	  .form-block-5 {
		display: block;
		margin-top: 60px;
		margin-bottom: 0px;
	  }
	  
	  .image-21 {
		margin-right: 0px;
		padding-right: 0px;
		opacity: 0.5;
	  }
	  
	  .column-46 {
		padding-left: 0px;
	  }
	  
	  .column-47 {
		padding-right: 0px;
		text-align: right;
	  }
	  
	  .button-22 {
		width: 50px;
		height: 50px;
		padding-top: 15px;
		border-radius: 50%;
		background-color: #c49b65;
	  }
	  
	  .field-label-5 {
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		font-weight: 700;
	  }
	  
	  .heading-31 {
		margin-top: 60px;
		margin-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		font-weight: 700;
	  }
	  
	  .column-48 {
		padding-right: 0px;
	  }
	  
	  .text-block-65 {
		margin-bottom: 0px;
		padding-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		font-weight: 700;
	  }
	  
	  .text-block-66 {
		padding-bottom: 10px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		font-weight: 700;
	  }
	  
	  .columns-33 {
		margin-top: 20px;
	  }
	  
	  .text-field-26 {
		padding: 30px 10px;
		border-radius: 5px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .columns-34 {
		margin-top: 20px;
		padding: 0px;
		background-color: #fff;
		opacity: 0.31;
		text-align: left;
	  }
	  
	  .form-block-6 {
		margin-bottom: 10px;
	  }
	  
	  .heading-32 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		margin-bottom: 0px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 16px;
		text-align: left;
	  }
	  
	  .form-block-7 {
		margin-top: 40px;
		margin-bottom: 0px;
	  }
	  
	  .column-49 {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .form-5 {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .columns-35 {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .bold-text {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .text-span-4 {
		font-family: 'Avenir-Book', sans-serif;
		font-weight: 400;
	  }
	  
	  .shop-3:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		color: #c49b65;
		font-weight: 400;
		text-decoration: none;
	  }
	  
	  .shop-3:active {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .text-block-67 {
		color: #b99055;
	  }
	  
	  .about-us-3:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .navbar-3 {
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		background-color: #fff;
	  }
	  
	  .how-it-works-3:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .link-block-9 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-family: 'Avenir-Book', sans-serif;
		color: #000;
		text-decoration: none;
	  }
	  
	  .link-block-9:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		text-decoration: underline;
	  }
	  
	  .link-block-7 {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	  }
	  
	  .bold-text-5 {
		color: #fff;
	  }
	  
	  .dropdown-link {
		background-color: #000;
		color: #fff;
	  }
	  
	  .bold-text-2 {
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .icon-2 {
		color: #c49b65;
	  }
	  
	  .bold-text-3 {
		color: #b99055;
	  }
	  
	  .icon-4 {
		color: #b99055;
	  }
	  
	  .dropdown-link-8 {
		background-color: #000;
	  }
	  
	  .container-17 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .link-block-10 {
		padding: 20px;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .link-block-10:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		text-decoration: underline;
	  }
	  
	  .dropdown-link-9 {
		background-color: #000;
		color: #fff;
	  }
	  
	  .nav-menu-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: left;
	  }
	  
	  .image-22 {
		position: relative;
		display: block;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	  }
	  
	  .bold-text-4 {
		color: #c49b65;
	  }
	  
	  .div-block-44 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-51 {
		padding-right: 10px;
		padding-left: 10px;
	  }
	  
	  .div-block-45 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-52 {
		padding-right: 10px;
		padding-left: 10px;
	  }
	  
	  .div-block-46 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .div-block-47 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	  }
	  
	  .div-block-48 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		opacity: 0.4;
		text-align: left;
	  }
	  
	  .div-block-49 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  @media screen and (max-width: 991px) {
		.text-field-5 {
		  padding-right: 10px;
		  padding-left: 10px;
		}
	  
		.text-field-6 {
		  padding-right: 10px;
		  padding-left: 10px;
		}
	  
		.text-block-9 {
		  text-align: center;
		}
	  
		.heading-5 {
		  line-height: 33px;
		}
	  
		.container-3 {
		  display: block;
		}
	  
		.nav-link {
		  -webkit-align-self: auto;
		  -ms-flex-item-align: auto;
		  -ms-grid-row-align: auto;
		  align-self: auto;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  
		.image-10 {
		  display: inline-block;
		}
	  
		.heading-31 {
		  line-height: 33px;
		}
	  
		.nav-menu {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-pack: center;
		  -webkit-justify-content: center;
		  -ms-flex-pack: center;
		  justify-content: center;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.shop-3 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.about-us-3 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.icon {
		  color: #c49b65;
		}
	  
		.menu-button {
		  background-color: #fff;
		}
	  
		.menu-button.w--open {
		  background-color: #000;
		}
	  
		.how-it-works-3 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.link-block-9 {
		  display: block;
		  text-align: left;
		}
	  
		.link-block-10 {
		  display: block;
		}
	  
		.nav-menu-2 {
		  display: block;
		  background-color: #000;
		}
	  
		.text-block-68 {
		  color: #fff;
		}
	  
		.column-51 {
		  padding-right: 10px;
		  padding-left: 10px;
		}
	  
		.column-52 {
		  padding-right: 10px;
		  padding-left: 10px;
		}
	  }
	  
	  @media screen and (max-width: 767px) {
		.section {
		  padding-top: 80px;
		}
	  
		.container {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.section-2 {
		  padding-top: 00px;
		}
	  
		.text-field-5 {
		  padding-right: 10px;
		  padding-left: 10px;
		}
	  
		.text-field-6 {
		  padding-right: 10px;
		  padding-left: 10px;
		}
	  
		.text-field-7 {
		  margin-bottom: 10px;
		}
	  
		.text-field-9 {
		  margin-bottom: 10px;
		}
	  
		.columns {
		  display: block;
		}
	  
		.section-4 {
		  padding-top: 80px;
		}
	  
		.div-block-2 {
		  padding-right: 0px;
		}
	  
		.div-block-5 {
		  margin-right: 0px;
		  margin-bottom: 10px;
		}
	  
		.div-block-6 {
		  margin-left: 0px;
		}
	  
		.section-5 {
		  padding-top: 80px;
		}
	  
		.text-block-9 {
		  text-align: center;
		}
	  
		.section-6 {
		  padding-top: 80px;
		}
	  
		.text-field-17 {
		  margin-bottom: 20px;
		}
	  
		.div-block-7 {
		  padding-right: 0px;
		}
	  
		.div-block-8 {
		  padding-left: 0px;
		}
	  
		.div-block-9 {
		  padding-left: 0px;
		}
	  
		.container-3 {
		  padding-top: 0px;
		  padding-bottom: 0px;
		}
	  
		.container-4 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.image-2 {
		  padding-top: 0px;
		}
	  
		.heading-17 {
		  margin-left: 0px;
		}
	  
		.text-block-22 {
		  margin-left: 0px;
		}
	  
		.columns-12 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.div-block-12 {
		  padding: 20px;
		}
	  
		.columns-13 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.columns-14 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.columns-15 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.columns-16 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.div-block-13 {
		  padding: 0px 20px;
		}
	  
		.section-7 {
		  padding-top: 80px;
		}
	  
		.heading-21 {
		  margin-top: 20px;
		  margin-left: 0px;
		}
	  
		.columns-18 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-align: stretch;
		  -webkit-align-items: stretch;
		  -ms-flex-align: stretch;
		  align-items: stretch;
		}
	  
		.columns-19 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-20 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-21 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.div-block-18 {
		  padding-right: 0px;
		}
	  
		.container-5 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-21 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.div-block-22 {
		  padding-left: 0px;
		}
	  
		.container-6 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.columns-23 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.container-7 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.section-8 {
		  padding-top: 80px;
		}
	  
		.text-block-44 {
		  padding-left: 0px;
		}
	  
		.text-block-45 {
		  margin-left: 0px;
		}
	  
		.columns-27 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.text-block-50 {
		  margin-top: 0px;
		  margin-left: 0px;
		}
	  
		.container-10 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.container-11 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.container-13 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-28 {
		  margin-bottom: 10px;
		}
	  
		.container-14 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-32 {
		  margin-right: 0px;
		}
	  
		.div-block-33 {
		  margin-left: 0px;
		}
	  
		.image-10 {
		  display: block;
		  padding-top: 20px;
		}
	  
		.column-36 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-37 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-41 {
		  padding-left: 0px;
		}
	  
		.container-16 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-47 {
		  text-align: left;
		}
	  
		.columns-34 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		  -webkit-box-pack: start;
		  -webkit-justify-content: flex-start;
		  -ms-flex-pack: start;
		  justify-content: flex-start;
		}
	  
		.column-49 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.shop-3 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.about-us-3 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.icon {
		  color: #b99055;
		}
	  
		.how-it-works-3 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.icon-2 {
		  margin-right: 20px;
		}
	  
		.icon-4 {
		  margin-right: 20px;
		}
	  
		.container-17 {
		  padding-right: 0px;
		  padding-left: 20px;
		}
	  
		.columns-36 {
		  display: block;
		  -webkit-box-pack: center;
		  -webkit-justify-content: center;
		  -ms-flex-pack: center;
		  justify-content: center;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.container-18 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-50 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-51 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-52 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-19 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-53 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-54 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-20 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-55 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-56 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-57 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-64 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-21 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-48 {
		  margin-top: 20px;
		  -webkit-box-pack: start;
		  -webkit-justify-content: flex-start;
		  -ms-flex-pack: start;
		  justify-content: flex-start;
		}
	  
		.column-65 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-66 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-67 {
		  margin-top: 50px;
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.columns-37 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.container-22 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-68 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.bold-text-6 {
		  display: block;
		}
	  
		.columns-38 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.column-69 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.columns-39 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-40 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.container-23 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.columns-41 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.column-70 {
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  }
	  
	  @media screen and (max-width: 479px) {
		.columns-4 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.text-block-19 {
		  display: block;
		}
	  
		.columns-5 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-6 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-7 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-8 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-9 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.columns-13 {
		  -webkit-box-align: stretch;
		  -webkit-align-items: stretch;
		  -ms-flex-align: stretch;
		  align-items: stretch;
		}
	  
		.columns-15 {
		  -webkit-box-align: stretch;
		  -webkit-align-items: stretch;
		  -ms-flex-align: stretch;
		  align-items: stretch;
		}
	  
		.menu-button {
		  position: relative;
		  margin-left: 0px;
		  -webkit-box-ordinal-group: 1;
		  -webkit-order: 0;
		  -ms-flex-order: 0;
		  order: 0;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  
		.menu-button.w--open {
		  background-color: #000;
		}
	  
		.container-17 {
		  display: block;
		  padding-right: 0px;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		  -webkit-box-pack: justify;
		  -webkit-justify-content: space-between;
		  -ms-flex-pack: justify;
		  justify-content: space-between;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.image-22 {
		  margin-top: 20px;
		}
	  
		.column-58 {
		  display: none;
		}
	  
		.column-59 {
		  display: none;
		}
	  
		.column-60 {
		  display: none;
		}
	  
		.column-61 {
		  display: none;
		}
	  
		.column-62 {
		  display: none;
		}
	  
		.column-63 {
		  display: none;
		}
	  
		.image-23 {
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  }

}
