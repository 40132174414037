@import '../../variables';

.learn-component {
	position: relative;
	font-family: "Avenir-Medium", serif;
	background-color: #fff;
	padding-top: 20px;

	.top-header {
		padding: 220px 30px 140px 150px;
		box-sizing: border-box;
		background-color: #000;
		background-image: url("../../static/images/the_facts_main_page_-_man.png");
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: right bottom;
		width: 100%;
		.text-info {
			max-width: 570px;
			text-align: right;

			.title {
				font-family: "YesevaOne-Regular", serif;
				font-size: 64px;
				line-height: 61px;
				color: #fff;
				margin-bottom: 30px;
			}
			.description {
				font-family: "Avenir-Light", serif;
				font-size: 22px;
				line-height: 30px;
				color: $light-gold;
			}
		}
		@media (max-width: 750px) {
			padding: 170px 30px 100px 110px;
			background-image: none;
		}
		@media (max-width: 570px) {
			padding: 140px 30px 100px 30px;
			.text-info {
				.title {
					font-size: 46px;
					line-height: 40px;
					margin-bottom: 20px;
				}
				.description {
					font-size: 18px;
					line-height: 24px;
				}
			}
		}
		@media (max-width: 450px) {
			padding: 120px 15px 70px 15px;
		}
	}
	.facts-categories-items {
		margin: 60px auto 0 auto;
		max-width: 1100px;
		padding: 0 20px;

		.category-item {
			margin-top: 60px;
			padding-bottom: 60px;
			border-bottom: 1px solid $main-gold;
			&:last-child {
				border-bottom: none;
			}
			.title {
				font-family: "YesevaOne-Regular", serif;
				font-size: 48px;
				line-height: 43px;
				color: #000;
				margin-bottom: 60px;
				@media (max-width: 750px) {
					font-size: 37px;
				}
			}
			.category-item-articles {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 45px 45px;
				@media (max-width: 900px) {
					grid-template-columns: 1fr;
				}
				.item-article {
					background-color: #f2f2f2;
					height: 330px;
					position: relative;
					.text-info {
						z-index: 2;
						position: relative;
						padding: 25px 44px 16px 25px;
						height: 100%;
						box-sizing: border-box;
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						.article-title {
							font-family: "YesevaOne-Regular", serif;
							font-size: 28px;
							line-height: 34px;
							color: #000;
						}
						.article-description {
							font-family: "Avenir-Medium", serif;
							font-size: 18px;
							line-height: 25px;
							color: #000;
							margin: 20px 0;
						}
						.article-link {
							font-family: "Avenir-Heavy", serif;
							font-size: 14px;
							line-height: 34px;
							color: $main-gold;
							text-decoration: none;
						}
					}
					.circles-bg {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						left: 0;
						overflow: hidden;
						.circle {
							background-color: rgb(185, 144, 85);
							border-radius: 100%;
							position: absolute;
							&.opacity-1 {
								opacity: 0.1;
							}
							&.opacity-2 {
								opacity: 0.2;
							}
						}
					}
				}
			}
		}
	}
}
