@import '../../variables';

.custom-pop-up-wrapper {
	position: fixed;
	z-index: 10000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;

	.custom-pop-up {
		padding: 40px;
		box-sizing: border-box;
		min-width: 30%;
		cursor: default;
		background-color: #fff;
		position: relative;
		@media (max-width: 600px) {
			padding: 15px;
		}
		@media (max-width: 500px) {
			max-width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
