@import '../../variables';

.doctorListSchedule {
	max-width: 710px;
	width: 100%;
	margin: auto auto 50px auto;

	&__empty {
		display: flex;
		justify-content: center;
		margin: 20px 0 0 0;
	}

	&__dates {
		background-color: $main-gold;
		padding: 6px;
		display: flex;
		justify-content: space-between;
	}

	&__dateItem {
		color: white;
		cursor: pointer;

		&:not(:first-of-type) {
			margin: 0 8px 0 0;
		}

		&_active {
			font-weight: 600;
			color: black;
		}
	}

	&__slots {
		margin: 5px 0 0 0;
		display: flex;
		flex-flow: column nowrap;
	}

	&__slot {
		margin: 8px 0 0 0;
		border: 2px solid $main-gold;
		padding: 5px;
		cursor: pointer;

		&_isFree {
			cursor: default;
			background-color: lightgreen;
		}
	}

	&__subscriptionItemWrapper {
		border:2px solid $main-gold;
		border-top: none;
	}

	&__subscriptionItem {
		height: 175px;
		font-size: 14px;
		width: 100%;
		&:not(:first-of-type) {
			border-top: 1px solid $main-gold;
		}

		.button {
			min-width: 60px;
			height: 50px;
		}
	}
}
