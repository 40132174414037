// body {
// 	margin: 0;
// 	min-height: 100%;
// 	background-color: #fff;
// 	font-family: 'Avenir-Book' !important;
// 	font-size: 14px !important;
// 	line-height: 20px !important;
// 	color: #333 !important;
// }

#factPagesHL{
	
	.page-101{
		background-color:#fff !important;
		padding-bottom: 0px !important;
		padding-top: 60px !important;
		font-size: 14px !important;
		line-height: 20px !important;
		color: #333 !important;
		font-family: 'Avenir-Book' !important;
	}
	a:hover{
		text-decoration: none !important;
	}
	.blogBackground{
		background: url('../../static/images/hair-loss-blog.jpg') no-repeat;
	}

	.mob-hide{
		display: block;
	}

	.mob-show{
		display: none;
	}
	
	.container-12 {
		color: #000;
	  }
	  
	  .paragraph {
		color: #fff;
		font-size: 20px;
		line-height: 33px;
		font-weight: 400;
	  }
	  
	  .heading {
		color: #fff;
		font-size: 45px;
		font-weight: 400;
	  }

	  .paragraph-2{
		  font-size: 16px;
	  }
	  
	  .columns {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 60px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	//   .image-21 {
	// 	opacity: 1;
	// 	-webkit-filter: brightness(0%);
	// 	filter: brightness(0%);
	//   }
	  
	  .heading-3 {
		margin-left: 20px;
		color: #fff;
		font-size: 14px;
		line-height: 22px;
		// font-weight: 400;
		margin-top: 20px;
		margin-bottom: 10px;
	  }
	  
	  .column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	//   .image-24 {
	// 	-webkit-filter: brightness(0%);
	// 	filter: brightness(0%);
	//   }
	  
	  .section {
		padding-top: 220px;
		padding-bottom: 100px;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.35)), to(rgba(0, 0, 0, 0.35))), url('../../static/images/hair-loss-page-bg.jpg');
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url('../../static/images/hair-loss-page-bg.jpg');
		background-position: 0px 0px, 0px 0px;
		background-size: auto, cover;
		opacity: 1;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 30px;
		font-weight: 400;
	  }
	  
	  .image-3 {
		margin-bottom: 0px;
	  }
	  
	  .container-11 {
		margin-bottom: 100px;
	  }
	  
	  .bold-text {
		display: block;
	  }
	  
	  .heading-14 {
		margin-top: 0px;
		font-size: 38px;
		font-weight: 400;
	  }
	  
	  .div-block-19 {
		position: static;
		top: auto;
		display: block;
		margin-top: 40px;
		padding: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #fff;
	  }
	  
	  .text-block-7 {
		margin-top: 10px;
	  }
	  
	  .paragraph-14 {
		font-size: 16px;
		margin-bottom: 30px;
	  }
	  
	  .button-9 {
		display: inline-block;
		margin-top: 20px;
		padding: 20px 40px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border: 2px none #c49b65;
		background-color: #c49b65;
		font-size: 18px;
		text-align: center;
		text-transform: none;
	  }
	  
	  .button-9:hover {
		background-color: #c49b65;
		color: #fff;
		font-weight: 400;
		white-space: normal;
	  }
	  
	  .button-14 {
		display: block;
		margin-right: 0px;
		padding: 10px 15px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-style: solid none solid solid;
		border-width: 2px;
		border-color: #c49b65;
		background-color: rgba(56, 152, 236, 0);
		color: #c49b65;
		text-align: center;
	  }
	  
	  .button-14:hover {
		background-color: rgba(196, 155, 101, 0.2);
	  }
	  
	  .button-15 {
		display: block;
		margin: 10px 0px 0px;
		padding: 10px 15px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		border: 2px solid #c49b65;
		background-color: rgba(56, 152, 236, 0);
		color: #c49b65;
		text-align: center;
	  }
	  
	  .button-15:hover {
		background-color: rgba(196, 155, 101, 0.2);
	  }
	  
	  .heading-12 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 22px;
	  }
	  
	  .section-1 {
		margin-top: 100px;
		margin-bottom: 100px;
		padding-top: 0px;
		padding-bottom: 0px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .button-8 {
		display: block;
		margin-right: 0px;
		margin-left: 0px;
		padding: 10px 15px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		border: 2px solid #c49b65;
		background-color: rgba(255, 255, 255, 0);
		color: #c49b65;
		font-size: 14px;
		text-align: center;
		text-transform: none;
		white-space: normal;
	  }
	  
	  .button-8:hover {
		background-color: rgba(196, 155, 101, 0.2);
	  }
	  
	  .heading-5 {
		margin-top: 0px;
		font-weight: 400;
		font-size: 38px;
    margin-bottom: 10px;
	  }
	  
	  .heading-11 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 20px;
	  }
	  
	  .section-3 {
		position: static;
		margin-bottom: 0px;
		padding-top: 0px;
		padding-bottom: 100px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .div-block-20 {
		margin-top: 40px;
		padding: 0px;
		background-color: #fff;
		background-image: none;
	  }
	  
	  .columns-31 {
		margin-top: 0px;
	  }
	  
	  .column-89 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	  }
	  
	  .column-45 {
		position: -webkit-sticky;
		position: sticky;
		padding-right: 10px;
		padding-left: 10px;
	  }
	  
	  .columns-30 {
		position: static;
		top: 0px;
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
	  }
	  
	  .container-6 {
		position: relative;
		top: auto;
	  }
	  
	  .section-2 {
		padding-top: 60px;
		padding-bottom: 60px;
		background-color: #f0e0cb;
		font-family: 'Avenir-Book', sans-serif;
	  }

	  .section-2.canada{
		  padding-bottom: 0px;
	  }
	  
	  .heading-6 {
		margin-top: 0px;
		font-weight: 400;
	  }
	  
	  .heading-8 {
		display: block;
		margin-top: 0px;
		margin-bottom: 0px;
		padding: 10px 0px;
		font-size: 20px;
		line-height: 22px;
		font-weight: bold;
	  }
	  
	  .heading-77 {
		font-size: 20px;
	  }
	  
	  .paragraph-8 {
		margin-top: 10px;
		margin-bottom: 40px;
		font-size: 16px;
	  }
	  
	  .heading-75 {
		margin-top: 0px;
		font-size: 38px;
		font-weight: 400;
		text-align: left;
	  }
	  
	  .column-107 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-103 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-100 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .heading-79 {
		margin-top: 20px;
		margin-left: 40px;
		font-size: 20px;
		line-height: 30px;
		font-weight: 400;
	  }
	  
	  .container-16 {
		margin-top: 100px;
	  }
	  
	  .heading-96 {
		margin-left: 40px;
		font-size: 20px;
		line-height: 30px;
		font-weight: 400;
	  }
	  
	  .heading-80 {
		margin-left: 50px;
		font-size: 20px;
		line-height: 30px;
		font-weight: 400;
	  }
	  
	  .columns-66 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 10px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .heading-82 {
		margin-left: 40px;
		font-size: 20px;
		line-height: 30px;
		font-weight: 400;
	  }
	  
	  .heading-97 {
		font-weight: 400;
	  }
	  
	  .section-4 {
		margin-top: 100px;
		margin-bottom: 100px;
		padding-top: 0px;
		padding-bottom: 0px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .bold-text-3 {
		display: block;
		padding-right: 4px;
		padding-left: 3px;
	  }
	  
	  .paragraph-13 {
		margin-bottom: 40px;
		font-size: 16px;
		text-align: left;
	  }
	  
	  .heading-95 {
		margin-top: 40px;
		margin-bottom: 0px;
		font-size: 20px;
		line-height: 33px;
	  }
	  
	  .section-5 {
		position: static;
		margin-bottom: 0px;
		padding-top: 60px;
		padding-bottom: 60px;
		background-color: #f0e0cb;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .paragraph-12 {
		margin-top: 20px;
		margin-bottom: 0px;
	  }
	  
	  .container-3 {
		margin-bottom: 0px;
	  }
	  
	  .div-block-25 {
		position: relative;
		display: block;
		margin-top: 0px;
		padding: 20px;
		background-color: #fff;
	  }
	  
	  .div-block-59 {
		position: relative;
		display: inline-block;
		padding: 20px 20px 40px;
		background-color: #fff;
	  }
	  
	  .paragraph-11 {
		display: block;
		margin-top: 20px;
		margin-bottom: 0px;
	  }
	  
	  .paragraph-9 {
		display: block;
		margin-top: 20px;
		margin-bottom: 0px;
		font-size: 14px;
		text-align: left;
	  }
	  
	  .heading-55 {
		margin-top: 40px;
		margin-bottom: 0px;
		font-size: 20px;
		line-height: 33px;
	  }
	  
	  .div-block-26 {
		position: relative;
		margin-top: 0px;
		padding: 20px;
		background-color: #fff;
	  }
	  
	  .heading-44 {
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 20px;
		font-size: 18px;
		line-height: 20px;
		font-weight: 400;
	  }
	  
	  .heading-45 {
		margin-top: 10px;
		font-size: 16px;
		line-height: 33px;
		font-weight: 400;
	  }
	  
	  .div-block-38 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 0px;
		background-color: #f0e0cb;
	  }
	  
	  .column-49 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	  }
	  
	  .column-48 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .paragraph-6 {
		margin-bottom: 0px;
		margin-left: 20px;
		font-size: 18px;
	  }
	  
	  .div-block-40 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #f0e0cb;
	  }
	  
	  .section-6 {
		margin-top: 100px;
		margin-bottom: 100px;
		padding-top: 0px;
		padding-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .columns-25 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 40px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .container-4 {
		padding: 0px;
	  }
	  
	  .image-18 {
		display: block;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	  }
	  
	  .container-5 {
		font-size: 20px;
	  }
	  
	  .div-block-46 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;
	  }
	  
	  .button-17 {
		display: inline-block;
		margin-top: 20px;
		border: 2px solid #c49b65;
		background-color: #fff;
		color: #c49b65;
		font-size: 18px;
	  }
	  
	  .button-17:hover {
		background-color: rgba(196, 155, 101, 0.2);
	  }
	  
	  .div-block-31 {
		position: relative;
	  }
	  
	  .div-block-34 {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;
	  }
	  
	  .section-7 {
		margin-top: 100px;
		margin-bottom: 100px;
		padding-top: 0px;
		padding-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-39 {
		margin-top: 0px;
		font-size: 38px;
		font-weight: 400;
	  }
	  
	  .div-block-48 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;
	  }
	  
	  .bold-text-2 {
		color: #000;
		font-size: 16px;
	  }
	  
	  .text-span-2 {
		color: #000;
		text-decoration: underline;
	  }
	  
	  .heading-34 {
		display: block;
		font-size: 20px;
		text-align: left;
	  }
	  
	  .div-block-30 {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;
	  }
	  
	  .heading-37 {
		font-size: 20px;
	  }
	  
	  .heading-93 {
		margin-top: 100px;
		font-size: 28px;
		font-weight: 400;
	  }
	  
	  .heading-94 {
		margin-top: 100px;
		font-size: 28px;
		line-height: 33px;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .column-16 {
		text-align: center;
	  }
	  
	  .heading-92 {
		margin-top: 100px;
		font-size: 28px;
		font-weight: 400;
	  }
	  
	  .columns-65 {
		margin-bottom: 0px;
		padding-bottom: 0px;
	  }
	  
	  .slide-nav {
		position: relative;
		margin-top: -30px;
		padding-top: 0px;
		font-weight: 400;
	  }
	  
	  .text-block {
		margin-top: 20px;
	  }
	  
	  .columns-2 {
		display: block;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .text-block-4 {
		margin-top: 20px;
		padding-bottom: 0px;
		font-size: 14px;
	  }
	  
	  .icon {
		color: #c49b65;
	  }
	  
	  .heading-26 {
		margin-top: 100px;
		font-size: 28px;
		line-height: 33px;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .slide {
		position: relative;
		margin-top: 0px;
		border-radius: 10px;
		background-color: #f0e0cb;
	  }
	  
	  .slide-3 {
		background-color: #f0e0cb;
	  }
	  
	  .column-14 {
		text-align: center;
	  }
	  
	  .text-block-2 {
		margin-top: 20px;
	  }
	  
	  .mask {
		border-radius: 0px;
	  }
	  
	  .slider {
		border-radius: 0px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .section-8 {
		position: static;
		margin-top: 100px;
		margin-bottom: 100px;
		padding-top: 0px;
		padding-bottom: 0px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-27 {
		margin-top: 0px;
		margin-bottom: 40px;
		font-size: 38px;
		font-weight: 400;
		text-align: center;
	  }
	  
	  .div-block-3 {
		padding: 20px;
		cursor: pointer;
	  }
	  
	  .div-block-3:hover {
		box-shadow: 0 1px 1px 1px #ded8d8;
	  }
	  
	  .heading-49 {
		font-size: 30px;
		font-weight: 400;
	  }
	  
	  .div-block-5 {
		padding: 20px;
		cursor: pointer;
	  }
	  
	  .div-block-5:hover {
		box-shadow: 0 1px 1px 1px #ded8d8;
	  }
	  
	  .image-6 {
		margin-top: 0px;
	  }
	  
	  .columns-67 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	  }
	  
	  .heading-48 {
		padding-left: 0px;
		font-size: 30px;
		font-weight: 400;
	  }
	  
	  .section-10 {
		margin-top: 100px;
		padding-top: 0px;
		padding-bottom: 140px;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .div-block-23 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .heading-52 {
		margin-top: 0px;
		margin-bottom: 40px;
		font-weight: 400;
		text-align: left;
	  }
	  
	  .div-block-60 {
		display: block;
		padding-top: 60px;
		padding-bottom: 220px;
		// background-image: url('/static/images/ed-101.png');
		background-position: 0% 0% !important;
		background-size: cover !important;
	  }
	  
	  .div-block-17 {
		padding: 0px 0px 0px 10px;
		background-color: #fff;
	  }
	  
	  .text-block-6 {
		padding-bottom: 0px;
		padding-left: 20px;
		text-align: right;
	  }
	  
	  .button-13 {
		border: 2px solid #c49b65;
		background-color: #fff;
		color: #c49b65;
		font-size: 16px;
	  }
	  
	  .button-13:hover {
		background-color: rgba(196, 155, 101, 0.2);
	  }
	  
	  .paragraph-4 {
		margin-top: 20px;
	  }
	  
	  .button-18 {
		margin-top: 20px;
		padding: 20px 40px;
		border: 2px none #c49b65;
		background-color: #c49b65;
		font-size: 18px;
	  }
	  
	  .button-18:hover {
		background-color: #c49b65;
		color: #fff;
	  }
	  
	  .image-20 {
		padding-top: 0px;
		padding-bottom: 0px;
	  }
	  
	  .heading-46 {
		margin-top: 0px;
		font-size: 38px;
		font-weight: 400;
	  }
	  
	  .columns-5 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .column-113 {
		text-align: right;
	  }
	  
	  .column-114 {
		display: block;
	  }
	  
	  .container-15 {
		padding-top: 0px;
		padding-bottom: 0px;
	  }
	  
	  .heading-98 {
		margin-left: 40px;
		font-size: 20px;
		font-weight: 400;
	  }
	  
	  .heading-83 {
		margin-left: 40px;
		font-size: 20px;
		line-height: 30px;
		font-weight: 400;
	  }
	  
	  .section-11 {
		margin-top: 100px;
		font-family: 'Avenir-Book', sans-serif;
		background:#fff;
	  }
	  
	  .section-12 {
		padding-top: 60px;
		padding-bottom: 60px;
		background-color: #f0e0cb;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .heading-99 {
		margin-top: 0px;
		font-weight: 400;
	  }
	  
	  .heading-100 {
		margin-top: 10px;
		font-size: 20px;
	  }
	  
	  .heading-101 {
		margin-top: 10px;
		font-size: 20px;
	  }
	  
	  .heading-102 {
		margin-top: 10px;
		font-size: 20px;
	  }
	  
	  .heading-103 {
		margin-top: 10px;
		font-size: 20px;
	  }
	  
	  .text-block-8 {
		margin-bottom: 10px;
		background: transparent;
		color: #333;
		font-size: 14px;
		padding: 0;
	  }
	  
	  .columns-68 {
		margin-top: 40px;
	  }

	  
	  .div-block-55 {
		position: fixed;
		z-index: 4;
		display: block;
		width: 100vw;
		height: 100vh;
		background-color: rgba(54, 50, 50, 0.42);
	  }
	  
	  .div-block-56 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
		width: 600px;
		height: 170px;
		margin: 140px auto auto;
		padding: 20px;
		border-radius: 10px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .div-block-45 {
		position: fixed;
		/* left: auto; */
		top: 141px;
		/* right: 0; */
		/* bottom: auto; */
		padding: 20px 0px 0px 0px;
		cursor: pointer;
		width: 560px;
		overflow: hidden;
		text-align: end;
		background: #fff;
	  }
	  
	  .div-block-44 {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: 0%;
		overflow: auto;
		width: 600px;
		height: 350px;
		margin: 140px auto auto;
		padding: 20px;
		border-radius: 10px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .paragraph-15 {
		display: block;
	  }
	  
	  .heading-104 {
		font-size: 18px;
	  }
	  
	  .heading-68 {
		display: block;
		font-size: 18px;
	  }
	  
	  .div-block-43 {
		position: fixed;
		z-index: 4;
		display: block;
		overflow: visible;
		width: 100vw;
		height: 100vh;
		background-color: rgba(54, 50, 50, 0.42);
	  }
	  
	  .heading-60 {
		padding: 0px;
		font-size: 20px;
		font-weight: 600;
	  }
	  
	  .div-block-61 {
		position: fixed;
		z-index: 4;
		display: block;
		width: 100vw;
		height: 100vh;
		background-color: rgba(54, 50, 50, 0.42);
	  }
	  
	  .text-block-9 {
		padding-left: 10px;
		background-color: #000;
		color: #fff;
	  }
	  
	  .text-block-10 {
		padding-left: 10px;
		border-radius: 0px;
		background-color: #000;
		color: #fff;
	  }
	  
	  .div-block-62 {
		position: fixed;
		z-index: 4;
		display: block;
		overflow: visible;
		width: 100vw;
		height: 100vh;
		background-color: rgba(54, 50, 50, 0.42);
	  }
	  
	  .section-13 {
		position: static;
		margin-top: 100px;
		margin-bottom: 100px;
		padding-top: 0px;
		padding-bottom: 0px;
		background-color: #fff;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .columns-69 {
		position: static;
		top: 0px;
	  }
	  
	  .form-block-2 {
		margin-bottom: 0px;
	  }
	  
	  .paragraph-7 {
		margin-bottom: 0px;
		font-size: 16px;
	  }
	  
	  .text-block-11 {
		margin-top: 10px;
	  }
	  
	  .heading-105 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 20px;
	  }
	  
	  .column-121 {
		position: -webkit-sticky;
		position: sticky;
		padding-right: 0px;
		padding-left: 0px;
	  }
	  
	  .heading-2 {
		margin-top: 0px;
		font-size: 45px;
		font-weight: 400;
	  }
	  
	  .heading-4 {
		margin-top: 20px;
		margin-bottom: 0px;
		font-size: 16px;
	  }
	  
	  .select-field-2 {
		margin-top: 10px;
		margin-bottom: 0px;
	  }
	  
	  .image-2 {
		margin-bottom: 20px;
	  }
	  
	  .div-block-63 {
		position: static;
		top: auto;
		display: block;
		margin-top: 40px;
		padding: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #fff;
	  }
	  
	  .div-block-64 {
		position: fixed;
		z-index: 4;
		display: block;
		width: 100vw;
		height: 100vh;
		background-color: rgba(54, 50, 50, 0.42);
	  }
	  
	  .div-block-65 {
		position: fixed;
		z-index: 4;
		display: block;
		overflow: visible;
		width: 100vw;
		height: 100vh;
		background-color: rgba(54, 50, 50, 0.42);
	  }
	  
	  .section-14 {
		padding-top: 60px;
		padding-bottom: 0px;
		background-color: #f0e0cb;
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  @media screen and (max-width: 991px) {

		.div-block-45{
			width: 560px;
		}

		.image-25{
			padding-top: 40px;
		}
		.section {
		  padding-top: 140px;
		  background-position: 100% 50%;
		}
	  
		.bold-text {
		  display: inline;
		}
	  
		.button-14 {
		  margin-right: 0px;
		  padding: 10px;
		}
	  
		.button-15 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding-right: 15px;
		  padding-left: 15px;
		  -webkit-box-pack: center;
		  -webkit-justify-content: center;
		  -ms-flex-pack: center;
		  justify-content: center;
		}
	  
		.button-8 {
		  padding: 10px 15px;
		}
	  
		.column-45 {
		  padding-right: 10px;
		  padding-left: 10px;
		}
	  
		.heading-8 {
		  font-size: 20px;
		  line-height: 22px;
		  font-weight: bold;
		}
	  
		.div-block-25 {
		  padding: 20px;
		}
	  
		.div-block-59 {
		  display: block;
		  padding: 20px;
		}
	  
		.div-block-26 {
		  padding: 20px;
		}
	  
		.div-block-38 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-40 {
		  padding: 30px 20px;
		}
	  
		.heading-93 {
		  margin-top: 60px;
		  line-height: 33px;
		}
	  
		.heading-94 {
		  margin-top: 60px;
		}
	  
		.heading-92 {
		  margin-top: 60px;
		  line-height: 33px;
		}
	  
		.slide-nav {
		  margin-top: -31px;
		}
	  
		.heading-26 {
		  margin-top: 60px;
		}
	  
		.slide {
		  position: relative;
		}
	  
		.slider {
		  padding-bottom: 0px;
		}
	  
		.heading-48 {
		  margin-top: 0px;
		}
	  
		.column-113 {
		  padding-right: 0px;
		}
	  
		.column-114 {
		  padding-right: 10px;
		  padding-left: 0px;
		}
	  
		.container-15 {
		  padding: 0px 10px;
		}
	  
		.heading-101 {
		  line-height: 33px;
		}
	  
		.column-121 {
		  padding-right: 10px;
		  padding-left: 10px;
		}
	  }
	  
	  @media screen and (max-width: 767px) {

		 .mob-hide{
			display: none;
		}

		.mob-show{
			display: block;
			margin-top: 30px;
		}

		.div-block-45{
			width: 360px;
		}
		
		.container-12 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-109 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.section {
			padding-top: 80px;
			padding-bottom: 60px;
			// background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.35)), to(rgba(0, 0, 0, 0.35))), url('../../static/images/hair-loss-page-bg.jpg');
			// background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url('../../static/images/hair-loss-page-bg.jpg');
			// background-position: 0px 0px, 50% 50%;
			// background-size: auto, cover;
		}
	  
		.container-11 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading-14 {
		  margin-top: 20px;
		}
	  
		.button-14 {
		  margin-right: 0px;
		  padding-top: 10px;
		  padding-bottom: 10px;
		  border-right-style: solid;
		  border-bottom-style: none;
		}
	  
		.button-15 {
		  display: block;
		  text-align: center;
		}
	  
		.button-8 {
		  margin-right: 0px;
		  margin-left: 0px;
		  padding-top: 10px;
		  padding-bottom: 10px;
		}
	  
		.heading-5 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.section-3 {
		  padding-top: 0px;
		}
	  
		.column-89 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-45 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-6 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.columns-60 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.heading-96 {
		  line-height: 30px;
		}
	  
		.image-22 {
		  display: block;
		}
	  
		.columns-66 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.image-16 {
		  display: inline-block;
		}
	  
		.heading-95 {
		  display: block;
		}
	  
		.paragraph-12 {
		  margin-top: 10px;
		  margin-bottom: 0px;
		}
	  
		.container-3 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-25 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding: 20px;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.div-block-59 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding: 20px;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.paragraph-11 {
		  margin-top: 10px;
		}
	  
		.paragraph-9 {
		  margin-top: 10px;
		  margin-bottom: 0px;
		}
	  
		.div-block-26 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding: 20px;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: column;
		  -ms-flex-direction: column;
		  flex-direction: column;
		}
	  
		.column-51 {
		  padding-right: 0px;
		  padding-bottom: 20px;
		  padding-left: 0px;
		}
	  
		.div-block-38 {
		  margin-bottom: 20px;
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-49 {
		  display: block;
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-48 {
		  display: block;
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.div-block-40 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding: 20px;
		}
	  
		.columns-25 {
		  display: block;
		}
	  
		.container-4 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.container-5 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-34 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.heading-34 {
		  line-height: 30px;
		}
	  
		.heading-37 {
		  line-height: 30px;
		}
	  
		.heading-94 {
		  margin-top: 60px;
		  font-weight: 400;
		}
	  
		.text-block {
		  margin-top: 20px;
		}
	  
		.heading-26 {
		  margin-top: 60px;
		  font-weight: 400;
		}
	  
		.image-14 {
		  margin-top: 0px;
		}
	  
		.column-14 {
		  margin-bottom: 0px;
		}
	  
		.div-block-3 {
		  text-align: center;
		}
	  
		.div-block-5 {
		  text-align: center;
		}
	  
		.columns-67 {
		  display: block;
		}
	  
		.heading-48 {
		  margin-top: 20px;
		}
	  
		.container-8 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading-52 {
		  margin-bottom: 40px;
		}
	  
		.div-block-17 {
		  padding-left: 0px;
		}
	  
		.column-71 {
		  margin-bottom: 0px;
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.heading-46 {
		  margin-top: 0px;
		}
	  
		.column-113 {
		  padding-right: 0px;
		}
	  
		.column-114 {
		  padding-left: 0px;
		}
	  
		.container-15 {
		  padding: 0px 20px;
		}
	  
		.column-115 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-17 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.column-116 {
		  padding-right: 0px;
		  padding-bottom: 40px;
		  padding-left: 0px;
		}
	  
		.column-117 {
		  padding-right: 0px;
		  padding-bottom: 40px;
		  padding-left: 0px;
		}
	  
		.column-118 {
		  padding-right: 0px;
		  padding-bottom: 40px;
		  padding-left: 0px;
		}
	  
		.column-119 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-120 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		// .div-block-55 {
		//   display: none;
		// }
	  
		.div-block-56 {
		  width: 400px;
		}
	  
		.div-block-44 {
		  width: 400px;
		}
	  
		// .div-block-61 {
		//   display: none;
		// }
	  
		.section-13 {
		  padding-top: 80px;
		}
	  
		.column-121 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.heading-2 {
		  margin-top: 20px;
		}
	  
		// .div-block-64 {
		//   display: none;
		// }
	  }
	  
	  @media screen and (max-width: 479px) {

		// .section {
		// 	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.35)), to(rgba(0, 0, 0, 0.35))), url('../../static/images/hair-loss-page-bg.jpg');
		// 	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url('../../static/images/hair-loss-page-bg.jpg');
		// 	background-position: 0px 0px, 70% 50%;
		// 	background-size: auto, cover;
		// 	background-attachment: scroll, scroll;
		//   }

		.mob-hide{
			display: none;
		}

		.mob-show{
			display: block;
			margin-top: 30px;
		}

		.div-block-45{
			width: 260px;
		}
		.columns {
		  display: block;
		}
	  
		.button-14 {
		  margin-right: 0px;
		}
	  
		.button-15 {
		  margin-top: 10px;
		  margin-left: 0px;
		}
	  
		.heading-12 {
		  line-height: 33px;
		}
	  
		.button-8 {
		  margin-top: 0px;
		  margin-right: 0px;
		  margin-left: 0px;
		}
	  
		.heading-11 {
		  font-weight: 400;
		}
	  
		.column-89 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-45 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.container-6 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading-8 {
		  line-height: 33px;
		}
	  
		.column-107 {
		  margin-top: 20px;
		}
	  
		.column-103 {
		  margin-top: 20px;
		  -webkit-box-pack: start;
		  -webkit-justify-content: flex-start;
		  -ms-flex-pack: start;
		  justify-content: flex-start;
		}
	  
		.column-100 {
		  margin-top: 30px;
		}
	  
		.columns-60 {
		  display: block;
		}
	  
		.heading-80 {
		  margin-left: 55px;
		}
	  
		.columns-66 {
		  display: block;
		  margin-top: 0px;
		}
	  
		.div-block-38 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-40 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.heading-34 {
		  line-height: 30px;
		}
	  
		.heading-37 {
		  line-height: 30px;
		}
	  
		.heading-93 {
		  margin-top: 40px;
		  font-size: 25px;
		}
	  
		.heading-94 {
		  margin-top: 40px;
		  font-size: 28px;
		  font-weight: 400;
		}
	  
		.heading-92 {
		  margin-top: 40px;
		  font-size: 28px;
		  line-height: 33px;
		  font-weight: 400;
		}
	  
		.slide-nav {
		  position: absolute;
		  margin-top: 0px;
		  font-weight: 400;
		}
	  
		.text-block {
		  margin-bottom: 0px;
		  padding-bottom: 0px;
		}
	  
		.heading-26 {
		  margin-top: 40px;
		  font-weight: 400;
		}
	  
		.container-10 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-3 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.div-block-5 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.image-20 {
		  padding-top: 40px;
		}
	  
		.columns-5 {
		  display: block;
		}
	  
		.column-113 {
		  padding-left: 0px;
		  text-align: center;
		}
	  
		.column-114 {
		  padding-right: 0px;
		}
	  
		.container-15 {
		  margin-top: 0px;
		  padding-top: 0px;
		  text-align: center;
		}
	  
		.heading-83 {
		  line-height: 30px;
		}
	  
		.heading-100 {
		  margin-top: 10px;
		}
	  
		.heading-101 {
		  margin-top: 10px;
		}
	  
		.heading-102 {
		  margin-top: 10px;
		}
	  
		.column-115 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-120 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.div-block-56 {
		  width: 300px;
		}
	  
		.div-block-44 {
		  width: 300px;
		}
	  
		.column-121 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  }
  }