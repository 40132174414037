@import "../../variables";


.header-component-top-padding {
	height: $info-strip-height;
	background: #000000;
}

.login p{
	margin-bottom: 0 !important;
}

.dropdown {
	display: inline-block;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
  border: transparent;
}

.dropdown-content {
  position: absolute;
  background: #dddddd;
  display: none;
  min-width: 100%;
  }
  
.dropdown:hover .dropdown-content {
	display: block;
}

.header-component{
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 15;
}

nav .res-menu{
	display: inherit;
}

#header{
	.container {
		display: block;
		margin-left: 0px;
		padding-top: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }

	  a:focus, a:active, a:hover{
		  outline: none;
		  border: none;
		  text-decoration: none;
		  box-shadow: none;
	  }

	  a{
		cursor: pointer;
		outline: none;
		border: none;
		text-decoration: none;
		box-shadow: none;
	  }	 

	  a.nav-link a strong{
		  color:#c49b65;
	  }

	  :link:focus, :visited:focus { 
		-moz-outline: none; 
	  }

	  a span strong{
		  font-weight: normal !important;
	  }

	  .nav-link:hover{
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		text-decoration: none;
	  }

	  .link-block-10:hover{
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		text-decoration: none;
	  }

	  .w-nav-button{
		display: block;
	  }
	  
	  .button-14 {
		display: block;
		margin-right: 0px;
		padding: 10px 15px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-style: solid none solid solid;
		border-width: 2px;
		border-color: #c49b65;
		background-color: rgba(56, 152, 236, 0);
		color: #c49b65;
		text-align: center;
	  }

	  
	  .button-14:hover {
		background-color: rgba(196, 155, 101, 0.2);
	  }
	  
	  .button-15 {
		display: block;
		margin: 10px 0px 0px;
		padding: 10px 15px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		border: 2px solid #c49b65;
		background-color: rgba(56, 152, 236, 0);
		color: #c49b65;
		text-align: center;
	  }
	  
	  .button-15:hover {
		background-color: rgba(196, 155, 101, 0.2);
	  }
	  
	  .image-2 {
		margin-bottom: 0px;
	  }
	  
	  .button-9 {
		display: inline-block;
		margin-top: 20px;
		padding: 20px 40px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border: 2px none #c49b65;
		background-color: #c49b65;
		font-size: 18px;
		text-align: center;
		text-transform: none;
	  }
	  
	  .button-9:hover {
		background-color: #c49b65;
		color: #fff;
		font-weight: 400;
		white-space: normal;
	  }
	  
	  .heading-2 {
		margin-top: 0px;
		font-size: 45px;
		font-weight: 400;
	  }
	  
	  .heading-3 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 20px;
	  }
	  
	  .form-block-2 {
		margin-bottom: 0px;
	  }
	  
	  .columns-30 {
		position: static;
		top: 0px;
	  }
	  
	  .heading-5 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 22px;
	  }
	  
	  .paragraph {
		font-size: 16px;
	  }
	  
	  .div-block-19 {
		position: static;
		top: auto;
		display: block;
		margin-top: 60px;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #fff;
	  }
	  
	  .text-block-7 {
		margin-top: 10px;
	  }
	  
	  .container-6 {
		position: relative;
		top: auto;
	  }

	  .w-inline-block p{
		  margin-bottom: 0px;
	  }
	  
	  .button-8 {
		display: block;
		margin-right: 0px;
		margin-left: 0px;
		padding: 10px 15px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		border: 2px solid #c49b65;
		background-color: rgba(255, 255, 255, 0);
		color: #c49b65;
		font-size: 14px;
		text-align: center;
		text-transform: none;
		white-space: normal;
	  }
	  
	  .button-8:hover {
		background-color: rgba(196, 155, 101, 0.2);
	  }
	  
	  .heading-4 {
		margin-top: 20px;
		margin-bottom: 0px;
		font-size: 16px;
	  }
	  
	  .select-field-2 {
		margin-top: 10px;
		margin-bottom: 0px;
	  }
	  
	  .bold-text {
		display: block;
	  }
	  
	  .column-45 {
		position: -webkit-sticky;
		position: sticky;
		padding-right: 10px;
		padding-left: 10px;
	  }
	  
	  .section {
		position: static;
		margin-bottom: 0px;
		padding-top: 140px;
		padding-bottom: 60px;
		background-color: #f0e0cb;
	  }
	  
	  .paragraph-7 {
		margin-bottom: 0px;
		font-size: 16px;
	  }
	  
	  .image-3 {
		display: block;
		margin-top: 20px;
		margin-bottom: 0px;
		padding-top: 0px;
	  }
	  
	  .navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0px;
		padding: 0px 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #000;
	  }
	  
	  .nav-link {
		display: block;
		padding: 20px 20px 20px 20px !important;
	  }

	  .dropdown-toggle:after{
		content: "\e603";
		border: none;
		margin-left: .255em;
		vertical-align: middle;
		font-family: "webflow-icons"!important;
		float: right;
	  }

	  .dropdown-toggle{
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-feature-settings: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-family: 'Avenir-Medium';
		border: transparent;
	  }

	  .dropdown:hover > .dropdown-menu{
		display: block !important;
	  }

	  .dropdown-menu{
		  padding:0px;
		  top: auto;
		  border: none!important;
		  outline: none;
		  box-shadow: none;
		  border: transparent;
	  } 

	  .button-16 {
		background-color: rgba(56, 152, 236, 0);
		color: #c49b65;
	  }
	  
	  .button-17 {
		background-color: rgba(56, 152, 236, 0);
		color: #000;
	  }
	  
	  .button-18 {
		background-color: rgba(56, 152, 236, 0);
		color: #c49b65;
	  }
	  
	  .button-19 {
		background-color: rgba(56, 152, 236, 0);
		color: #c49b65;
	  }
	  
	  .container-7 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		text-align: left;
	  }
	  
	  .link-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-decoration: none;
	  }
	  
	  .bold-text-2 {
		color: #c49b65;
		text-decoration: none;
		font-family: 'Avenir-Book';
		font-weight: 600;
	  }
	  
	  .text-block-8 {
		text-decoration: none;
	  }
	  
	  .text-block-9 {
		text-decoration: none;
	  }
	  
	  .link-block-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .nav-link {
		color: #c49b65;
		line-height: 20px;
		border: transparent;
	  }
	  
	  .nav-link-2 {
		color: #c49b65;
	  }
	  
	  .nav-link-3 {
		color: #c49b65;
	  }
	  
	  .navbar-2 {
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		background-color: #000;
		font-family: 'Anenir-Book', sans-serif;
	  }
	  
	  .nav-menu-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: left;
		border: transparent;
	  }
	  
	  .link-block-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-decoration: none;
	  }
	  
	  .bold-text-3 {
		color: #b99055;
		font-family: 'Avenir-Book';
		text-decoration: none;
		font-weight: 600;
	  }
	  
	  .link-block-4 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .link-block-4:hover {
		text-decoration: underline;
	  }
	  
	  .link-block-5 {
		padding: 20px;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .link-block-5:hover {
		text-decoration: underline;
	  }
	  
	  .brand {
		display: block;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .icon-2 {
		color: #c49b65;
	  }
	  
	  .bold-text-4 {
		color: #c49b65;
		font-family: 'Avenir-Book';
		text-decoration: none;
		font-weight: 600;
	  }
	  
	  .icon-3 {
		color: #c49b65;
	  }
	  
	  .text-block-10 {
		color: #b99055;
	  }
	  
	  .icon-4 {
		color: #b99055;
	  }
	  
	  .dropdown-link {
		background-color: #000;
		color: #fff;
		text-decoration: none;
		border: transparent;
	  }
	  
	  .dropdown-link-2 {
		background-color: #000;
		color: #fff;
		border: transparent;
	  }
	  
	  .dropdown-link-3 {
		background-color: #000;
		color: #fff;
		border: transparent;
	  }
	  
	  .dropdown-link-4 {
		background-color: #000;
		color: #fff;
		border: transparent;
	  }
	  
	  .dropdown-link-5 {
		background-color: #000;
		color: #fff;
		border: transparent;
	  }
	  
	  .dropdown-link-6 {
		background-color: #000;
		color: #fff;
		border: transparent;
	  }
	  
	  .dropdown-link-7 {
		background-color: #000;
		color: #fff;
		text-decoration: none;
		border: transparent;
	  }
	  
	  .shop:hover {
		color: #c49b65;
		font-weight: 400;
		text-decoration: underline;
	  }
	  
	  .shop:active {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .how-it-works:hover {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .about-us:hover {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .dropdown-link-8 {
		background-color: #000;
		text-decoration: none;
	  }
	  
	  .bold-text-5 {
		color: #fff;
	  }
	  
	  .dropdown-link-9 {
		background-color: #000;
		color: #fff;
		text-decoration: none;
	  }
	  
	  .dropdown-link-10 {
		background-color: #000;
		color: #fff;
	  }
	  
	  .dropdown-link-11 {
		background-color: #000;
		color: #fff;
	  }
	  
	  .link-block-6 {
		padding: 20px;
		font-family: 'Anenir-Book', sans-serif;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .link-block-6:hover {
		text-decoration: underline;
	  }
	  
	  .image-4 {
		position: relative;
		display: block;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	  }
	  
	  .container-9 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		// -webkit-box-direction: reverse;
		// -webkit-flex-direction: row-reverse;
		// -ms-flex-direction: row-reverse;
		// flex-direction: row-reverse;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }

	  .w-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 940px;
		width: 940px;
	}

	.navbar-collapse {
		flex-grow: 0;
    	flex-basis: auto;
	}
	  
	  .about-us-2 {
		font-family: 'Anenir-Book', sans-serif;
	  }
	  
	  .about-us-2:hover {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .navbar-3 {
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		background-color: #000;
	  }
	  
	  .shop-2 {
		font-family: 'Anenir-Book', sans-serif;
	  }
	  
	  .shop-2:hover {
		color: #c49b65;
		font-weight: 400;
		text-decoration: underline;
	  }
	  
	  .shop-2:active {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .how-it-works-2 {
		font-family: 'Anenir-Book', sans-serif;
	  }
	  
	  .how-it-works-2:hover {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .link-block-7 {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	  }
	  
	  .link-block-8 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-family: 'Anenir-Book', sans-serif;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .link-block-8:hover {
		text-decoration: underline;
	  }
	  
	  .shop-3:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		color: #c49b65;
		font-weight: 400;
		text-decoration: none;
	  }
	  
	  .shop-3:active {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .link-block-9 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .link-block-9:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		text-decoration: underline;
	  }
	  
	  .how-it-works-3:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .link-block-10 {
		padding: 20px;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  
	  .about-us-3:hover {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		color: #c49b65;
		text-decoration: none;
	  }

	  #basic-navbar-nav{
		  display: none;
	  }

	  #basic-navbar-nav-2{
		display: block !important;
	}

	  @media only screen and (min-width: 730px) and (max-width: 991px)  {
		  // mob dropmenu

		#basic-navbar-nav-2{
			display: none !important;
		}

		#basic-navbar-nav{
			display: block !important;
			position: absolute;
			height: auto;
			top: 60px;
			left: 0;
			width: 100%;
		}  

		.dropdown{
			width: 100%;
		}
		#basic-navbar-nav-2{
			display: none !important;
		}
		.container-9{
			padding-left: 0px;
		}

		.w-nav-menu{
			float: left;
			width: 100%;
		}

		.shop-3{
			width: 100%;
			// margin:0 62px;
		}

		.how-it-works-3{
			width: 100%;
			// margin:0 62px;
		}

		.about-us-3{
			width: 100%;
			// margin:0 62px;
		}

		.res-menu{
			max-width: 728px;
			margin: auto;
			display: block;
		}

		.link-block-9.w-inline-block{
			padding: 20px 0px;
		}

		.link-block-10.w-inline-block{
			padding: 20px 20px 20px 0px;
		}

		.dropdown-content{
			position: relative;
		}

		.w-dropdown-link{
			padding: 10px 20px;
		}
	  }
	  
	  @media screen and (max-width: 991px) {

		.navbar-brand{
			padding-left: 0px;
		}

		.nav-link{
			padding-left: 0px !important;
		}

		.button-14 {
		  margin-right: 0px;
		  padding: 10px;
		}
	  
		.button-15 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding-right: 15px;
		  padding-left: 15px;
		  -webkit-box-pack: center;
		  -webkit-justify-content: center;
		  -ms-flex-pack: center;
		  justify-content: center;
		}
	  
		.button-8 {
		  padding: 10px 15px;
		}
	  
		.bold-text {
		  display: inline;
		}
	  
		.column-45 {
		  padding-right: 10px;
		  padding-left: 10px;
		}
	  
		.nav-menu {
		  display: block;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 0 auto;
		  -ms-flex: 0 0 auto;
		  flex: 0 0 auto;
		}
	  
		.nav-menu-2 {
		  display: block;
		  background-color: #000;
		}
	  
		.link-block-4 {
		  display: block;
		  text-align: left;
		}
	  
		.link-block-5 {
		  display: block;
		}
	  
		.icon {
		  color: #c49b65;
		}
	  
		.shop {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.how-it-works {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.about-us {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.text-block-11 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.menu-button {
		  background-color: #000;
		}
	  
		.menu-button.w--open {
		  background-color: #000;
		}
	  
		.link-block-6 {
		  display: block;
		}
	  
		.about-us-2 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.shop-2 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.how-it-works-2 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.link-block-8 {
		  display: block;
		  text-align: left;
		}
	  
		.shop-3 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.link-block-9 {
		  display: block;
		  text-align: left;
		}
	  
		.how-it-works-3 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.link-block-10 {
		  display: block;
		}
	  
		.about-us-3 {
		  padding-right: 0px;
		  padding-left: 0px;
		}

		.w-container{
			width: 728px;
		}

		.w-nav-menu{
			display: block;
			position: initial;
			height: 85vh;
    		overflow: auto;
		}

		.menu-transition{
			-webkit-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			-moz-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			-ms-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			-o-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			max-height: 0;
			display: block;
			overflow: auto;
			opacity: 0;
			visibility: hidden;
			width: 100%;
		}

		.menu-transition.show{
			-webkit-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			-moz-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			-ms-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			-o-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			max-height: 100vh;
			overflow: auto;
			opacity: 1;
			visibility: visible;
			width: 100%;
		}

		
	  }
	  
	  @media screen and (max-width: 767px) {

		#basic-navbar-nav-2{
			display: none !important;
		}

		#basic-navbar-nav{
			display: block !important;
			position: absolute;
			height: auto;
			top: 60px;
			width: 100%;
		}

		.nav-link {
			padding-left: 20px !important;
		}

		.button-14 {
		  margin-right: 0px;
		  padding-top: 10px;
		  padding-bottom: 10px;
		  border-right-style: solid;
		  border-bottom-style: none;
		}
	  
		.button-15 {
		  display: block;
		  text-align: center;
		}
	  
		.heading-2 {
		  margin-top: 20px;
		}
	  
		.container-6 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.button-8 {
		  margin-right: 0px;
		  margin-left: 0px;
		  padding-top: 10px;
		  padding-bottom: 10px;
		}
	  
		.column-89 {
		  padding-left: 0px;
		}
	  
		.column-45 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.section {
		  padding-top: 80px;
		}
	  
		.brand {
		  padding-left: 0px;
		}
	  
		.icon {
		  color: #b99055;
		}
	  
		.icon-2 {
		  margin-right: 20px;
		}
	  
		.icon-3 {
		  margin-right: 20px;
		}
	  
		.icon-4 {
		  margin-right: 20px;
		}
	  
		.shop {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.how-it-works {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.about-us {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.container-8 {
		  padding-right: 0px;
		  padding-left: 20px;
		}
	  
		.container-9 {
		  padding-right: 0px;
		  padding-left: 20px;
		}
	  
		.about-us-2 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.shop-2 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.how-it-works-2 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.shop-3 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.how-it-works-3 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.about-us-3 {
		  padding-right: 20px;
		  padding-left: 20px;
		}

		// mob dropmenu
		.dropdown{
			width: 100%;
		}

		.container-9{
			padding-left: 0px;
		}

		.w-nav-menu{
			float: left;
			width: 100%;
		}


		.shop-3{
			width: 100%;
		}

		.how-it-works-3{
			width: 100%;
		}

		.about-us-3{
			width: 100%;
		}

		.w-inline-block{
			padding-left: 18px;
		}

		.dropdown-content{
			position: relative;
		}

		.w-nav-menu{
			display: block;
			position: initial;
			height: 85vh;
    		overflow: auto;
		}

		.menu-transition{
			-webkit-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			-moz-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			-ms-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			-o-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			max-height: 0;
			display: block;
			overflow: auto;
			opacity: 0;
			visibility: hidden;
			width: 100%;
		}

		.menu-transition.show{
			-webkit-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			-moz-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			-ms-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			-o-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			max-height: 100vh;
			overflow: auto;
			opacity: 1;
			visibility: visible;
			width: 100%;
		}
	  }
	  
	  @media screen and (max-width: 479px) {

		#basic-navbar-nav-2{
			display: none !important;
		}

		#basic-navbar-nav{
			display: block !important;
		}

		.button-14 {
		  margin-right: 0px;
		}

		.navbar{
			padding:0;
		}

		.nav-link {
			padding-left: 20px !important;
		}
	  
		.button-15 {
		  margin-top: 10px;
		  margin-left: 0px;
		}
	  
		.heading-5 {
		  line-height: 33px;
		}
	  
		.div-block-19 {
		  padding-top: 20px;
		}
	  
		.container-6 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.button-8 {
		  margin-top: 0px;
		  margin-right: 0px;
		  margin-left: 0px;
		}
	  
		.column-89 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.column-45 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.image-3 {
		  margin-top: 20px;
		}
	  
		.brand {
		  margin-right: 70px;
		}
	  
		.menu-button {
		  position: relative;
		  margin-left: 0px;
		  -webkit-box-ordinal-group: 1;
		  -webkit-order: 0;
		  -ms-flex-order: 0;
		  order: 0;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  
		.menu-button.w--open {
		  background-color: #000;
		}
	  
		.container-8 {
		  display: block;
		  padding-right: 0px;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		  -webkit-box-pack: justify;
		  -webkit-justify-content: space-between;
		  -ms-flex-pack: justify;
		  justify-content: space-between;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.image-4 {
		  margin-top: 20px;
		}
	  
		.container-9 {
		  display: block;
		  padding-right: 0px;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		  -webkit-box-pack: justify;
		  -webkit-justify-content: space-between;
		  -ms-flex-pack: justify;
		  justify-content: space-between;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}

		// mob dropmenu

		.dropdown{
			width: 100%;
		}

		.container-9{
			padding-left: 0px;
		}

		.w-nav-menu{
			float: left;
			width: 100%;
		}

		.shop-3{
			width: 100%;
		}

		.how-it-works-3{
			width: 100%;
		}

		.about-us-3{
			width: 100%;
		}

		.w-inline-block{
			padding-left: 18px;
		}

		.dropdown-content{
			position: relative;
		}

		.w-nav-menu{
			display: block;
			position: initial;
			height: 85vh;
    		overflow: auto;
		}

		#basic-navbar-nav.hide{
			-webkit-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			-moz-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			-ms-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			-o-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
			max-height: 0;
			display: none;
			overflow: auto;
			opacity: 0;
			visibility: hidden;
			width: 100%;
		}

		#basic-navbar-nav.show{
			display: block;
			-webkit-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			-moz-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			-ms-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			-o-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			transition: max-height 0.3s, opacity 0.2s, visibility 0s;
			max-height: 100vh;
			overflow: auto;
			opacity: 1;
			visibility: visible;
			width: 100%;
		}
			

	  }
}
