@import '../../variables';

.jump-to-nav-wrapper {
	width: 100%;
	background: #fff;
	font-family: "Avenir-Book", serif;
	font-weight: 300;
	font-size: 25px;
	line-height: 50px;
	color: #585858;
	text-shadow: 0 0 0.01px black;

	.jump-to-nav {
		display: flex;
		justify-content: center;
		padding-top: 30px;
		padding-bottom: 1px;
		border-bottom: 2px solid #dcdcdc;
		.link-to-item {
			margin-left: 30px;
			border-bottom: 5px solid #fff;
			cursor: pointer;
			text-align: center;
			&:hover {
				font-family: "Avenir-Medium", serif;
				color: #000;
				border-bottom: 5px solid $main-gold;
			}
			//we need exact width for every link, cause we change text-bold on hover
			&.ed {
				width: 230px;
			}
			&.hl {
				width: 110px;
			}
			&.ac {
				width: 60px;
			}
			&.cs {
				width: 130px;
			}
			&.mh {
				width: 161px;
			}
			&.dc {
				width: 230px;
			}
		}
	}
	&.products-categories {
		.jump-to-nav {
			@media (max-width: 800px) {
				flex-direction: column;
				align-items: center;
			}
			.link-to-item {
				@media (max-width: 800px) {
					margin-left: 0;
				}
			}
		}
	}
	&.facts-categories {
		.jump-to-nav {
			@media (max-width: 1250px) {
				flex-direction: column;
				align-items: center;
			}
			.link-to-item {
				@media (max-width: 1250px) {
					margin-left: 0;
				}
			}
		}
	}
}
