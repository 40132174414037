@import '../../../../variables';

.subscription-item {
	display: flex;
	flex-wrap: wrap;
	border-top: 1px;
	padding: 20px 50px;
	border-top: 2px solid $main-gold;
	&:last-child {
		padding-bottom: 0;
	}
	@media (max-width: 650px) {
		padding: 10px 0;
	}
	.info-tooltip {
		max-width: 320px;
		font-family: "Avenir-Book", serif;
	}
	.top-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		width: calc(100% - 388px);
		margin-bottom: 20px;
		&.big-width {
			width: calc(100% - 128px);
			@media (max-width: 800px) {
				width: 100%;
			}
		}
		@media (max-width: 800px) {
			width: 100%;
		}
		@media (max-width: 750px) {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.status {
		display: flex;
		font-family: "Avenir-Heavy", serif;
		font-size: 16px;
		line-height: 22px;

		&-title {
			color: #000;
			margin-right: 5px;
		}
		&-text {
			color: $main-gold;
		}
	}
	.pause-reason {
		width: 100%;
		font-family: "Avenir-Medium", serif;
		font-size: 13px;
		line-height: 16px;
		color: #bc6b5f;
	}
	.refill-actions-btns {
		z-index: 2;
		margin-left: auto;
		display: flex;
		flex-wrap: wrap;
		@media (max-width: 800px) {
			// order: 1;
			width: 100%;
			margin-bottom: 20px;
		}

		.pause-btn {
			.pause-icon {
				width: 2px;
				height: 14px;
				border-style: solid;
				border-width: 0px 3px 0px 3px;
				border-color: $main-gold;
				margin-right: 4px;
			}
		}
		.play-btn {
			.play-icon {
				box-sizing: border-box;
				width: 14px;
				height: 14px;
				border-style: solid;
				border-width: 7px 0px 7px 14px;
				border-color: transparent transparent transparent $main-gold;
				margin-right: 4px;
			}
		}
		.earlier-refill-block,
		.skip-refill-block,
		.pause-block,
		.resume-block {
			display: flex;
			.earlier-refill-btn,
			.skip-refill-btn,
			.pause-btn,
			.play-btn {
				padding: 7px 0;
				display: flex;
				justify-content: center;
				border: 2px solid $main-gold;
				height: 30px;
				width: 105px;
				box-sizing: border-box;
				cursor: pointer;
				margin-right: 4px;
				display: flex;
				align-items: center;
				span {
					font-family: "Avenir-Book", serif;
					font-size: 16px;
					line-height: 22px;
					color: $main-gold;
				}
				img {
					height: 15px;
					margin-right: 4px;
				}
			}
			.tooltip-icon {
				font-family: "Avenir-Heavy", serif;
				cursor: pointer;
				width: 15px;
				height: 15px;
				border-radius: 100%;
				background-color: #d5d5d5;
				flex-shrink: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
			}
		}
		.pause-block {
			@media (max-width: 480px) {
				width: 100%;
				margin-top: 20px;
			}
		}
		.earlier-refill-block,
		.skip-refill-block {
			margin-right: 8px;
		}
	}
	.refill-count {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 20px;

		&-title {
			color: $main-gold;
			font-family: "Avenir-Book", serif;
			font-size: 11px;
			line-height: 15px;
		}
		&-text {
			font-family: "Avenir-Medium", serif;
			font-size: 16px;
			line-height: 22px;
			color: #000;
		}
	}

	.center-row {
		display: flex;
		width: 100%;
		font-family: "Avenir-Book", serif;
		font-size: 16px;
		line-height: 22px;
		color: #000;
		@media (max-width: 700px) {
			flex-direction: column;
		}
		.id {
			display: flex;
			margin-right: 30px;
			@media (max-width: 700px) {
				margin-bottom: 20px;
			}
			&-title {
				margin-right: 5px;
			}
		}
		.product-name {
			display: flex;

			&-title {
				margin-right: 5px;
			}
		}
	}
	.bottom-row {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;
		margin-top: 20px;
		.dates-wrapper {
			display: flex;
			.next-refill-date {
				margin-right: 20px;
			}
			@media (max-width: 800px) {
				flex-direction: column;
				.next-refill-date {
					margin-right: 0;
				}
				.expires-date {
					margin-top: 15px;
				}
			}
		}
		.next-refill-date,
		.expires-date {
			font-family: "Avenir-Book", serif;
			&-title {
				font-size: 11px;
				line-height: 15px;
				color: $main-gold;
			}
			&-text {
				font-size: 16px;
				line-height: 22px;
				color: #000;
			}
		}

		.bottom-right {
			display: flex;
			align-items: center;

			.subscription-price {
				font-family: "Avenir-Book", serif;
				text-align: right;
				min-width: 90px;

				&-title {
					font-size: 11px;
					line-height: 15px;
					color: $main-gold;
				}
				&-text {
					font-size: 24px;
					line-height: 33px;
					color: #000;
				}
			}
		}
	}
	.collapse-details-btn {
		font-family: "Avenir-Book", serif;
		min-width: 43px;
		font-size: 16px;
		line-height: 22px;
		color: #a0a0a0;
		padding: 20px 0;
		text-align: center;
		display: flex;
		align-items: center;
		cursor: pointer;
		@media (max-width: 800px) {
			order: 2;
		}
		img {
			height: 12px;
			margin-left: 10px;
			&.open {
				transform: rotate(180deg);
			}
		}
	}
	.order-item-details {
		width: 100%;
		padding-left: 20px;
		@media (max-width: 800px) {
			order: 3;
		}
	}
}
