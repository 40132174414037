@import '../../../../variables';

.continue-transfer-wrapper {
	background-color: #fff;
	padding: 32px;
	max-width: 750px;
	margin: 50px auto 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 400px) {
		padding: 16px;
	}
	.title {
		font-family: "YesevaOne-Regular", serif;
		font-size: 25px;
		line-height: 29px;
		color: #000;
	}
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			margin-top: 15px;
			margin-bottom: 10px;
			font-size: 16px;
			line-height: 22px;
			color: #000000;
			display: inline-block;
		}
	}
	.button-links {
		display: flex;
		width: 100%;
		justify-content: center;
		margin-top: 20px;

		button {
			&:first-child {
				margin-right: 30px;
			}
			font-family: "Avenir-Book", serif;
			background-color: $main-gold;
			outline: none;
			width: 130px;
			font-size: 18px;
			cursor: pointer;
			line-height: 25px;
			color: #ffffff;
			border: none;
			padding: 12px 0;
		}
	}
}
