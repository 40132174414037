@import '../../variables';

.why-winston-component {
	position: relative;
	padding: 100px 0 0 0;
	min-height: 100px;
	font-family: "Avenir-Medium", serif;
	background-color: #dbdbdb;
	.main {
		margin: 0 auto;
		max-width: 1000px;
		.main-title {
			text-align: center;
			padding: 40px 5px 20px 5px;
			font-size: 64px;
			font-family: "YesevaOne-Regular", serif;
			border-top: 2px solid $main-gold;
			@media (max-width: 1010px) {
				width: calc(80% + 30px);
				margin: 0 auto;
			}
			@media (max-width: 750px) {
				font-size: 46px;
			}
		}
		.content-box {
			padding: 10px 0 40px 0;
			@media (max-width: 480px) {
				width: 100%;
			}
			.box {
				margin-top: 30px;
				padding: 20px;
				background-color: #e3e3e3;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				@media (max-width: 1010px) {
					flex-direction: column;
					width: 80%;
					margin: 30px auto 0 auto;
				}
				@media (max-width: 465px) {
					width: 90%;
					padding: 20px 10px;
				}
				.left-part {
					display: flex;
					font-family: "YesevaOne-Regular", serif;
					max-height: 60px;
					width: 400px;
					@media (max-width: 1010px) {
						padding-bottom: 20px;
					}
					@media (max-width: 465px) {
						width: 300px;
						max-height: none;
					}
					.number {
						font-size: 78px;
						padding-right: 10px;
						border-right: 1px solid #000000;
						display: flex;
						align-items: center;
					}
					.title {
						padding-left: 10px;
						display: flex;
						align-items: center;
						font-size: 28px;
						line-height: 1.4em;
						@media (max-width: 460px) {
							font-size: 28px;
						}
					}
				}
				.right-part {
					width: 400px;
					text-align: start;
					font-family: "Avenir-Medium", serif;
					font-size: 15px;
					letter-spacing: 0.02em;
					line-height: 1.4em;
					p.padding-top {
						padding-top: 15px;
					}
					@media (max-width: 465px) {
						width: 300px;
					}
				}
				&.about-us {
					display: block;
					text-align: center;
					font-family: "Avenir-Medium", serif;
					font-size: 15px;
					letter-spacing: 0.02em;
					line-height: 1.4em;
					a {
						text-decoration: underline;
						color: #000000;
						font-family: "Avenir-Heavy", serif;
						cursor: pointer;
						&:hover {
							color: $main-gold;
						}
					}
				}
			}
			.drug-table {
				display: flex;
				flex-direction: row;
				text-align: center;
				padding: 20px 5px 5px 5px;
				background-color: #e3e3e3;
				color: #565655;
				font-size: 0.9em;
				@media (max-width: 1010px) {
					flex-direction: column;
					width: calc(80% + 30px);
					margin: 0 auto;
				}
				@media (max-width: 465px) {
					width: calc(90% + 10px);
				}
				.drug-box {
					flex: auto;
					display: flex;
					flex-direction: column;
					@media (max-width: 1010px) {
						flex-direction: row;
					}
					&.first {
						.example {
							@media (max-width: 1010px) {
								border-top: 1px solid #c7c7c9;
							}
						}
					}
					&.last {
						@media (min-width: 1011px) {
							.drug-title {
								border-right: 0;
							}
							.example {
								border-right: 0;
							}
						}
					}
					.drug-title {
						background-color: #c7c7c9;
						border-right: 1px solid #e3e3e3;
						font-family: "Avenir-Heavy", serif;
						padding: 8px 3px;
						@media (max-width: 1010px) {
							min-width: 70px;
							width: 33%;
							border-bottom: 1px solid #e3e3e3;
						}
					}
					.example {
						padding: 8px 3px;
						border-right: 1px solid #c7c7c9;
						border-bottom: 1px solid #c7c7c9;
						@media (max-width: 1010px) {
							width: 33%;
						}
					}
				}
			}
		}
	}
	.get-started-section {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		margin: 60px 0 80px 0;
		.title {
			font-family: "YesevaOne-Regular", serif;
			text-align: center;
			font-size: 48px;
			line-height: 56px;
			color: #000;
			margin-bottom: 50px;
			@media (max-width: 750px) {
				font-size: 37px;
				line-height: 42px;
			}
		}
		.buttons {
			display: flex;
			@media (max-width: 550px) {
				flex-direction: column;
			}
			button {
				cursor: pointer;
				background-color: $main-gold;
				outline: none;
				border: none;
				padding: 16px 20px;
				color: #fff;
				font-family: "Avenir-Book", serif;
				font-size: 18px;
				line-height: 20px;
				min-width: 200px;
			}
			a {
				&:first-child {
					margin-right: 20px;
					@media (max-width: 550px) {
						margin-right: 0;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
	.end-wrapper {
		background-color: #c49b65;
		background-image: url("../../static/images/end-why-winston.jpg");
		background-size: contain;
		background-repeat: no-repeat;
		background-position-x: right;
		background-position-y: top;
		.end {
			display: flex;
			flex-direction: row;
			padding-left: calc(5% + 16px);
			height: 400px;
			max-width: 1200px;
			margin: 0 auto;
			@media (max-width: 1010px) {
				flex-direction: column-reverse;
				background-image: none;
				height: auto;
				padding: 0;
			}
			.end-content {
				display: flex;
				align-items: center;
				font-size: 25px;
				color: #ffffff;
				font-family: "YesevaOne-Regular", serif;
				width: 52%;
				line-height: 1.4em;
				@media (max-width: 1010px) {
					width: 90%;
					margin: 0 auto;
					padding: 35px 10px 35px 10px;
					text-align: center;
				}
				@media (max-width: 460px) {
					font-size: 20px;
				}
			}
			.image {
				flex: auto;
				.narrow {
					display: none;
					@media (max-width: 1010px) {
						display: block;
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}
