@import '../../variables';

.dialog-component {
	position: relative;
	font-family: "Avenir-Medium", serif;
	.bg {
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 2;
		top: 0;
		left: 0;
		opacity: 0.5;
		background: #000000;
	}
	.content-wrapper {
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 3;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		.content {
			min-width: 100px;
			min-height: 100px;
			height: initial;
			display: block;
			background: #ffffff;
			padding: 15px;
			margin: 20px;
			.content-inner {
				background: #eeeeee;
				.text {
					text-align: center;
					padding: 40px 40px 20px 40px;
					font-family: "Avenir-Medium", serif;
					font-size: 15px;
					letter-spacing: 0.02em;
					line-height: 1.4em;
					a {
						text-decoration: underline;
						color: #000000;
						font-family: "Avenir-Heavy", serif;
						cursor: pointer;
						&:hover {
							color: $main-gold;
						}
					}
					b {
						font-family: "Avenir-Heavy", serif;
					}
				}
				.text-input {
					width: 70%;
					margin: 0 auto;
					padding-bottom: 20px;
					.input-title {
						text-align: center;
						padding-bottom: 10px;
						font-family: "Avenir-Medium", serif;
						font-size: 15px;
						letter-spacing: 0.02em;
						line-height: 1.4em;
					}
					textarea {
						resize: none;
						background-color: #eeeeee;
						padding: 5px;
						width: calc(100% - 10px - 2px);
						border: 1px solid #a7a7a7;
						color: #565656;
						min-height: 15px;
						&:focus {
							outline: 1px solid $main-gold;
						}
					}
					.validation-error {
						color: #e26161;
					}
				}
				.buttons {
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					padding: 20px 40px 40px 40px;
					.button {
						flex: none;
						background-color: $main-gold;
						color: #ffffff;
						padding: 5px 5px 8px 5px;
						text-align: center;
						width: 70px;
						font-size: 15px;
						letter-spacing: 0.1em;
						cursor: pointer;
						font-family: "Avenir-Medium", serif;
						line-height: 16px;
						border: 2px solid $main-gold;
						margin: 0 10px;
						min-width: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						&.light {
							color: $main-gold;
							background: #eeeeee;
						}
						&.dark {
							color: #ffffff;
							background: $main-gold;
						}
						&.disabled {
							opacity: 0.3;
							cursor: initial;
						}
					}
				}
			}
		}
	}
}
