@import '../../variables';

.contact-us-wrapper {
	color: #000;
	padding: 80px 60px 100px 140px;
	margin-top: 70px;
	background-color: #dbdbdb;
	@media (max-width: 900px) {
		background-image: none;
	}
	@media (max-width: 700px) {
		padding: 110px 30px 30px 30px;
	}
	.title {
		font-family: "YesevaOne-Regular", serif;
		font-size: 64px;
		line-height: 74px;
		margin-bottom: 40px;
		@media (max-width: 750px) {
			font-size: 46px;
			line-height: 52px;
		}
		@media (max-width: 450px) {
			margin-bottom: 20px;
		}
	}
	.content-contact-us {
		display: flex;
		@media (max-width: 975px) {
			flex-direction: column-reverse;
		}
		.left-part {
			.cta {
				font-family: "Avenir-Book", serif;
				font-size: 24px;
				line-height: 33px;
				margin-bottom: 20px;
				@media (max-width: 450px) {
					font-size: 17px;
					line-height: 22px;
				}
			}
			form {
				max-width: 620px;
				display: flex;
				flex-direction: column;

				input,
				textarea {
					outline: none;
					border: none;
					background-color: #fff;
					padding: 10px 12px;
					font-family: "Avenir-Book", serif;
					font-size: 16px;
					line-height: 22px;
					color: #000000;
					margin-bottom: 15px;
					&::placeholder {
						color: #000000;
					}
				}

				textarea {
					resize: none;
					height: 120px;
				}
				button {
					outline: none;
					border: none;
					padding: 15px;
					background-color: $main-gold;
					font-family: "Avenir-Book", serif;
					cursor: pointer;
					width: 230px;
					margin-top: 40px;
					font-size: 18px;
					line-height: 25px;
					color: #fff;
				}
				.validation-error {
					font-family: "Avenir-Book", serif;
					color: #e26161;
					font-size: 14px;
					line-height: 20px;
				}
				.success-message {
					font-family: "Avenir-Book", serif;
					color: #4bb543;
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
		.right-part {
			flex-shrink: 0;
			padding-left: 100px;
			font-family: "Avenir-Book", serif;
			font-size: 24px;
			line-height: 33px;
			color: #000000;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@media (max-width: 975px) {
				padding-left: 0;
				margin-bottom: 40px;
			}
			.top-contacts {
				display: flex;
				flex-direction: column;
				.contact-title {
					font-family: "Avenir-Heavy", serif;
				}
				.email-link {
					text-decoration: none;
					color: #000000;
					margin-bottom: 30px;
					display: inline-block;
				}
				.more-questions-answer {
					margin-bottom: 30px;
					.faq-link {
						font-family: "Avenir-Heavy", serif;
						text-decoration: none;
						color: #000;
					}
				}
			}
			.canada-located {
				display: flex;
				align-items: center;
				margin-top: 40px;
				img {
					margin-left: 5px;
				}
			}
		}
	}
}
