@import '../../variables';

.doctorSchedule {
	display: flex;
	flex-flow: row nowrap;
	height: 650px;
	background-color: white;
	padding: 20px;
	position: relative;

	> button {
		cursor: pointer;
		position: absolute;
		left: 340px;
		height: 29px;
		background-color: transparent;
		border: 1px solid lightgray;
		border-radius: 5px;
	}

	.rbc-calendar {
		width: 100%;
	}

	&__checkbox {
		display: inline-flex;
		flex-flow: row nowrap;
		min-width: 200px;

		span {
			margin: 0 0 0 8px;
		}
	}

	&__actions {
		display: flex;
		flex-flow: column nowrap;
		margin: 0 15px;
	}

	&__submit {
		margin: 20px 0 0 0;
		max-width: 250px;
		min-width: 200px;
		width: max-content;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
		letter-spacing: 1px;
		font-size: 13px;
		line-height: 13px;
		cursor: pointer;
		height: 35px;
		border: 2px solid $main-gold;
		color: $main-gold;
		outline: none;
	}
}
