@import '../../../../variables';

.billing-info-wrapper {
	background-color: #fff;
	padding: 32px;
	max-width: 750px;
	margin: 50px auto 0 auto;
	@media (max-width: 400px) {
		padding: 16px;
	}
	.title {
		font-family: "YesevaOne-Regular", serif;
		font-size: 25px;
		line-height: 29px;
		color: #000;
	}

	.validation-error {
		font-family: "Avenir-Book", serif;
		font-size: 14px;
		line-height: 20px;
		color: #e26161;
	}
}
