@import '../../variables';

.webcam-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 400000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.4);
	.close-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 5;
		text-decoration: none;

		button {
			cursor: pointer;
			outline: none;
			width: 30px;
			height: 30px;
			border: 1px solid $main-gold;
			cursor: pointer;
			border-radius: 100%;
			font-size: 20px;
			font-family: "Avenir-Book", serif;
			line-height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #979797;
		}
	}
	.no-webcam {
		padding: 30px 60px;
		background: #fff;
		font-size: 20px;
	}
	.work-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		max-width: 500px;
		border: 15px solid #fff;
		background-color: #fff;
		.info {
			display: flex;
			flex-direction: column;
			min-height: 50px;
			width: 100%;
			box-sizing: border-box;
			font-family: "Avenir-Medium", serif;
			font-size: 14px;
			justify-content: center;
			padding: 0 60px;
			text-align: center;
			color: #000;

			span.padding-bottom {
				margin-bottom: 20px;
			}
			span.bigger {
				font-family: "Avenir-Heavy", serif;
				font-size: 22px;
			}
		}
	}
	video,
	img {
		object-fit: cover;
		width: 100%;
	}
	.btns-wrapper {
		position: absolute;
		display: flex;
		bottom: 20px;
		.photo-btn {
			background: #fff;
			margin: 0 10px;
			padding: 10px;
			box-sizing: border-box;
			width: 50px;
			height: 50px;
			border-radius: 100%;
			border: 1px solid $main-gold;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
