@import '../../../../variables';

.reject-order-wrapper {
	width: 400px;
	max-width: 100%;
	.title {
		font-family: "YesevaOne-Regular", serif;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
	}

	.action-buttons {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-top: 30px;
		button {
			font-family: "Avenir-Book", serif;
			background-color: $main-gold;
			outline: none;
			width: 130px;
			font-size: 18px;
			cursor: pointer;
			line-height: 25px;
			color: #ffffff;
			border: none;
			padding: 12px 0;
		}
	}
}
