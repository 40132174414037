@import "../../../variables.scss";

#re-login{
    .section {
        margin-top: 0;
        padding-top: 100px;
        padding-bottom: 80px;
        font-family: "Josefin Sans",sans-serif;
    }
    .heading-1{
        font-family: "Avenir-Book",sans-serif;
        font-size: 30px;
        font-weight: 600;
    }
    .heading-2{
        margin-bottom: 0;
        font-family: "Avenir-Book",sans-serif;
        font-size: 18px;
        margin-top: 10px;
    }
    .mail-text{
        font-size: 14px;
        font-weight: bold;
        white-space: normal;
        width: 100%;
        margin-bottom: 0;
        margin-top: 0;
        border: none !important;
        background-color: white;
    }
    .account-form-block{
        margin: 30px 0px;
    }
    .text-input{
        padding: 30px 10px;
        border-radius: 5px;
        font-family: "Avenir-Book",sans-serif;
    }
    .account-info-text{
        margin-bottom: 30px;
    }
    .button-1{
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 5px;
        background-color: #c49b65;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        width: 100%;
    }
    .other-link-col{
        margin:30px 0px;
        text-align: center;
    }
    .link-1{
        color:#c49b65;
    }
    .link1:hover{
        color:#c49b65;
    }   

}