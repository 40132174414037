@import '../../variables';

.loader {
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	background: transparent url("../../static/images/loading.svg") no-repeat center center;
	z-index: 1000000;
	top: 0;
	left: 0;
	&.shadowed {
		background-color: rgba(0, 0, 0, 0.7);
	}
	&.fixed {
		position: fixed;
	}
}
