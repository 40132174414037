@import '../../../../variables';

.SubscriptionItem {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 75px;
	width: 90%;
	margin: 0 auto 8px auto;
	.appointment-time {
		min-width: 60px;
		max-width: 100px;
		word-break: break-word;
		text-align: center;
		background: #ffffff;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 4px 0 0;
		color: #000000;
		font-family: "Avenir-Heavy", serif;
		font-size: 14px;
	}
	.content {
		flex: auto;
		background: #ffffff;
		margin: 0 4px;
		padding: 0 10px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.row1 {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 33%;
			.id {
				font-family: "Avenir-Heavy", serif;
				padding: 0 10px 0 0;
			}
			.date {
				padding: 0 10px 0 0;
			}
			.app-date {
				margin-left: auto;
			}
			.items {
				text-decoration: underline;
			}
			.justify-self-right {
				display: flex;
				margin-left: auto;
			}
			.document-view-buttons {
				margin-left: auto;
				display: flex;
			}
			.view-id {
				margin-left: 10px;
				white-space: nowrap;
				letter-spacing: 0.5px;
				color: $main-gold;
				font-family: "Avenir-Heavy", serif;
				cursor: pointer;
				font-size: 11px;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				span {
					margin: 0 4px 0 0.3em;
				}
				img {
					height: 0.8em;
				}
			}
		}
		.row2 {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 33%;
			.patient-name {
				padding: 0 10px 0 10px;
				font-family: "Avenir-Heavy", serif;
			}
			.patient-birthday {
				padding: 0 10px 0 10px;
				font-family: "Avenir-Heavy", serif;
			}
			.patient-phone {
				padding: 0 0 0 10px;
				font-family: "Avenir-Heavy", serif;
			}
			.buttons {
				display: flex;
				flex-direction: row;
				margin-left: auto;
				height: 30px;
			}
		}
		.row3 {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 33%;
		}
	}
	.button {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 4px;
		padding: 0 10px;
		font-family: "Avenir-Medium", serif;
		font-size: 12px;
		letter-spacing: 1px;
		line-height: 12px;
		cursor: pointer;
		height: 100%;
		min-width: 90px;
		text-transform: uppercase;
		&.message-btn {
			color: #ffffff;
			background: $main-gold;
			width: 110px;
			font-size: 10px;
			line-height: 10px;
		}
		&.reject {
			color: #000000;
			background: #b4b4b4;
		}
		&.approve {
			color: #ffffff;
			background: $main-gold;
		}
		&.set-appointment {
			color: #ffffff;
			background: #b4b4b4;
			min-width: 205px;
		}
	}
}
