@import '../../../variables';

.modal-component {

  &__layout {
    position: fixed;
    background: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    opacity: 0.4;
  }

  &__external {
    position: fixed;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    padding: 35px;
    width: 830px;

    @media(max-width: 850px) {
      width: 530px;
    }
    @media(max-width: 550px) {
      width: 90%;
    }
  }

  &__internal {
    background: #eee;
    padding: 60px 150px;

    @media(max-width: 850px) {
      padding: 60px 50px;
    }
    @media(max-width: 550px) {
      padding: 20px 20px;
    }
  }

  h2 {
    text-align: center;
    color: $main-black;
    font-family: Avenir-Heavy, serif;
    font-size: 20px;
    margin-bottom: 25px;
  }

  &__text {
    color: $main-black;
    font-family: Avenir-Book, serif;
    font-size: 16px;
    line-height: 22px;

    a {
      color: $main-black;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto 0;
    font-family: "Avenir-Medium", serif;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    width: 250px;
    height: 40px;
    color: #fff;
    background-color: $main-gold;
  }
}
