@import '../../variables';

#cart{
	.cart .shop {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .cart  .shop:hover {
		color: #c49b65;
		font-weight: 400;
		text-decoration: underline;
	  }
	
	  .cart .remove-btn{
		  background: transparent;
		  text-align: right;
	  }
	
	  .cart div > .div-block-19{
		  padding-top: 0px !important;
	  }
	
	  .cart .div-block{
		  margin-bottom: 20px;
	  }
	
	  .right-area.cart-layout .right .back{
		  display: none;
	  }
	  
	  .cart  .shop:active {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .cart  .icon-3 {
		color: #c49b65;
	  }
	  
	  .cart  .dropdown-link-11 {
		background-color: #000;
		color: #fff;
	  }
	  
	  .cart  .text-block-10 {
		color: #b99055;
	  }
	  
	  .cart  .link-block-4 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 20px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-family: 'Avenir-Book', sans-serif;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .cart  .link-block-4:hover {
		text-decoration: underline;
	  }
	  
	  .cart  .dropdown-link-7 {
		background-color: #000;
		color: #fff;
	  }
	  
	  .cart .bold-text-5 {
		color: #fff;
	  }
	  
	  .cart  .container-8 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .cart .link-block-5 {
		padding: 20px;
		font-family: 'Avenir-Book', sans-serif;
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .cart .link-block-5:hover {
		text-decoration: underline;
	  }
	  
	  .cart .image-3 {
		position: relative;
		display: block;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-top: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	  }
	  
	  .cart .bold-text-2 {
		color: #c49b65;
		text-decoration: none;
	  }
	  
	  .cart  .bold-text-3 {
		color: #b99055;
	  }
	  
	  .cart  .icon-4 {
		color: #b99055;
	  }
	  
	  .cart  .dropdown-link-8 {
		background-color: #000;
	  }
	  
	  .cart .about-us {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .cart .about-us:hover {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .cart .brand {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	  }
	  
	  .cart .navbar-2 {
		position: -webkit-sticky;
		position: sticky;
		top: 0px;
		background-color: #000;
	  }
	  
	  .cart .nav-menu-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: left;
	  }
	  
	  .cart .how-it-works {
		font-family: 'Avenir-Book', sans-serif;
	  }
	  
	  .cart .how-it-works:hover {
		color: #c49b65;
		text-decoration: underline;
	  }
	  
	  .cart .bold-text-4 {
		color: #c49b65;
	  }
	  
	  .cart .section {
		padding-top: 60px;
		padding-bottom: 140px;
		font-family: 'Avenir-Book', sans-serif;
		background-color: transparent !important;
	  }
	  
	  .cart .text-span {
		font-size: 30px;
		font-weight: 400;
	  }
	  
	  .cart .columns {
		margin-top: 0px;
	  }
	  
	  .cart .div-block {
		margin-top: 0px;
		padding: 20px;
		border-style: solid;
		border-width: 1px;
		border-color: #d5d5d5;
		background-color: #fff;
	  }
	  
	  .cart .heading {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 25px;
		line-height: 33px;
		font-family: 'Avenir-Heavy';
    	font-weight: normal;
	  }
	  
	  .cart .heading-2 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 25px;
		line-height: 33px;
	  }
	
	  .cart .link strong{
		  color:#b99055;
		  font-size: 14px;
	  }
	  
	  .cart .div-block-2 {
		margin-top: 0px;
		padding: 20px;
		border-style: solid;
		border-width: 1px;
		border-color: #d8d8d8;
		background-color: #fff;
	  }
	  
	  .cart  .heading-3 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 20px;
		line-height: 33px;
		text-align: right;
	  }
	  
	  .cart  .heading-4 {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 20px;
		line-height: 33px;
		text-align: right;
		font-family: 'Avenir-Heavy';
    	font-weight: normal;
	  }
	  
	  .cart .columns-2 {
		margin-top: 60px;
	  }
	  
	  .cart .field-label {
		margin-top: 20px;
		font-size: 16px;
		font-weight: 400;
	  }
	  
	  .cart  .text-block-12 {
		font-size: 16px;
	  }
	  
	  .cart .field-label-2 {
		margin-top: 20px;
		font-size: 16px;
		font-weight: 400;
	  }
	  
	  .cart  .text-block-13 {
		font-size: 16px;
	  }
	  
	  .cart .container-9 {
		margin-top: 0px;
	  }
	  
	  .cart .select-field {
		margin-bottom: 0px;
	  }
	  
	  .cart .form-block {
		margin-bottom: 0px;
	  }
	  
	  .cart .select-field-2 {
		margin-bottom: 0px;
	  }
	  
	  .cart .form-block-2 {
		margin-bottom: 0px;
	  }
	  
	  .cart .columns-19 {
		margin-top: 20px;
	  }
	  
	  .cart .text-block-29 {
		text-align: right;
	  }
	  
	  .cart .column-18 {
		margin-top: 0px;
	  }
	  
	  .cart .div-block-15 {
		margin-top: 0px;
		padding: 0px;
		border: 1px none #d5d5d5;
		font-size: 16px;
	  }
	  
	  .cart .div-block-16 {
		margin-top: 0px;
		padding-right: 0px;
		padding-bottom: 10px;
		text-align: right;
	  }
	  
	  .cart .div-block-17 {
		margin-top: 20px;
		padding-bottom: 10px;
		text-align: right;
	  }
	  
	  .cart .heading-5 {
		margin-top: 0px;
		margin-bottom: 20px;
		font-size: 30px;
		line-height: 22px;
		text-align: left;
		font-family: 'Avenir-Medium';
    	font-weight: normal;
	  }
	  
	  .cart .heading-6 {
		margin-top: 0px;
		margin-bottom: 20px;
		font-size: 45px;
		font-weight: 400;
	  }
	  
	  .cart .columns-20 {
		margin-top: 20px;
	  }
	  
	  .cart  .column-19 {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	  }
	  
	  .cart .heading-7 {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 20px;
	  }
	  
	  .cart .heading-8 {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 20px;
		text-align: right;
	  }
	  
	  .cart .columns-21 {
		margin-top: 30px;
		border-top: 1px solid #ababab;
	  }
	  
	  .cart .columns-22 {
		margin-top: 20px;
	  }
	  
	  .cart .columns-23 {
		margin-top: 20px;
	  }
	  
	  .cart .columns-24 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 20px;
		padding-top: 20px;
		border-style: solid none none;
		border-width: 1px;
		border-color: #d8d8d8;
	  }
	  
	  .cart .column-20 {
		text-align: right;
	  }
	  
	  .cart .button {
		display: block;
		margin-top: 40px;
		padding: 20px 40px;
		background-color: #c49b65;
		font-size: 20px;
	  }
	  
	  .cart .text-block-30 {
		margin-bottom: 10px;
		text-align: left;
	  }
	  
	  .cart .button-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 40px;
		padding-top: 20px;
		padding-bottom: 20px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		background-color: #c49b65;
		font-size: 18px;
		text-align: left;
	  }
	
	  .cart .button-2:hover{
		  color:#fff !important;
		  text-decoration: none;
	  }
	  
	  .cart .form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		grid-auto-columns: 1fr;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	  }
	  
	  .cart .submit-button {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		border: 2px none #c49b65;
		background-color: #c49b65;
	  }
	  
	  .cart .text-field {
		margin-bottom: 0px;
	  }
	  
	  .cart .form-block-3 {
		margin-bottom: 20px;
	  }
	  
	  .cart .div-block-18 {
		margin-top: 0px;
		padding-bottom: 10px;
		text-align: right;
	  }
	  
	  .cart .div-block-19 {
		position: static;
		top: auto;
		display: block;
		margin-top: 0px;
		padding: 20px 0px 10px 0px;
		align-items: center;
		background-color: #fff;
		text-align: right;
	  }
	
	  .fw-600{
		  font-weight: 600;
	  }
	
	//   .right-area.cart-layout .right .back{
	// 	  display: none !important;
	//   }
	
	  .right-area.cart-layout .right{
		  padding: 35px 105px;
	  }
	
	//   .right-area.cart-layout {
	//     flex-direction: column;
	//     position: inherit;
	//     overflow-x: hidden;
	// }
	
	// .right-area{
	// 	width: 100% !important;
	// 	height: auto !important;
	// }
	  
	  .cart .image-4 {
		opacity: 0.3;
	  }
	  
	  .cart .image-5 {
		opacity: 0.3;
	  }
	  
	  .cart .columns-26 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 60px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .cart .column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .cart .image-21 {
		opacity: 1;
		-webkit-filter: brightness(0%);
		filter: brightness(0%);
	  }
	  
	  .cart .heading-9 {
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 20px;
		color: #000;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
	  }
	  
	  .image-24 {
		-webkit-filter: brightness(0%);
		filter: brightness(0%);
	  }
	  
	  .cart .column-21 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .cart .heading-10 {
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 20px;
		font-family: 'Avenir-Book', sans-serif;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
	  }
	  
	  .cart .text-block-31 {
		margin-top: 40px;
	  }
	  
	  .cart .text-block-32 {
		text-align: right;
	  }
	  
	  .cart .columns-28 {
		margin-top: 20px;
	  }
	  
	  .cart .button-3 {
		background-color: #fff;
		color: #b99055;
	  }
	  
	  .cart .container-12 {
		padding-bottom: 80px;
	  }
	  
	  .cart .link {
		color: #b99055;
		text-decoration: none;
	  }
	  
	  .link:hover {
		text-decoration: none;
	  }
	
	  .viewProduct{
		  display: inline-block !important;
	  }
	
	  .empty-cart{
		text-align: center;
	  }
	
	  .empty-cart p{
		font-size: 20px;
		color: #c49b65;
		margin-top: 30px;
	  }
	  
	  .cart .column-26 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .cart .column-27 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .cart .column-28 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .cart .column-29 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .cart .container-13 {
		margin-top: 100px;
	  }
	  
	  .cart .columns-29 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	  }
	  
	  .cart .link-block-6 {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	  }
	  
	  .cart .heading-11 {
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 10px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
	  }
	  
	  .cart .heading-12 {
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 10px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
	  }
	  
	  @media screen and (max-width: 991px) {
		.cart .shop {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.cart .link-block-4 {
		  display: block;
		  text-align: left;
		}
	  
		.cart .icon {
		  color: #c49b65;
		}
	  
		.cart .menu-button {
		  background-color: #000;
		}
	  
		.cart .menu-button.w--open {
		  background-color: #000;
		}
	  
		.cart .link-block-5 {
		  display: block;
		}
	  
		.cart .about-us {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.cart .nav-menu-2 {
		  display: block;
		  background-color: #000;
		}
	  
		.cart .how-it-works {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.cart .text-block-11 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.cart .heading-9 {
		  margin-left: 10px;
		}
	  
		.cart .heading-10 {
		  margin-left: 10px;
		}
	  
		.cart .column-22 {
		  padding-left: 0px;
		}
	  
		.cart .columns-27 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.cart .image-25 {
		  padding-right: 20px;
		}
	  
		.cart .image-26 {
		  padding-right: 20px;
		}
	  
		.cart .container-13 {
		  margin-top: 60px;
		}
	  }
	  
	  @media screen and (max-width: 767px) {
		.cart .shop {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.cart .icon-3 {
		  margin-right: 20px;
		}
	  
		.cart .icon {
		  color: #b99055;
		}
	  
		.cart .container-8 {
		  padding-right: 0px;
		  padding-left: 20px;
		}
	  
		.cart .icon-4 {
		  margin-right: 20px;
		}
	  
		.cart .about-us {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.cart .brand {
		  padding-left: 0px;
		}
	  
		.cart .how-it-works {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.cart .section {
		  padding-top: 40px;
		}
	  
		.cart .columns {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.cart .div-block-2 {
		  display: block;
		}
	  
		.cart .heading-3 {
		  margin-top: 0px;
		}
	  
		.cart .container-9 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.cart .columns-19 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.cart .heading-5 {
		  margin-top: 40px;
		}
	  
		.cart .columns-22 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.cart .columns-23 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.cart .columns-24 {
		  -webkit-box-align: stretch;
		  -webkit-align-items: stretch;
		  -ms-flex-align: stretch;
		  align-items: stretch;
		}
	  
		.cart .columns-25 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.cart .columns-26 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  margin-top: 40px;
		}
	  
		.cart .column {
		  padding-left: 10px;
		}
	  
		.cart .column-21 {
		  padding-right: 10px;
		}
	  
		.cart .heading-10 {
		  margin-bottom: 0px;
		  font-weight: 400;
		}
	  
		.cart .container-10 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.cart .container-11 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.cart .column-23 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.cart .columns-27 {
		  display: block;
		}
	  
		.cart .image-25 {
		  padding-right: 0px;
		}
	  
		.cart .image-26 {
		  padding-right: 0px;
		}
	  
		.cart .column-24 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.cart .columns-28 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.cart .container-12 {
		  padding-right: 20px;
		  padding-bottom: 40px;
		  padding-left: 20px;
		}
	  
		.cart .column-25 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.cart .column-27 {
		  margin-top: 20px;
		}
	  
		.cart .column-29 {
		  margin-top: 20px;
		}
	  
		.cart .container-13 {
		  padding-right: 20px;
		  padding-left: 20px;
		}
	  
		.cart .columns-29 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		}
	  
		.cart .column-30 {
		  padding-left: 0px;
		}
	  
		.cart .heading-11 {
		  font-size: 14px;
		  line-height: 22px;
		  font-weight: 400;
		}
	  }
	  
	  @media screen and (max-width: 479px) {
		.cart .menu-button {
		  position: relative;
		  margin-left: 0px;
		  -webkit-box-ordinal-group: 1;
		  -webkit-order: 0;
		  -ms-flex-order: 0;
		  order: 0;
		  -webkit-box-flex: 0;
		  -webkit-flex: 0 auto;
		  -ms-flex: 0 auto;
		  flex: 0 auto;
		}
	  
		.cart .menu-button.w--open {
		  background-color: #000;
		}
	  
		.cart .container-8 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  padding-right: 0px;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: reverse;
		  -webkit-flex-direction: row-reverse;
		  -ms-flex-direction: row-reverse;
		  flex-direction: row-reverse;
		  -webkit-box-pack: justify;
		  -webkit-justify-content: space-between;
		  -ms-flex-pack: justify;
		  justify-content: space-between;
		  -webkit-box-align: center;
		  -webkit-align-items: center;
		  -ms-flex-align: center;
		  align-items: center;
		}
	  
		.cart .image-3 {
		  margin-top: 0px;
		}
	  
		.cart .brand {
		  margin-right: 70px;
		}
	  
		.cart .columns {
		  display: block;
		}
	  
		.cart .heading {
		  margin-top: 20px;
		}
	  
		.cart .heading-2 {
		  margin-top: 20px;
		}
	  
		.cart .heading-3 {
		  margin-top: 40px;
		  text-align: left;
		}
	  
		.cart .heading-4 {
		  margin-top: 40px;
		  text-align: left;
		}
	  
		.cart .columns-25 {
		  display: block;
		}
	  
		.cart .columns-26 {
		  display: block;
		}
	  
		.cart .column-21 {
		  padding-left: 0px;
		}
	  
		.cart .column-27 {
		  margin-top: 20px;
		}
	  
		.cart .column-29 {
		  margin-top: 20px;
		}
	  
		.cart .columns-29 {
		  display: -webkit-box;
		  display: -webkit-flex;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-orient: horizontal;
		  -webkit-box-direction: normal;
		  -webkit-flex-direction: row;
		  -ms-flex-direction: row;
		  flex-direction: row;
		}
	  
		.cart .column-30 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  
		.cart .column-31 {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  }
	  
}


