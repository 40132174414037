@import '../../../../variables';

.orders-info-wrapper {
	.subscription-cancel-info {
		margin: 23px auto -17px auto;
		max-width: 900px;
		padding: 0 40px;
		text-align: center;
		font-family: "Avenir-Medium", serif;
		font-size: 16px;
		line-height: 22px;
		color: #000;
		a {
			color: #000;
			font-family: "Avenir-Heavy", serif;
		}
	}
	.active-orders-wrapper,
	.paused-orders-wrapper,
	.past-orders-wrapper {
		background-color: #fff;
		padding: 40px;
		max-width: 900px;
		box-sizing: border-box;
		margin: 40px auto 40px auto;
		@media (max-width: 400px) {
			padding: 16px;
		}

		.title {
			font-family: "YesevaOne-Regular", serif;
			font-size: 25px;
			line-height: 29px;
			color: #000;
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: 0.2s padding;
			&.opened {
				padding-bottom: 28px;
			}

			.open-icon {
				font-family: "YesevaOne-Regular", serif;
				font-size: 50px;
				line-height: 50px;
				cursor: pointer;
			}
		}
	}

	.consultations {
		margin: 20px 0 0 0;
		display: flex;
		flex-flow: column nowrap;
		position: relative;
		top: -10px;
	}

	.consultationItem {
		border-top: 2px solid $main-gold;
		padding: 20px 50px;

		b {
			font-weight: 600;
		}

		&:last-of-type {
			padding: 20px 50px 0 50px;
		}
	}
	.dialog-wrapper {
		background-color: #f0f0f0;
		max-width: 620px;
		padding: 60px;
		.close-icon {
			position: absolute;
			top: 13px;
			right: 13px;
			border: 1px solid $main-gold;
			color: $main-gold;
			border-radius: 100%;
			width: 20px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			@media (max-width: 600px) {
				top: 20px;
				right: 20px;
			}
		}
		@media (max-width: 600px) {
			padding: 25px;
		}
		.title {
			font-family: "Avenir-Heavy", serif;
			font-size: 20px;
			line-height: 24px;
			color: #000;
			text-align: center;
			margin-bottom: 60px;
		}
		.description {
			font-family: "Avenir-Book", serif;
			font-size: 16px;
			line-height: 22px;
			color: #000;
			text-align: center;
			max-width: 500px;
			margin-bottom: 130px;
			&.medium {
				font-family: "Avenir-Medium", serif;
			}
		}

		.btns {
			display: flex;
			justify-content: space-between;

			.yes-btn,
			.no-btn {
				width: 220px;
				height: 40px;
				box-sizing: border-box;
				font-family: "Avenir-Heavy", serif;
				font-size: 16px;
				line-height: 22px;
				cursor: pointer;
				outline: none;
				@media (max-width: 600px) {
					width: 160px;
				}
				@media (max-width: 420px) {
					width: 110px;
				}
			}
			.yes-btn {
				background-color: #bb9257;
				color: #ffffff;
				border: 0;
				margin-right: 15px;
			}
			.no-btn {
				background-color: #ffffff;
				border: 2px solid #bb9257;
				color: #bb9257;
			}
			&.one-btn {
				justify-content: center;
				.yes-btn {
					margin-right: 0;
				}
			}
		}
		.why-radio-btns {
			label {
				display: flex;
				align-items: center;
				cursor: pointer;
				font-family: "Avenir-Book", serif;
				font-size: 16px;
				line-height: 22px;
				color: #000000;
				margin-bottom: 13px;
				input {
					display: none;
				}
				.radio-mark {
					box-sizing: border-box;
					width: 23px;
					height: 23px;
					border-radius: 100%;
					border: 1px solid #bb9257;
					margin-right: 10px;
					padding: 1px;
					&.checked {
						background-color: #bb9257;
					}
				}
			}
			.other-pause-reason {
				resize: none;
				width: 100%;
				height: 150px;
				margin-bottom: 20px;
				font-family: "Avenir-Book", serif;
				font-size: 16px;
				line-height: 22px;
				padding: 10px;
				box-sizing: border-box;
				border-color: #bb9257;
			}
		}
		.reason-validation {
			font-family: "Avenir-Book", serif;
			color: #e26161;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			margin-bottom: 20px;
		}
	}
}
