@import '../../variables';

.post-consult-component {
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Avenir-Medium", serif;
	font-size: 13px;
	@media (max-width: 959px) {
		height: auto;
	}
	.box {
		position: relative;
		background-color: #f0f0f0;
		width: 762px;
		min-height: 51vh;
		padding: 37px 40px 30px 40px;
		@media (max-width: 959px) {
			width: 100vw;
		}
		.close {
			position: absolute;
			top: 5px;
			right: 5px;
			cursor: pointer;
			width: 26px;
			height: 26px;
			border: 1px solid $main-gold;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 10px;
				height: 10px;
			}
		}
		.messages-container {
			position: relative;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			margin-bottom: 20px;
			.title {
				text-transform: uppercase;
				text-align: center;
				padding: 0 5px 45px 5px;
				font-size: 24px;
				letter-spacing: 0.06em;
				font-family: "YesevaOne-Regular", serif;
			}
			.question {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				background-color: #e8e8e8;
				font-family: "Avenir-Heavy", serif;
				&:nth-child(even) {
					background-color: #e1e1e1;
				}
				.question-title {
					padding: 11px 20px 11px 7px;
					line-height: 1.2em;
				}
				.radio-question {
					display: flex;
					padding: 10px 0 10px 10px;
					.input-container {
						display: block;
						position: relative;
						padding: 0 20px 0 25px;
						line-height: 1.3em;
						cursor: pointer;
						-webkit-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
						input {
							&[type="radio"] {
								position: absolute;
								opacity: 0;
								cursor: pointer;
								height: 0;
								width: 0;
							}
							&:checked ~ .checkmark {
								background: $main-gold;
							}
						}
						.checkmark {
							position: absolute;
							top: 0;
							left: 0;
							height: 13px;
							width: 13px;
							background-color: #f0f0f0;
							border-radius: 50%;
							border: 1px solid #a7a7a7;
							&::after {
								content: "";
								position: absolute;
								display: none;
							}
						}
					}
				}
				.text-question {
					width: 100vw;
					textarea {
						resize: none;
						background-color: #ebebeb;
						padding: 5px;
						width: calc(100% - 10px - 2px);
						border: 1px solid #a7a7a7;
						color: #565656;
						min-height: 15px;
						&:focus {
							outline: 1px solid $main-gold;
						}
					}
				}
				.input-question {
					input {
						background: transparent;
						outline: none;
						border: 0;
						border-bottom: 0.1px solid #a7a7a7;
						height: 23px;
					}
				}
			}
			.finish {
				font-family: "Avenir-Medium", serif;
				letter-spacing: 0.1em;
				font-size: 16px;
				line-height: 18px;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				box-sizing: border-box;
				height: 50px;
				border-width: 2px;
				border-style: solid;
				border-radius: 0;
				border-color: $main-gold;
				background-color: $main-gold;
				color: #ffffff;
				text-transform: uppercase;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
