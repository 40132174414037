@import '../../variables';

.how-it-works-component {
	position: relative;
	font-family: "Avenir-Medium", serif;
	background-color: #dbdbdb;
	padding: 70px 0 0 0;
	@media (max-width: 959px) {
		flex-direction: column;
		padding-top: 80px;
	}
	.start {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: calc(5% + 70px);
		padding-right: calc(5% + 16px);
		padding-bottom: 50px;
		max-width: 1000px;
		margin: 0 auto;
		min-height: calc(100vh - 70px);
		@media (max-width: 959px) {
			flex-direction: column;
			padding-left: 5%;
			padding-right: 5%;
			min-height: initial;
		}
		.left-part {
			flex: 1;
			padding-right: 20px;
			@media (max-width: 959px) {
				padding: 0;
			}
			.title {
				display: flex;
				font-family: "YesevaOne-Regular", serif;
				font-size: 64px;
				@media (max-width: 1200px) {
					font-size: 64px;
				}
				@media (max-width: 1000px) {
					font-size: 46px;
					line-height: 52px;
				}
				.horizontal-line {
					border-top: 2px solid $main-gold;
					flex: auto;
					margin-left: 50px;
					margin-top: 30px;
				}
			}
			.content {
				text-align: left;
				padding: 20px 0 0 0;
				font-family: "Avenir-Medium", serif;
				font-size: 15px;
				letter-spacing: 0.02em;
				line-height: 1.4em;
				p.padding-top {
					padding-top: 15px;
				}
			}
		}
		.right-part {
			flex: 1;
			padding-left: 20px;
			@media (max-width: 959px) {
				padding: 20px 0 0 0;
			}
			img {
				width: 100%;
				height: auto;
				@media (min-width: 1600px) {
					width: 130%;
				}
			}
		}
	}
	.steps {
		background-color: #cfcfcf;
		.step {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-left: calc(5% + 70px);
			padding-right: calc(5% + 16px);
			padding-bottom: 50px;
			max-width: 1000px;
			margin: 0 auto;
			@media (max-width: 959px) {
				flex-direction: column-reverse;
				padding-left: 5%;
				padding-right: 5%;
			}
			.left-part {
				flex: 1;
				padding: 30px 20px 0 0;
				@media (max-width: 959px) {
					padding: 20px 0 0 0;
				}
				img {
					max-width: 490px;
					width: 100%;
					height: auto;
				}
			}
			.right-part {
				flex: 1;
				padding-left: 20px;
				@media (max-width: 959px) {
					padding: 20px 0 0 0;
				}
				.title {
					display: flex;
					flex-direction: row;
					padding-bottom: 10px;
					justify-content: flex-start;
					align-items: flex-end;
					height: 106px;
					font-family: "YesevaOne-Regular", serif;
					.number {
						font-size: 80px;
						padding-right: 10px;
						border-right: 1px solid #000000;
						@media (max-width: 959px) {
							font-size: 70px;
						}
					}
					.text {
						font-size: 28px;
						text-transform: capitalize;
						padding-left: 15px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						line-height: 1.4em;
						@media (max-width: 959px) {
							font-size: 28px;
							padding-left: 10px;
						}
						.text-line {
							display: flex;
							flex-direction: row;
							align-items: flex-end;
							img {
								width: 80px;
								margin-left: 15px;
								@media (max-width: 959px) {
									width: 70px;
								}
							}
						}
					}
				}
				.content {
					text-align: left;
					padding: 20px 0 0 0;
					font-family: "Avenir-Medium", serif;
					font-size: 15px;
					letter-spacing: 0.02em;
					line-height: 1.4em;
					max-width: 320px;
				}
			}
		}
		.step-even {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-left: calc(5% + 70px);
			padding-right: calc(5% + 16px);
			padding-bottom: 50px;
			max-width: 1000px;
			margin: 0 auto;
			@media (max-width: 959px) {
				flex-direction: column;
				padding-left: 5%;
				padding-right: 5%;
			}
			.left-part {
				flex: 1;
				padding-left: 20px;
				@media (max-width: 959px) {
					padding: 0;
				}
				.title {
					display: flex;
					flex-direction: row;
					padding-bottom: 10px;
					justify-content: flex-start;
					align-items: flex-end;
					height: 106px;
					font-family: "YesevaOne-Regular", serif;
					.number {
						font-size: 80px;
						padding-right: 10px;
						width: 99px;
						border-right: 1px solid #000000;
						@media (max-width: 959px) {
							font-size: 70px;
						}
					}
					.text {
						font-size: 28px;
						text-transform: capitalize;
						padding-left: 15px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						line-height: 1.4em;
						@media (max-width: 959px) {
							font-size: 28px;
							padding-left: 10px;
						}
						.text-line {
							display: flex;
							flex-direction: row;
							align-items: flex-end;
							img {
								width: 80px;
								margin-left: 15px;
								@media (max-width: 959px) {
									width: 70px;
								}
							}
						}
					}
				}
				.content {
					text-align: left;
					padding: 20px 0 0 0;
					font-family: "Avenir-Medium", serif;
					font-size: 15px;
					letter-spacing: 0.02em;
					line-height: 1.4em;
					max-width: 320px;
				}
			}
			.right-part {
				flex: 1;
				padding-right: 20px;
				@media (max-width: 959px) {
					padding: 20px 0 0 0;
				}
				img {
					width: 100%;
					height: auto;
					max-width: 400px;
				}
			}
		}
	}
	.get-started-section {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		padding: 60px 0 80px 0;
		background-color: #cfcfcf;

		.title {
			font-family: "YesevaOne-Regular", serif;
			font-size: 37px;
			line-height: 43px;
			text-align: center;
			color: #000;
			margin-bottom: 50px;
		}
		.buttons {
			display: flex;
			@media (max-width: 550px) {
				flex-direction: column;
			}
			button {
				cursor: pointer;
				background-color: $main-gold;
				outline: none;
				border: none;
				padding: 16px 20px;
				color: #fff;
				font-family: "Avenir-Book", serif;
				font-size: 18px;
				line-height: 20px;
				min-width: 200px;
			}
			a {
				&:first-child {
					margin-right: 20px;
					@media (max-width: 550px) {
						margin-right: 0;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}
