@import '../../variables';

.admin-dashboard-component {
	position: relative;
	min-height: calc(100vh - 70px);
	z-index: 0;
	background: #dbdbdb;
	padding: 70px 0 20px 0;
	font-family: "Avenir-Medium", serif;

	.menu-section {
		position: fixed;
		top: 0;
		z-index: 2;
		background: #dbdbdb;
		height: 70px;
		transition: 0.5s linear;
		width: calc(100% - 40px);
		padding: 0 20px;
		.box {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			.left {
				flex: 1;
				font-size: 14px;
				justify-content: center;
				span {
					margin-right: auto;
				}
			}
			.logo {
				display: flex;
				flex: 1;
				justify-content: center;
				padding: 10px 0;
				.AW-logo-black {
					display: inline-block;
					height: 50px;
					cursor: pointer;
				}
				@media (max-width: 959px) {
					order: 1;
				}
			}
			.right-buttons {
				flex: 1;
				justify-content: center;
				display: flex;
				flex-direction: row;
				align-items: center;
				color: #1a1a1a;
				width: auto;
				span {
					margin-left: auto;
					.logout {
						cursor: pointer;
						white-space: nowrap;
						font-family: "Avenir-Heavy", serif;
						&:hover {
							text-decoration: underline;
						}
					}
				}
				@media (max-width: 959px) {
					width: auto;
					order: 3;
				}
			}
		}
	}
	.content-section {
		padding: 40px 20px 0 20px;
		display: flex;
		.sidebar {
			ul {
				background-color: #fff;
				li {
					font-family: "Avenir-Book", serif;
					cursor: pointer;
					padding: 16px 38px;
					border-bottom: 1px solid #f2f2f2;
					border-left: 14px solid #fff;
					&.active {
						border-left: 14px solid $main-gold;
					}
				}
			}
		}
		.admin-failed-orders-component {
			position: relative;
			flex-grow: 1;
			min-height: 100px;
			background: transparent;
			font-family: "Avenir-Medium", serif;
			font-size: 14px;
			.dates {
				margin: 0 0 40px 0;
				padding: 3px;
				display: flex;
				flex-direction: row;
				justify-content: center;
				font-size: 12px;
				letter-spacing: 1px;
				.day {
					cursor: pointer;
					background: #e7e7e7;
					padding: 5px;
					margin: 0 5px;
					display: flex;
					flex-direction: column;
					align-items: center;
					border-top: solid 2px #e7e7e7;
					min-width: 60px;
					&.selected {
						border-top: solid 2px $main-gold;
					}
					.date {
						color: #888888;
						padding: 5px 0;
					}
					.count {
						color: #ffffff;
						background: #dbdbdb;
						display: flex;
						width: 100%;
						padding: 5px 0;
						justify-content: center;
						&.active {
							background: #a4a4a4;
						}
					}
				}
			}
			.bar {
				display: block;
				height: 0;
				border-top: 2px solid $main-gold;
				margin: 30px 0;
			}
			.order-row {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				height: 90px;
				width: 90%;
				margin: 0 auto 8px auto;
				&.nested {
					margin-right: 20px;
				}
				.appointment-time {
					min-width: 60px;
					max-width: 100px;
					word-break: break-word;
					text-align: center;
					background: #ffffff;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 4px 0 0;
					color: #000000;
					font-family: "Avenir-Heavy", serif;
					font-size: 14px;
				}
				.content {
					flex: auto;
					background: #ffffff;
					margin: 0 4px;
					padding: 0 10px;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					.row1 {
						display: flex;
						flex-direction: row;
						align-items: center;
						height: 30px;
						.id {
							font-family: "Avenir-Heavy", serif;
							padding: 0 10px 0 0;
						}
						.date {
							padding: 0 10px 0 0;
						}
						.app-date {
							margin-left: auto;
						}
						.items {
							text-decoration: underline;
						}
						.justify-self-right {
							display: flex;
							margin-left: auto;
						}
						.document-view-buttons {
							margin-left: auto;
							display: flex;
						}
						.view-id {
							margin-left: 10px;
							white-space: nowrap;
							letter-spacing: 0.5px;
							color: $main-gold;
							font-family: "Avenir-Heavy", serif;
							cursor: pointer;
							font-size: 11px;
							text-transform: uppercase;
							display: flex;
							align-items: center;
							justify-content: flex-end;
							span {
								margin: 0 4px 0 0.3em;
							}
							img {
								height: 0.8em;
							}
						}
					}
					.row2 {
						display: flex;
						flex-direction: row;
						align-items: center;
						height: 30px;
						.patient-name {
							padding: 0 10px 0 10px;
							font-family: "Avenir-Heavy", serif;
						}
						.patient-birthday {
							padding: 0 10px 0 10px;
							font-family: "Avenir-Heavy", serif;
						}
						.patient-phone {
							padding: 0 10px 0 10px;
							font-family: "Avenir-Heavy", serif;
						}
						.patient-email {
							padding: 0 0 0 10px;
							font-family: "Avenir-Heavy", serif;
						}
						.buttons {
							display: flex;
							flex-direction: row;
							margin-left: auto;
							height: 30px;
						}
					}
					.row3 {
						display: flex;
						flex-direction: row;
						align-items: center;
						height: 30px;
					}
				}
				.button {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 4px;
					padding: 0 10px;
					font-family: "Avenir-Medium", serif;
					font-size: 12px;
					letter-spacing: 1px;
					line-height: 12px;
					cursor: pointer;
					height: 100%;
					min-width: 90px;
					text-transform: uppercase;
					&.message-btn {
						color: #ffffff;
						background: $main-gold;
						width: 110px;
						font-size: 10px;
						line-height: 10px;
					}
					&.reject {
						color: #000000;
						background: #b4b4b4;
					}
					&.approve {
						color: #ffffff;
						background: $main-gold;
					}
					&.set-appointment {
						color: #ffffff;
						background: #b4b4b4;
						min-width: 205px;
					}
				}
			}
		}
	}
}
