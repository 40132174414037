@import "src/variables";

.card-number {
	display: flex;
	flex-flow: column nowrap;

	.titles {
		display: flex;
		font-family: "Avenir-Heavy", serif;
		font-size: 14px;
		line-height: 25px;
		color: $main-gold;
		position: relative;

		.card-number-title {
			width: 207px;
			&.shrinked {
				width: 160px;
			}
			@media (max-width: 531px) {
				width: 130px;
			}
		}

		.expiry-date-title {
			width: 120px;
			&.shrinked {
				width: 80px;
			}
			@media (max-width: 531px) {
				width: 80px;
			}
		}

		.cvd-title {
			width: 50px;
			text-align: center;
			margin: 0;

			.csv-help-open {
				cursor: pointer;
			}

			img {
				margin: 0 0 0 7px;
				width: 22px;
				height: 22px;
				position: relative;
				top: -2px;
			}
		}
	}

	&-wrapper {
		display: flex;
		align-items: center;
	}

	&__cardImg {
		width: 50px;
		height: 35px;

		&:not(:last-of-type) {
			margin: 0 10px 0 0;
		}
	}

	&__cardIcons {
		display: flex;
		margin: 0 0 20px 0;
	}

	.show-csv-help-wrapper {
		position: relative;
		max-width: 400px;
		.close {
			position: absolute;
			top: 9px;
			right: 9px;
			cursor: pointer;
			width: 25px;
			height: 25px;
			border: 1px solid $main-gold;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 50%;
				height: 50%;
			}
		}
		.title {
			width: 100%;
			box-sizing: border-box;
			padding-right: 40px;
			font-family: "Avenir-Book", serif;
			font-size: 14px;
			line-height: 20px;
		}
		img.help-image {
			max-width: 100%;
			margin-top: 15px;
		}
	}
}
