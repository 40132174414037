@import '../../variables';

.pharmacist-pending-orders-component {
	.content {
		position: relative;
		display: flex;
		flex-direction: row;
		margin: 0 auto 20px auto;
		max-width: 1000px;

		.column-a {
			flex: 15;
			display: flex;
			flex-direction: row;
			border: 3px solid #ffffff;
			background: #eeeeee;

			.column-a1 {
				flex: 9;
				display: flex;
				flex-direction: column;
				padding: 0 10px;
				border-right: 1px solid #bbbbbb;

				.top {
					display: flex;
					flex-direction: row;
					flex: 2;
					padding: 10px 0;
					border-bottom: 1px solid #bbbbbb;

					.column-a11 {
						display: flex;
						flex-direction: column;
					}

					.column-a12 {
						padding: 0 0 0 10px;
					}
				}
			}

			.column-a2 {
				flex: 6;
				display: flex;
				flex-direction: column;
				padding: 0 10px;

				.top {
					flex: 2;
					padding: 10px 0;
					display: flex;
					flex-direction: column;

					&.bottom-border {
						border-bottom: 1px solid #bbbbbb;
					}
				}
			}

			.bottom {
				flex: none;
				padding: 8px 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.field {
					align-items: center;
				}

				.view-id {
					align-items: center;
				}
			}

			.field {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				letter-spacing: 0.5px;
				line-height: 20px;
				white-space: nowrap;

				span {
					font-family: "Avenir-Heavy", serif;
					margin-left: 1em;
					white-space: normal;
				}

				.address {
					font-family: "Avenir-Heavy", serif;
					margin-left: 1em;
					display: flex;
					flex-direction: column;
				}

				img {
					height: 1em;
				}

				&.items {
					justify-content: space-between;
				}

				&.view-id {
					color: $main-gold;
					cursor: pointer;
				}
			}
		}

		.column-b {
			flex: 4;
			display: flex;
			flex-direction: column;

			.button {
				flex: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				font-family: "Avenir-Heavy", serif;
				font-size: 9px;
				text-transform: uppercase;
				cursor: pointer;
				letter-spacing: 0.5px;

				&.good-1 {
					color: #ffffff;
					background: $main-gold;
					margin-bottom: 2px;
				}

				&.good-2 {
					color: #ffffff;
					background: $main-gold; //#57bb8b;
					margin-bottom: 2px;
				}

				&.bad {
					color: #ffffff;
					background: #e26161;
				}

				&.disabled {
					background: #b4b4b4;
					cursor: initial;
				}
			}
		}

		&__withPromocode {
			border: 3px solid #57bb8b;
		}

		.promocode {
			font-size: 14px;
		}
	}
	.express-shipping{
		color: red;
	}
}
