@import '../../variables';

.faq-component {
	position: relative;
	padding: 100px 0 30px 0;
	min-height: 100px;
	font-family: "Avenir-Medium", serif;
	background-color: #dbdbdb;
	.main {
		background-color: #f6f6f6;
		margin: 0 auto;
		max-width: 1200px;
		border: 10px solid #ffffff;
		.title {
			text-transform: uppercase;
			text-align: center;
			padding: 20px 5px 20px 5px;
			font-size: 64px;
			font-family: "YesevaOne-Regular", serif;
			@media (max-width: 750px) {
				font-size: 46px;
			}
		}
		.content-box {
			width: 90%;
			margin: 0 auto;
			padding: 10px 0 40px 0;
			@media (max-width: 480px) {
				width: 100%;
			}
			.accordion {
				.content-header {
					border-top: 1px solid #e5e5e5;
					display: flex;
					align-items: center;
					cursor: pointer;
					&.shown {
						border-bottom: 0;
						border-top: 2px solid $main-gold;
						.plus-image {
							display: none;
						}
						.minus-image {
							display: block;
						}
					}
					.minus-image {
						display: none;
					}
					img {
						width: 10px;
						height: 10px;
						padding: 0 10px 0 10px;
						opacity: 0.5;
					}
					.question {
						padding: 18px 10px;
						width: 100%;
						font-family: "Avenir-Heavy", serif;
						font-size: 28px;
						line-height: 1.4em;
						letter-spacing: 0.02em;
					}
				}
				.content {
					font-family: "Avenir-Medium", serif;
					font-size: 15px;
					padding: 10px;
					margin-left: 30px;
					line-height: 1.4em;
					letter-spacing: 0.02em;
					p {
						padding: 0 0 10px 0;
					}
					@media (max-width: 959px) {
						margin-left: 0;
					}
					.drug-table {
						display: flex;
						flex-direction: row;
						text-align: center;
						padding: 20px 0;
						color: #565655;
						font-size: 0.9em;
						@media (max-width: 959px) {
							flex-direction: column;
						}
						.drug-box {
							flex: auto;
							display: flex;
							flex-direction: column;
							@media (max-width: 959px) {
								flex-direction: row;
							}
							&.first {
								.example {
									@media (max-width: 1010px) {
										border-top: 1px solid #c7c7c9;
									}
								}
							}
							&.last {
								@media (min-width: 960px) {
									.drug-title {
										border-right: 0;
									}
									.example {
										border-right: 0;
									}
								}
							}
							.drug-title {
								background-color: #d7d7d7;
								border-right: 1px solid #f6f6f6;
								font-family: "Avenir-Heavy", serif;
								padding: 8px 3px;
								@media (max-width: 959px) {
									min-width: 70px;
									width: 33%;
									border-bottom: 1px solid #e3e3e3;
								}
							}
							.example {
								padding: 8px 3px;
								border-right: 1px solid #e5e5e5;
								border-bottom: 1px solid #e5e5e5;
								@media (max-width: 959px) {
									width: 33%;
								}
							}
						}
					}
					a {
						text-decoration: underline;
						color: #000000;
						font-family: "Avenir-Heavy", serif;
						&:hover {
							color: #ba9257;
						}
					}
					.faq-contact-item {
						display: flex;
						flex-direction: column;
						margin-bottom: 30px;
						&:last-child {
							margin-bottom: 0;
						}
						.partner-title {
							font-size: 1.2em;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}
