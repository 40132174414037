@import "../../../../variables";

.add-shipping-address-block {
	margin-top: 20px;
	.validation-error {
		font-family: "Avenir-Book", serif;
		font-size: 14px;
		line-height: 20px;
		color: #e26161;
	}
	.add-address-button {
		font-family: "Avenir-Book", serif;
		font-size: 16px;
		line-height: 22px;
		color: $main-gold;
		display: flex;
		align-items: center;
		cursor: pointer;
		width: fit-content;
		padding-right: 40px;
		.plus {
			font-family: "Avenir-Heavy", serif;
			margin-right: 10px;
		}
	}
	.add-address-form {
		display: flex;
		flex-wrap: wrap;
		.street,
		.suite,
		.city,
		.province,
		.country {
			margin-right: 1%;
		}
		.street {
			width: 20%;
		}
		.suite {
			width: 10%;
		}
		.city {
			width: 15%;
		}
		.province {
			width: 20%;
		}
		.country {
			width: 10%;
		}
		.postal {
			width: 17%;
		}
		@media (max-width: 980px) {
			.city,
			.suite,
			.street {
				margin-bottom: 20px;
			}
			.city {
				margin-right: 0;
			}
			.street,
			.suite,
			.province,
			.country {
				margin-right: 2%;
			}
			.street {
				width: 40%;
			}
			.suite {
				width: 26%;
			}
			.city {
				width: 30%;
			}
			.province {
				width: 40%;
			}
			.country {
				width: 26%;
			}
			.postal {
				width: 30%;
			}
		}
		@media (max-width: 570px) {
			.city,
			.suite,
			.street,
			.province,
			.country,
			.postal {
				margin-bottom: 20px;
				margin-right: 0;
				width: 49%;
			}
			.street,
			.city,
			.country {
				margin-right: 2%;
			}
		}
		@media (max-width: 450px) {
			.city,
			.suite,
			.street,
			.province,
			.country,
			.postal {
				margin-bottom: 20px;
				margin-right: 0;
				width: 100%;
			}
		}
	}
	.is-set-to-primary {
		display: flex;
		align-items: center;
		margin-top: 15px;
		margin-bottom: 10px;
		label {
			display: none;
		}
		.is-set-to-primary-text {
			margin-left: 10px;
			font-family: "Avenir-Book", serif;
			font-size: 11px;
			line-height: 15px;
			color: #000;
			span {
				font-weight: bold;
			}
		}
	}
	.action-buttons {
		display: flex;
		margin-top: 20px;
		button {
			outline: none;
			padding: 5px;
			border: none;
			background-color: $main-gold;
			margin-right: 20px;
			padding: 7px 35px;
			font-family: "Avenir-Book", serif;
			font-size: 16px;
			color: #fff;
			cursor: pointer;
		}
	}
}
