@import '../../variables';

.about-us-component {
	position: relative;
	font-family: "Avenir-Medium", serif;
	background-color: #dbdbdb;
	padding: 70px 0 0 0;
	@media (max-width: 959px) {
		flex-direction: column;
		padding-top: 80px;
	}
	.start {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: calc(5% + 70px);
		padding-right: calc(5% + 16px);
		max-width: 1000px;
		margin: 0 auto;
		min-height: calc(100vh - 70px);
		@media (max-width: 959px) {
			flex-direction: column;
			padding-left: 5%;
			padding-right: 5%;
		}
		.left-part {
			flex: 1;
			padding-right: 20px;
			@media (max-width: 959px) {
				padding: 0;
			}
			.title {
				display: flex;
				font-family: "YesevaOne-Regular", serif;
				font-size: 64px;
				@media (max-width: 750px) {
					font-size: 48px;
				}
				.horizontal-line {
					border-top: 2px solid $main-gold;
					flex: auto;
					margin-left: 50px;
					margin-top: 30px;
				}
			}
			.content {
				font-family: "Avenir-Heavy", serif;
				font-size: 18px;
				line-height: 1.4em;
				letter-spacing: 0.02em;
				text-align: justify;
				padding-top: 50px;
			}
			.bottom-text {
				font-family: "Avenir-Medium", serif;
				font-size: 15px;
				line-height: 1.4em;
				letter-spacing: 0.02em;
				text-align: justify;
				padding-top: 30px;
			}
		}
		.right-part {
			flex: 1;
			padding-left: 20px;
			@media (max-width: 959px) {
				padding: 20px 0 20px 0;
			}
			img {
				width: 100%;
				height: auto;
				@media (min-width: 1400px) {
					width: 130%;
				}
			}
		}
	}
	.our-goal {
		padding: 50px 10px 100px 10px;
		text-align: center;
		font-family: "Avenir-Medium", serif;
		font-size: 25px;
		line-height: 1.4em;
		width: 80vw;
		max-width: 750px;
		margin: 0 auto;
		border-top: solid 2px $main-gold;
		.bold {
			font-family: "Avenir-Bold", serif;
		}
		div {
			padding-bottom: 30px;
		}
	}
	.meet-winston-wrapper {
		background-color: #c39a64;
		background-image: url("../../static/images/about-us/meet-winston.jpg");
		background-size: contain;
		background-repeat: no-repeat;
		background-position-x: left;
		background-position-y: top;
		@media (max-width: 1040px) {
			flex-direction: column;
			background-image: none;
		}
		.meet-winston {
			display: flex;
			flex-direction: row;
			height: 400px;
			padding-left: calc(5% + 430px);
			padding-right: calc(5% + 16px);
			margin: 0 auto;
			@media (max-width: 1040px) {
				flex-direction: column;
				height: auto;
				padding: 0;
			}
			.meet-winston-image {
				flex: auto;
				display: none;
				@media (max-width: 1040px) {
					display: block;
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			.meet-winston-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;
				text-align: right;
				font-size: 25px;
				color: #ffffff;
				font-family: "YesevaOne-Regular", serif;
				width: 70%;
				line-height: 1.4em;
				min-width: 475px;
				margin: 0 auto;
				.header {
					font-family: "YesevaOne-Regular", serif;
					font-size: 48px;
					padding-bottom: 50px;
					line-height: 56px;
					@media (max-width: 750px) {
						font-size: 37px;
					}
				}
				@media (max-width: 1340px) {
					width: 50%;
				}
				@media (max-width: 1040px) {
					width: 90%;
					margin: 0 auto;
					align-items: center;
					text-align: center;
					padding: 35px 10px 35px 10px;
					min-width: initial;
					.header {
						padding-bottom: 20px;
					}
				}
				@media (max-width: 460px) {
					font-size: 20px;
					min-width: initial;
				}
			}
		}
	}
	.principles {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: calc(5% + 70px);
		padding-right: calc(5% + 70px);
		padding-bottom: 80px;
		padding-top: 100px;
		max-width: 1000px;
		margin: 0 auto;
		@media (max-width: 959px) {
			flex-direction: column;
			padding-left: 5%;
			padding-right: 5%;
		}
		.title {
			font-family: "YesevaOne-Regular", serif;
			font-size: 48px;
			line-height: 56px;
			@media (max-width: 750px) {
				font-size: 37px;
				line-height: 42px;
				text-align: center;
			}
		}
		.content {
			display: flex;
			flex-direction: row;
			padding-top: 80px;
			justify-content: space-around;
			@media (max-width: 1040px) {
				flex-direction: column;
				justify-content: flex-start;
				padding-top: 40px;
			}
			.left-part {
				padding-right: 40px;
				@media (max-width: 520px) {
					padding: 0;
				}
			}
			.left-part,
			.right-part {
				display: flex;
				flex-direction: column;
				width: 480px;
				@media (max-width: 520px) {
					width: 290px;
				}
				.block {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding-bottom: 30px;
					p {
						font-family: "Avenir-Medium", serif;
						font-size: 15px;
						letter-spacing: 0.02em;
						line-height: 1.4em;
						&.title {
							font-family: "Avenir-Heavy", serif;
							padding-bottom: 5px;
						}
					}
					.img-wrapper {
						display: flex;
						flex-direction: row;
						justify-content: center;
						width: 70px;
						padding-right: 20px;
						flex: none;
						@media (max-width: 520px) {
							width: 60px;
							padding-right: 20px;
						}
						img {
							height: 40px;
							width: 40px;
						}
					}
				}
			}
		}
	}
	.doctor-part {
		background-color: #cfcfcf;
		.doctor-content {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-left: calc(5% + 70px);
			padding-right: calc(5% + 70px);
			padding-bottom: 50px;
			padding-top: 50px;
			max-width: 1000px;
			margin: 0 auto;
			@media (max-width: 959px) {
				flex-direction: column;
				padding-left: 5%;
				padding-right: 5%;
			}
			.left-part {
				flex: 1;
				padding-right: 20px;
				text-align: center;
				@media (max-width: 959px) {
					padding: 0;
				}
				img {
					width: auto;
					height: 300px;
				}
			}
			.right-part {
				flex: 1;
				padding-left: 20px;
				@media (max-width: 959px) {
					padding: 60px 0 0 0;
				}
				.title {
					display: flex;
					font-family: "YesevaOne-Regular", serif;
					font-size: 48px;
					line-height: 56px;
					@media (max-width: 750px) {
						font-size: 37px;
						line-height: 42px;
					}
					.horizontal-line {
						border-top: 2px solid $main-gold;
						flex: auto;
						margin-left: 20px;
						margin-top: 14px;
					}
					@media (max-width: 959px) {
						text-align: center;
					}
				}
				.content {
					text-align: start;
					font-size: 15px;
					padding: 20px 0 0 0;
					font-family: "Avenir-Medium", serif;
					letter-spacing: 0.02em;
					line-height: 1.4em;
				}
			}
		}
	}
}
