@import '../../../../variables';

.approve-order-wrapper {
	width: 400px;
	max-width: 100%;
	.validation-error {
		font-family: "Avenir-Book", serif;
		color: #e26161;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		margin-top: 10px;
	}
	.title {
		font-family: "YesevaOne-Regular", serif;
		font-size: 20px;
		line-height: 20px;
		margin-bottom: 25px;
	}
	.custom-input-wrapper {
		margin-top: 20px;
		&:first-child {
			margin-top: 0;
		}
	}
	.action-buttons {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-top: 30px;
		button {
			font-family: "Avenir-Book", serif;
			background-color: $main-gold;
			outline: none;
			width: 130px;
			font-size: 18px;
			cursor: pointer;
			line-height: 25px;
			color: #ffffff;
			border: none;
			padding: 12px 0;
		}
	}
}
