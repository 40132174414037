@import '../../variables';

.custom-select-wrapper {
	width: 100%;
	.label {
		font-family: "Avenir-Book", serif;
		font-size: 14px;
		line-height: 20px;
		color: $main-gold;
	}
}
