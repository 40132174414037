@import '../../variables';

.custom-checkbox {
	cursor: pointer;
	.checkbox {
		width: 18px;
		height: 18px;
		border: 2px solid $main-gold;
		padding: 2px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		&.focused {
			outline: 1px auto black;
		}
		svg {
			width: 100%;
			height: 100%;
			fill: $main-gold;
		}
	}
	input {
		position: absolute;
		left: -5000px;
	}
}
