@import '../../variables';

.doctor-orders-component {
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Avenir-Heavy", serif;
	.box {
		width: 60vw;
		height: 90vh;
		display: block;
		background: #ffffff;
		overflow-y: auto;
		padding: 30px 30px;
		.close {
			cursor: pointer;
			width: 25px;
			height: 25px;
			border: 1px solid $main-gold;
			border-radius: 50%;
			margin: -10px -10px 20px auto;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 50%;
				height: 50%;
			}
		}
		.name {
			padding-bottom: 20px;
		}
		.order-row {
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			min-height: 75px;
			width: 100%;
			margin: 8px 0;
			padding: 0 10px;
			&:nth-child(odd) {
				background-color: #eaeaea;
			}
			&:nth-child(even) {
				background-color: #e0e0e0;
			}
			.id {
				font-family: "Avenir-Heavy", serif;
				flex: none;
				padding: 0 20px 0 0;
			}
			.date {
				flex: none;
				padding: 0 20px 0 0;
				width: 80px;
			}
			.items {
				flex: auto;
				text-decoration: underline;
				padding: 0 20px 0 0;
			}
			.status {
				flex: none;
			}
		}
	}
}
