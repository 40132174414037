@import '../../variables';

.shop-page {
	background-color: #000;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;

	*, :after, :before {
		box-sizing: border-box;
	}
.d-none{
	display: none
}
	.top-header {
		background-image: url("../../static/images/product_man.png");
		background-size: 40%;
		background-repeat: no-repeat;
		background-position: right 65%;
		width: 100%;
		box-shadow: inset 0 83px 39px 0 rgba(0, 0, 0, 0.84);
		padding: 45px 0 0 0;
		@media (max-width: 750px) {
			background: none;
		}

		.text-info {
			padding: 20px 0 30px 0;
			max-width: 1060px;
			text-align: left;
			align-items: center;
			display: flex;
			flex-flow: row nowrap;
			margin: 0 0 0 170px;

			> div {
				display: flex;
				flex-flow: column nowrap;
			}

			@media (max-width: 1024px) {
				margin: 0 40px 0 70px;
			}

			@media (max-width: 750px) {
				background: none;
				padding: 70px 0 100px 0;
				margin: 0 40px;
			}
			@media (max-width: 420px) {
				margin: 0 10px;
				flex-flow: column nowrap;
				text-align: center;
			}

			.title {
				color: #fff;
				font-family: "YesevaOne-Regular", serif;
				font-size: 64px;
				line-height: 74px;
				padding-bottom:20px;
				@media (max-width: 750px) {
					font-size: 46px;
					line-height: 52px;
				}
			}

			.subtitle {
				color: $light-gold;
				font-family: "Avenir-Light", serif;
				font-weight: 300;
				font-size: 22px;
				line-height: 30px;
				@media (max-width: 750px) {
					font-size: 18px;
					line-height: 22px;
				}
			}

			.consult-btn {
				font-family: "Avenir-Book", serif;
				font-weight: 300;
				padding: 13px 20px !important;
				font-size: 18px;
				line-height: 25px;
				color: #fff;
				background-color: $light-gold;
				outline: none;
				border: none;
				cursor: pointer;
				text-decoration: none;
				height: 56px;
				text-align: center;
				width: 250px;
				display: flex;
				align-items: center;
				margin: 0 0 0 60px;
				@media (max-width: 750px) {
					font-size: 16px;
					line-height: 23px;
					padding: 10px 15px;
				}
				@media (max-width: 420px) {
					margin: 30px 0 0 0;
					text-align: center;
					justify-content: center;
				}
			}
		}
	}

	.categories-section {
		background-color: #fff;
		padding-top: 8px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		.trusted-name {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			box-sizing: border-box;
			background-color: #f2f2f2;
			padding: 30px 10px;
			margin: 41px 0;

			@media (max-width: 720px) {
				margin: 7px 0;
				padding: 70px 10px;
			}
			.title {
				font-family: "YesevaOne-Regular", serif;
				font-size: 48px;
				line-height: 56px;
				text-align: center;
				@media (max-width: 750px) {
					font-size: 37px;
					line-height: 42px;
				}
			}
			.trusted-brands {
				display: flex;
				width: 100%;
				justify-content: center;
				align-items: center;
				margin-top: 30px;

				img {
					flex-shrink: 0;
					height: 80px;
					&.accredited-business {
						margin: 0 80px;
						display: inline-block;
					}
				}
				@media (max-width: 720px) {
					flex-direction: column;
					margin-top: 0;
					img {
						margin-top: 50px;
						&.accredited-business {
							margin-top: 70px;
						}
					}
				}
			}
		}
		.category-item {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 50px 43px;
			max-width: 1125px;
			padding: 46px 20px;
			border-bottom: 1px solid $main-gold;
			&:nth-child(2) {
				border-bottom: 0;
			}
			&:last-child {
				border-bottom: none;
			}

			@media (max-width: 959px) {
				grid-template-columns: 1fr;
				gap: 23px 0;
			}

			@media (max-width: 720px) {
				padding: 12px 20px;
				border-top: none;
				border-bottom: none;
			}

			@media only screen and (min-width: 960px) and (max-width: 1220px)  {
				grid-template-columns: 2fr;
			}

			.category-description {
				background-color: rgba(204, 150, 71, 0.29);
				padding: 40px 30px;
				color: #000;
				width: 355px; //new

				.title {
					font-family: "YesevaOne-Regular", serif;
					font-size: 48px;
					line-height: 56px;
					@media (max-width: 750px) {
						font-size: 37px;
						line-height: 42px;
					}
				}

				.description {
					font-family: "Avenir-Book", serif;
					font-weight: 300;
					font-size: 18px;
					line-height: 25px;

					p {
						margin: 15px 0;
					}

					@media (max-width: 959px) {
						font-size: 16px;
						line-height: 20px;
						p {
							margin: 15px 0;
						}
					}

					.link-learn-more {
						font-family: "Avenir-Heavy", serif;
						color: #0e1ffb;
						font-size: 18px;
						text-decoration: none;
						cursor: pointer;
					}

					.category-notes {
						font-family: "Avenir-Book", serif;
						font-size: 12px;
						line-height: 16px;
						display: flex;
						flex-direction: column;
					}
				}
			}

			.product-item {
				padding: 40px 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				background-color: #f2f2f2;
				color: #000;
				text-align: center;
				font-family: "Avenir-Book", serif;
				font-size: 14px;
				line-height: 19px;
				font-weight: 300;
				justify-content: space-between;
				@media (max-width: 959px) {
					.top-block,
					.bottom-block {
						// max-width: 450px;
						max-width: 320px;
					}
				}

				

				&:nth-child(2n):not(.combo) {
					grid-column-start: 2;
					@media (max-width: 959px) {
						grid-column-start: 1;
					}
				}

				&.combo {
					grid-column-start: 2;
					grid-column-end: 4;
					@media (max-width: 959px) {
						grid-column-start: 1;
						grid-column-end: 2;
					}

					@media (max-width: 768px) {
						grid-column-start: 1;

						.top-block,
						.bottom-block {
							// max-width: 450px;
							max-width: 320px !important;
						}
					}

					@media only screen and (min-width: 960px) and (max-width: 1220px)  {
						grid-column-start: 2;
    					grid-column-end: initial;
						.top-block,
						.bottom-block {
							// max-width: 450px;
							max-width: 320px !important;
						}
					}

					.top-block,
					.bottom-block {
						max-width: 450px;
						// max-width: 320px;
					}
				}

				.top-block {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 0 30px;
					max-width: 320px;
					.form-image {
						height: 250px;

						&.combo {
							height: 300px;
						}

						img {
							height: 100%;
						}
					}

					.product-name {
						font-family: "YesevaOne-Regular", serif;
						font-size: 28px;
						line-height: 35px;
						margin-top: 20px;
					}

					.combo-text {
						font-family: "YesevaOne-Regular", serif;
						font-size: 25px;
						line-height: 29px;
						color: $main-gold;
					}

					.product-subname {
						font-size: 18px;
						font-family: "Avenir-Heavy", serif;
						line-height: 24px;
						@media (max-width: 959px) {
							font-size: 16px;
							line-height: 20px;
						}
					}

					.product-description {
						margin-top: 30px;
						font-size: 16px;
						line-height: 19px;
						@media (max-width: 959px) {
							line-height: 22px;
						}
					}
				}

				.bottom-block {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: 50px;
					width: 100%;
					.learn-more {
						a {
							display: inline-block;
							color: $main-gold;
							margin: 30px 0 20px 0;
							cursor: pointer;
							font-family: "Avenir-Heavy", serif;
							text-decoration: none;
						}
					}

					.starting-at {
						font-weight: 900;
					}

					.price {
						font-family: "YesevaOne-Regular", serif;
						color: $main-gold;
						display: flex;
						align-items: flex-end;
						margin: 10px 0;
					}

					.dollar-sign {
						font-size: 27px;
						line-height: 37px;
					}

					.digits {
						font-size: 35px;
						line-height: 40px;
					}

					.per-form {
						line-height: 30px;
						color: #000;
						font-family: "Avenir-Heavy", serif;
						display: inline-block;
						margin-left: 5px;
					}

					.products-buttons {
						display: flex;
						width: 100%;
						justify-content: space-between;
						padding: 0 10px;
						box-sizing: border-box;
						@media (max-width: 1030px) {
							flex-direction: column;
							justify-content: center;
							align-items: center;
						}
						.learn-more-btn-link {
							width: 100%;
							max-width: calc(50% - 5px);
							@media (max-width: 1030px) {
								width: 160px;
								max-width: 160px;
								display: inline-block;
								margin-bottom: 20px;
							}
							.learn-more-btn {
								color: $main-gold;
								font-size: 14px;
								line-height: 20px;
								font-family: "Avenir-Book", serif;
								font-weight: 300;
								padding: 12px 10px;
								border: 2px solid $main-gold;
								background-color: transparent;
								outline: none;
								cursor: pointer;
								margin-right: 10px;
								width: 100%;
								height: 50px;
							}
						}
						.get-started-btn {
							color: #fff;
							font-size: 14px;
							line-height: 20px;
							font-family: "Avenir-Book", serif;
							font-weight: 300;
							padding: 15px 10px;
							background-color: $main-gold;
							outline: none;
							border: 0;
							cursor: pointer;
							max-width: calc(50% - 5px);
							width: 100%;
							height: 50px;
							@media (max-width: 1030px) {
								width: 160px;
								max-width: 160px;
							}
						}
					}
				}
			}
		}
	}

	.jump-to-nav {
		&-wrapper {
			font-size: 19px;
			line-height: 30px;
		}

		padding: 6px 0 0 0;
	}
}
