@import '../../../../variables';

.shipping-address-item {
	display: flex;
	align-items: center;
	padding: 25px 0;
	border-bottom: 1px solid #e9e9e9;
	.address-info {
		width: 80%;
		display: flex;
		align-items: center;
		.street,
		.suite,
		.city,
		.province,
		.country,
		.postal {
			margin-right: 2%;
			&-title {
				font-family: "Avenir-Book", serif;
				font-size: 11px;
				line-height: 15px;
				color: $main-gold;
			}
			&-text {
				font-family: "Avenir-Book", serif;
				font-size: 16px;
				line-height: 22px;
				color: #000;
			}
		}
		.street {
			width: 21%;
			word-break: break-all;
		}
		.suite,
		.city,
		.province,
		.country {
			width: 13%;
		}
		.postal {
			width: 15%;
		}
		@media (max-width: 900px) {
			flex-wrap: wrap;
			.street,
			.suite,
			.city,
			.province,
			.country,
			.postal {
				width: 31.3%;
			}
		}
		@media (max-width: 600px) {
			flex-wrap: wrap;
			.street,
			.suite,
			.city,
			.province,
			.country,
			.postal {
				width: 46%;
			}
		}
	}
	.primary {
		width: 20%;
		.primary-block {
			display: flex;
			align-items: center;
			.check-icon {
				width: 18px;
				height: 18px;
				border: 2px solid $main-gold;
				padding: 2px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 5px;
				flex-shrink: 0;
				svg {
					width: 100%;
					height: 100%;
					fill: $main-gold;
				}
			}
			.primary-text {
				font-family: "Avenir-Book", serif;
				font-size: 11px;
				line-height: 15px;
				color: #000;
			}
		}
		button.set-primary {
			background: none;
			border: 2px solid $main-gold;
			padding: 4px 8px;
			outline: none;
			cursor: pointer;
			font-family: "Avenir-Book", serif;
			font-size: 11px;
			line-height: 15px;
			color: #000;
		}
	}
}
