@import '../../variables';

.upload {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 3px 0;
	color: $main-gold;
	font-size: 12px;
	font-family: "Avenir-Book", serif;
	@media (max-width: 600px) {
		flex-direction: column;
	}
	@media (max-width: 370px) {
		margin: 3px 0;
	}

	.photo {
		display: flex;
		padding: 5px 10px;
		border: 2px solid $main-gold;
		background-color: #ffffff;
		text-transform: uppercase;
		box-sizing: border-box;
		align-items: center;
		cursor: pointer;
		font-size: 16px;
		min-width: 150px;
		justify-content: center;
		margin: 3px 0;
		@media (max-width: 1050px) {
			min-width: 100px;
		}
		@media (max-width: 959px) {
			min-width: 150px;
		}
		@media (max-width: 380px) {
			width: 100%;
		}
		p {
			font-family: "Avenir-Book", serif;
			font-size: 11px;
			line-height: 15px;
			color: $main-gold;
		}
		img {
			height: 20px;
			margin-right: 5px;
			order: -1;
			margin-right: 10px;
			@media (max-width: 1140px) {
				height: 16px;
			}
			@media (max-width: 959px) {
				height: 20px;
			}
			@media (max-width: 400px) {
				height: 16px;
			}
		}
		input {
			display: none;
		}
		.photo-text {
			padding: 0 5px 0 0;
			white-space: nowrap;
		}
		&.webcam {
			@media (max-width: 768px) {
				display: none;
			}
		}

		&:first-child {
			margin-right: 5px;
		}
	}
}
