@import '../../variables';

.custom-input-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	.label {
		font-family: "Avenir-Book", serif;
		font-size: 14px;
		line-height: 20px;
		color: $main-gold;
	}
	.custom-input {
		border: 2px solid $main-gold;
		height: 100%;
		outline: none;
		box-sizing: border-box;
		width: 100%;
		padding: 8px 10px;
		background-color: #fff;
		font-family: "Avenir-Book", serif;
		font-size: 14px;
		&::placeholder {
			color: #4a4a4a;
		}
		&:focus {
			outline: 1px auto black;
		}
	}
}
