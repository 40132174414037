@import '../../variables';

.profile-page-wrapper {
	background-color: #dedede;
	width: 100%;
	padding: 70px 0;
	min-height: calc(100vh - 110px);
	.profile-page-navigation {
		width: 100%;
		border-bottom: 1px solid $main-gold;
		margin-top: 40px;
		ul {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: "Avenir-Book", serif;
			font-size: 25px;
			line-height: 34px;
			text-align: center;
			@media (max-width: 960px) {
				flex-direction: column;
			}
			a {
				color: #585858;
				display: inline-block;
				text-decoration: none;
				margin-right: 40px;
				text-align: center;
				border-bottom: 5px solid #dedede;
				cursor: pointer;
				li {
					text-align: center;
				}
				&:last-child {
					margin-right: 0;
				}
				&.active,
				&:hover {
					border-bottom: 5px solid $main-gold;
					color: #000000;
					font-family: "Avenir-Medium", serif;
				}
				@media (max-width: 960px) {
					margin-right: 0;
				}
				&.account-info-link {
					width: 228px;
					&.active,
					&:hover {
						letter-spacing: -0.24px;
					}
				}
				&.payment-billing-link {
					width: 198px;
					&.active,
					&:hover {
						letter-spacing: -0.16px;
					}
				}
				&.order-history-link {
					width: 152px;
					&.active,
					&:hover {
						letter-spacing: -0.39px;
					}
				}
				&.transfer-rx-link {
					width: 226px;
					&.active,
					&:hover {
						letter-spacing: -0.39px;
					}
				}
			}
		}
	}
	.profile-active-subscriptions {
		background-color: #fff;
		padding: 32px;
		max-width: 750px;
		margin: 50px auto 0 auto;
		.profile-welcome {
			.welcome {
				font-family: "YesevaOne-Regular", serif;
				font-size: 25px;
				line-height: 29px;
				color: $main-gold;
			}
			.welcome-name {
				font-family: "Avenir-Book", serif;
				font-size: 20px;
				line-height: 27px;
				color: #000;
			}
		}
		.no-subscriptions {
			margin: 15px 0;
			width: 100%;
			font-family: "Avenir-Book", serif;
			color: #000;
		}
		.active-subscription-item {
			display: flex;
			flex-direction: column;
			margin: 25px 0;
			&:last-child {
				margin-bottom: 0;
			}
			.subscription-item-info {
				display: flex;
				width: 100%;
				justify-content: space-between;

				.item-product-name,
				.item-orders,
				.item-prescription-expires {
					width: 33.33%;
					display: flex;
					align-items: flex-end;
					.product-name-title,
					.orders-title,
					.prescription-expires-title {
						font-family: "Avenir-Book", serif;
						font-size: 11px;
						line-height: 13px;
						color: $main-gold;
						display: inline-block;
						margin-right: 4px;
						.product-name,
						.orders,
						.expires {
							font-family: "Avenir-Book", serif;
							font-size: 16px;
							line-height: 22px;
							color: #000;
						}
					}
				}
				.item-orders {
					justify-content: center;
				}
				.item-prescription-expires {
					justify-content: flex-end;
				}
				@media (max-width: 650px) {
					flex-direction: column;
					.item-product-name,
					.item-orders,
					.item-prescription-expires {
						width: 100%;
						justify-content: flex-start;
						margin: 7px 0;
					}
				}
			}
			.progress-bar {
				width: 100%;
				height: 10px;
				background-color: #f2f2f2;
				border-radius: 8px;
				margin-top: 10px;
				display: flex;
				align-items: center;
				.fill {
					height: 6px;
					border-radius: 5px;
					background-color: $main-gold;
				}
				@media (max-width: 650px) {
					margin-bottom: 30px;
				}
			}
		}
	}
}
