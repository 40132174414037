@import '../../variables';

.billing-cards-component {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 60px;
	font-size: 14px;
	font-family: "Avenir-Medium", serif;
	&__content {
		flex: auto;
		.micro-header {
			opacity: 0.5;
			padding: 20px 0 10px 0;
			font-size: 14px;
		}
		.billing-card {
			display: flex;
			flex-direction: row;
			&.row-margin {
				margin: 0 0 10px 0;
			}
			.input-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				color: #000000;
				cursor: pointer;
				width: 100%;
				overflow: hidden;
				font-family: "Avenir-Heavy", serif;
				padding: 5px 0;
				line-height: 1.2em;
				input {
					position: absolute;
					opacity: 0;
					height: 0;
					width: 0;
					font-family: "Avenir-Heavy", serif;
					&:-webkit-autofill {
						-webkit-box-shadow: 0 0 0 1000px #ffffff inset;
						box-shadow: 0 0 0 1000px #ffffff inset;
					}
					&:checked ~ .checkmark {
						background: $main-gold;
					}
				}
				.checkmark {
					flex: none;
					height: 13px;
					width: 13px;
					background-color: #f0f0f0;
					border-radius: 50%;
					border: 1px solid #a7a7a7;
					margin: 0 10px 0 0;
					&::after {
						content: "";
						position: absolute;
						display: none;
					}
				}
				.card-address {
					display: flex;
					min-height: 20px;
					align-items: center;
				}
			}
			.primary-text {
				color: $main-gold;
				font-family: "Avenir-Medium", serif;
				font-size: 14px;
				width: 98px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
			}
			.set-primary {
				background: transparent;
				border: 0;
				cursor: pointer;
				color: $main-gold;
				font-family: "Avenir-Heavy", serif;
				font-size: 13px;
			}
		}
	}
}
