@import "../../../../variables";

.add-billing-card-block {
	margin-top: 20px;
	.validation-error {
		font-family: "Avenir-Book", serif;
		font-size: 14px;
		line-height: 20px;
		color: #e26161;
		margin-top: 10px;
	}
	.add-card-button {
		font-family: "Avenir-Book", serif;
		font-size: 16px;
		line-height: 22px;
		color: $main-gold;
		display: flex;
		align-items: center;
		cursor: pointer;
		width: fit-content;
		padding-right: 40px;
		.plus {
			font-family: "Avenir-Heavy", serif;
			margin-right: 10px;
		}
	}
	.card-form {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.card-number {
			width: 100%;
			.card-number-title {
				font-family: "Avenir-Book", serif;
				font-size: 14px;
				line-height: 20px;
				color: $main-gold;
			}
			.card-number-wrapper {
				border: 2px solid $main-gold;
				padding: 0;
				background-color: #fff;
				font-family: "Avenir-Book", serif;
				font-size: 14px;
			}
		}
	}
	.radio-input-container {
		font-family: "Avenir-Heavy", serif;
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		min-height: 30px;
		align-items: center;
		color: #000000;
		cursor: pointer;
		width: 100%;
		overflow: hidden;
		padding: 0;
		margin-top: 15px;
		line-height: 1.2em;
		font-size: 14px;

		input {
			position: absolute;
			opacity: 0;
			height: 0;
			width: 0;
			font-family: "Avenir-Heavy", serif;

			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 1000px #ffffff inset;
				box-shadow: 0 0 0 1000px #ffffff inset;
			}

			&:checked ~ .checkmark {
				background: $main-gold;
			}
		}

		.checkmark {
			flex: none;
			height: 13px;
			width: 13px;
			background-color: #f0f0f0;
			border-radius: 50%;
			border: 1px solid #a7a7a7;
			margin-left: 0;

			&::after {
				content: "";
				position: absolute;
				display: none;
			}
		}

		span {
			margin-right: 0.5em;
			margin-left: 0.5em;
		}
	}
	.radio-input-container {
		font-size: 18px !important;
		line-height: 25px !important;
		font-family: Avenir-Book !important;
		color: $main-black !important;

		span {
			margin: 0 !important;
		}

		.checkmark {
			height: 13px !important;
			width: 13px !important;
			margin: 0 8.5px 0 0 !important;
		}
	}
	.add-card-address-form {
		display: flex;
		flex-wrap: wrap;
		margin-top: 15px;
		.street,
		.suite,
		.city,
		.province,
		.country {
			margin-right: 1%;
		}
		.street {
			width: 20%;
		}
		.suite {
			width: 10%;
		}
		.city {
			width: 15%;
		}
		.province {
			width: 20%;
		}
		.country {
			width: 10%;
		}
		.postal {
			width: 17%;
		}
		@media (max-width: 980px) {
			.city,
			.suite,
			.street {
				margin-bottom: 20px;
			}
			.city {
				margin-right: 0;
			}
			.street,
			.suite,
			.province,
			.country {
				margin-right: 2%;
			}
			.street {
				width: 40%;
			}
			.suite {
				width: 26%;
			}
			.city {
				width: 30%;
			}
			.province {
				width: 40%;
			}
			.country {
				width: 26%;
			}
			.postal {
				width: 30%;
			}
		}
		@media (max-width: 570px) {
			.city,
			.suite,
			.street,
			.province,
			.country,
			.postal {
				margin-bottom: 20px;
				margin-right: 0;
				width: 49%;
			}
			.street,
			.city,
			.country {
				margin-right: 2%;
			}
		}
		@media (max-width: 450px) {
			.city,
			.suite,
			.street,
			.province,
			.country,
			.postal {
				margin-bottom: 20px;
				margin-right: 0;
				width: 100%;
			}
		}
	}
	.is-set-to-primary {
		display: flex;
		align-items: center;
		margin-top: 15px;
		label {
			display: none;
		}
		.is-set-to-primary-text {
			margin-left: 10px;
			font-family: "Avenir-Book", serif;
			font-size: 11px;
			line-height: 15px;
			color: #000;
			span {
				font-weight: bold;
			}
		}
	}
	.action-buttons {
		display: flex;
		margin-top: 20px;
		button {
			outline: none;
			padding: 5px;
			border: none;
			background-color: $main-gold;
			margin-right: 20px;
			padding: 7px 35px;
			font-family: "Avenir-Book", serif;
			font-size: 16px;
			color: #fff;
			cursor: pointer;
		}
	}
}
