@import "src/variables";

.card-number {
	display: flex;
	flex-flow: column nowrap;

	.titles {
		display: flex;
		font-family: "Avenir-Heavy", serif;
		font-size: 14px;
		line-height: 25px;
		color: $main-gold;
		position: relative;

		.card-number-title {
			width: 207px;
			&.shrinked {
				width: 160px;
			}
			@media (max-width: 531px) {
				width: 130px;
			}
		}

		.expiry-date-title {
			width: 120px;
			&.shrinked {
				width: 80px;
			}
			@media (max-width: 531px) {
				width: 80px;
			}
		}

		.cvd-title {
			width: 50px;
			text-align: center;
			margin: 0;

			.csv-help-open {
				cursor: pointer;
			}

			img {
				margin: 0 0 0 7px;
				width: 22px;
				height: 22px;
				position: relative;
				top: -2px;
			}
		}
	}

	&-wrapper {
		display: flex;
		align-items: center;
	}

	&__cardImg {
		width: 50px;
		height: 35px;

		&:not(:last-of-type) {
			margin: 0 10px 0 0;
		}
	}

	&__cardIcons {
		display: flex;
		margin: 0 0 20px 0;
	}

	.show-csv-help-wrapper {
		position: relative;
		max-width: 400px;
		.close {
			position: absolute;
			top: 9px;
			right: 9px;
			cursor: pointer;
			width: 25px;
			height: 25px;
			border: 1px solid $main-gold;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 50%;
				height: 50%;
			}
		}
		.title {
			width: 100%;
			box-sizing: border-box;
			padding-right: 40px;
			font-family: "Avenir-Book", serif;
			font-size: 14px;
			line-height: 20px;
		}
		img.help-image {
			max-width: 100%;
			margin-top: 15px;
		}
	}
}


.paymentform{
	width: 100%;
    height: 225px;
    background-color: #fff;
    position: relative;
}

#billing iframe{
		height: 230px !important;
		width: 87%;
	body {
		height: 200px !important;
	}

	@media (max-width: 568px) {
		width: 100%;
	}
}

.paymentInputs{
	width: 100%;
	float: left;
	overflow: hidden;
    // margin-left: 5px;
    height: 235px;
}


.paymentLabels{
	width: 87%;
	float: left;
	display: block;
	.cardLabel{
		width: 100%;
		float: left;
		display: block;
		position: absolute;
		top: 10%;
		bottom: auto;
		left: auto;
		z-index: 10;

		span{
			font-size: 16px;
			font-weight: bold;
			font-family: 'Avenir-Book';
		}
	}

	.expiry{
		width: 50%;
		float: left;
		display: block;
		position: absolute;
		top: 59%;
		bottom: auto;
		z-index: 10;
		left: auto;

		span{
			font-size: 16px;
			font-weight: bold;
			font-family: 'Avenir-Book';
		}
	}

	.cvc{
		width: 50%;
		float: left;
		display: block;
		position: absolute;
		top: 59%;
		bottom: auto;
		z-index: 10;
		left: 45%;

		span{
			font-size: 16px;
			font-weight: bold;
			font-family: 'Avenir-Book';
		}
	}

}

@media screen and (max-width: 359px) {

	.paymentLabels .cvc {
		left: 51% !important;
	}
}

@media screen and (max-width: 479px) {
	.paymentLabels .cvc{
		left: 52% !important;
	}
}

@media screen and (max-width: 569px) {

	.paymentLabels .cvc{
		left: 52%;
	}
}