@import "../../../../variables";
@import "../../styles";

.identification-wrapper {
	display: flex;
	flex-direction: column;

	&__title {
		@include header;
		padding: 0 0 35px 0;
	}

	&__subtitle {
		font-family: Avenir-Book, serif;
		font-size: 18px;
		color: $main-black;
		line-height: 25px;
		text-align: center;
	}

	.question-block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		line-height: 25px;
		font-size: 18px;

		&:last-of-type {
			margin-bottom: 10px;
		}

		.question {
			font-family: "Avenir-Heavy", serif;
			color: $main-gold;
			margin: 38px 0 20px 0;

			span {
				color: #979797;
				font-family: "Avenir-Book", serif;
				font-weight: normal;
			}
		}

		.popup-question {
			color: $main-black;
			text-decoration: underline;
			font-family: "Avenir-Heavy", serif;
			margin-top: 50px;
			cursor: pointer;
		}

		.answer {
			font-family: "Avenir-Book", serif;
			color: $main-black;
			margin: 20px 0;

			&.first {
				color: #1B1B1B;
			}
		}
	}

	.divider {
		height: 1px;
		background-color: #979797;
		margin: 40px 0 0 0;
	}

	.id-buttons {
		display: flex;
		width: 100%;
		flex-flow: row wrap;
		align-items: center;
		@media (max-width: 380px) {
			flex-direction: column;
		}

		.photo {
			height: 47px;
			margin: 0 22px 0 0;
			width: 192px;
			@media (max-width: 489px) {
				margin: 0 0 10px 0;
			}
		}

		.upload p {
			font-family: "Avenir-Book", serif;
			font-size: 18px;
			line-height: 25px;
			color: $main-gold;
		}

		.view-img {
			display: flex;
			padding: 5px 10px;
			border: 2px solid $main-gold;
			background-color: #ffffff;
			text-transform: uppercase;
			box-sizing: border-box;
			align-items: center;
			cursor: pointer;
			justify-content: center;
			font-size: 18px;
			line-height: 25px;
			width: 192px;
			height: 47px;

			@media (max-width: 489px) {
				margin: 0 0 10px 0;
			}

			p {
				font-family: "Avenir-Book", serif;
				font-size: 18px;
				line-height: 25px;
				color: $main-gold;
			}

			img {
				height: 20px;
				margin-right: 5px;
				@media (max-width: 1140px) {
					height: 16px;
				}
				@media (max-width: 959px) {
					height: 20px;
				}
				@media (max-width: 400px) {
					height: 16px;
				}
			}
		}
	}
	.validation-id-upload {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 2em;
		color: #e26161;
		font-size: 12.8px;
	}
}
