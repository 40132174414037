@import "../../../../variables";

.account-info-wrapper {
	background-color: #fff;
	padding: 32px;
	max-width: 750px;
	margin: 50px auto 0 auto;
	@media (max-width: 400px) {
		padding: 16px;
	}
	.input-edit {
		display: flex;
		.custom-input-wrapper:last-child {
			padding-left: 5px;
		}
	}
	.exit-modal {
		max-width: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.title {
			font-family: "YesevaOne-Regular", serif;
			font-size: 25px;
			line-height: 29px;
			color: #000;
		}
		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			p {
				text-align: center;
				margin-top: 15px;
				margin-bottom: 10px;
				font-size: 16px;
				line-height: 22px;
				color: #000000;
				display: inline-block;
			}
		}
		.button-links {
			display: flex;
			width: 100%;
			justify-content: center;
			margin-top: 20px;

			button {
				font-family: "Avenir-Book", serif;
				background-color: $main-gold;
				outline: none;
				width: 130px;
				font-size: 18px;
				cursor: pointer;
				line-height: 25px;
				color: #ffffff;
				border: none;
				padding: 12px 0;
			}
		}
	}
	.input-date {
		border: 2px solid $main-gold;
		height: 100%;
		outline: none;
		box-sizing: border-box;
		width: 100%;
		padding: 6px 10px;
		background-color: #fff;
		font-family: "Avenir-Book", serif;
		font-size: 14px;
		&::placeholder {
			color: #4a4a4a;
		}
		&:focus {
			outline: 1px auto black;
		}
	}
	.user-birthdate-text {
		input[type="date"] {
			background-color: white;
		}
	}
	.title {
		font-family: "YesevaOne-Regular", serif;
		font-size: 25px;
		line-height: 29px;
		color: #000;
	}
	.subtitle {
		font-family: "Avenir-Book", serif;
		font-size: 11px;
		line-height: 15px;
		color: $main-gold;
	}
	.user-info {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		@media (max-width: 800px) {
			flex-wrap: wrap;
		}
		.user-name,
		.user-birthdate,
		.user-email,
		.user-phone {
			// max-width: 25%;
			flex-shrink: 0;
			@media (max-width: 800px) {
				width: 50%;
				max-width: 50%;
			}
			@media (max-width: 500px) {
				width: 100%;
				max-width: 100%;
			}
		}

		.user-name-title,
		.user-birthdate-title,
		.user-email-title,
		.user-phone-title {
			font-family: "Avenir-Book", serif;
			font-size: 11px;
			line-height: 15px;
			color: $main-gold;
		}
		.user-name-text,
		.user-birthdate-text,
		.user-email-text,
		.user-phone-text {
			font-family: "Avenir-Book", serif;
			font-size: 16px;
			line-height: 22px;
			color: #000;
		}
	}
	.action-btns {
		margin: 25px 0 50px 0;
		@media (max-width: 600px) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		button {
			background-color: $main-gold;
			outline: none;
			border: none;
			cursor: pointer;
			padding: 16px;
			color: #fff;
			font-family: "Avenir-Book", serif;
			font-size: 18px;
			line-height: 22px;
			min-width: 240px;
			margin-bottom: 20px;
			&:first-child {
				margin-right: 30px;
				@media (max-width: 600px) {
					margin-right: 0;
				}
			}
		}
	}
	.emtyPrfileContent {
		padding: 0.7rem;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		border-radius: 5px;
		background-color: #ffcccb;
		display: flex;
		img {
			margin-right: 10px;
			width: 1rem;
			height: 1rem;
		}
		p {
			color: #ff0404;
			margin-top: 2px;
			font-size: 13px;
		}
	}
	.success-upload-id {
		display: flex;
		text-align: right;
		width: 100%;
		font-size: 16px;
		color: #4bb543;
		img {
			height: 16px;
			margin: 0 10px;
		}
	}
	.view-img {
		display: flex;
		padding: 5px 10px;
		border: 2px solid $main-gold;
		background-color: #ffffff;
		text-transform: uppercase;
		box-sizing: border-box;
		align-items: center;
		cursor: pointer;
		font-size: 16px;
		min-width: 150px;
		justify-content: center;
		margin: 6px 0 6px 6px;
		@media (max-width: 380px) {
			margin-left: 0;
		}
		p {
			font-family: "Avenir-Book", serif;
			font-size: 11px;
			line-height: 15px;
			color: $main-gold;
		}
		img {
			height: 20px;
			margin-right: 5px;
			@media (max-width: 1140px) {
				height: 16px;
			}
			@media (max-width: 959px) {
				height: 20px;
			}
			@media (max-width: 400px) {
				height: 16px;
			}
		}
	}
	.img-buttons {
		display: flex;
		@media (max-width: 380px) {
			flex-direction: column;
		}
	}
	.insurance-upload-block {
		margin: 50px 0;
	}
	.change-pass-modal {
		display: flex;
		flex-direction: column;
		width: 500px;
		@media (max-width: 700px) {
			width: 350px;
		}
		@media (max-width: 500px) {
			width: 100%;
		}
		.title {
			margin-bottom: 20px;
		}
		input {
			margin-bottom: 15px;
		}
		.action-buttons {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;
			button {
				outline: none;
				padding: 5px;
				border: none;
				background-color: $main-gold;
				width: 45%;
				font-family: "Avenir-Book", serif;
				font-size: 16px;
				color: #fff;
				cursor: pointer;
			}
		}
	}
	.validation-error {
		font-family: "Avenir-Book", serif;
		font-size: 14px;
		line-height: 20px;
		color: #e26161;
	}
}
