@import '../../variables';

.image-view-component {
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	background-color: rgba(219, 219, 219, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Avenir-Medium", serif;
	font-size: 13px;
	.box {
		position: relative;
		max-width: 90vw;
		min-width: 320px;
		box-sizing: border-box;
		min-height: 150px;
		background: #ebebeb;
		border: 10px solid #ffffff;
		.close {
			position: absolute;
			top: 9px;
			right: 9px;
			cursor: pointer;
			width: 25px;
			height: 25px;
			border: 1px solid $main-gold;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 50%;
				height: 50%;
			}
		}
		.title {
			text-align: center;
			padding: 10px 0 10px 0;
			font-size: 24px;
			letter-spacing: 0.06em;
			font-family: YesevaOne-Regular;
		}
		.container {
			box-sizing: border-box;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 2em;
			img {
				width: 100%;
				height: 100%;
				max-height: calc(90vh - 44px);
				max-width: 100%;
			}
			iframe {
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}
