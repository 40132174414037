@import "../../../../variables";

.cart-summary-component {
  padding: 25px 45px 25px 45px;
  background-color: #ebebeb;
  color: $main-black;
  border-radius: 4px;

  @media (max-width: 450px) {
    padding: 25px 15px 25px 15px;
  }

  .row {
    display: flex;
    flex-direction: row;

    .col-1 {
      width: 55px;
      margin-right: 25px;

      img {
        width: 100%;
      }

      @media (max-width: 450px) {
        display: none;
      }
    }

    .col-2 {
      flex: 1;
      font-family: Avenir-Book, serif;
      line-height: 25px;
      border-bottom: 1px solid $main-gold;
      padding-bottom: 23px;
      margin-bottom: 25px;

      @media (max-width: 450px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      &.last {
        padding-bottom: 0;
        margin-bottom: 0;
      }
      &.slim-margin {
        margin-bottom: 13px;
      }
      &.slim {
        padding-bottom: 13px;
        margin-bottom: 15px;
      }
      &.no-border {
        border: none;
      }

      .product-name {
        font-family: Avenir-Heavy, serif;
        font-size: 24px;
        line-height: 35px;
      }
      .product-quantity {
        padding-bottom: 5px;
      }
      .container {
        display: flex;

        .refill-icon {
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }
        .text {
          flex: 1;
          color: $main-black !important;

          &.free {
            color: $main-gold !important;
            font-size: 16px;
            font-family: Avenir-Heavy, serif;
          }
        }
        .price {
          align-self: flex-end;
          padding-right: 30px;
          padding-left: 20px;
          font-family: Avenir-Heavy, serif;
          font-size: 16px;
          text-align: right;

          @media (max-width: 450px) {
            padding-right: 0;
          }

          &.free {
            color: $main-gold;
            font-size: 16px;
            text-transform: uppercase;
          }
        }
      }
    }

    &.total {
      .price, .text {
        font-size: 24px !important;
        font-family: Avenir-Heavy, serif !important;
      }
    }
  }
}
