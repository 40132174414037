@import '../../../../variables';

.transfer-rx-wrapper {
	.title {
		font-family: "YesevaOne-Regular", serif;
		font-size: 25px;
		line-height: 29px;
		color: #000;
	}
	.subtitle {
		font-family: "Avenir-Book", serif;
		font-size: 16px;
		line-height: 22px;
		color: #000;
	}
	.validation-message {
		margin-top: 10px;
		.validation-error {
			font-family: "Avenir-Book", serif;
			font-size: 14px;
			line-height: 20px;
			color: #e26161;
		}
	}
	
	.top-block-wrapper {
		background-color: #fff;
		padding: 32px;
		max-width: 750px;
		margin: 50px auto 0 auto;
		@media (max-width: 400px) {
			padding: 16px;
		}
		.progress-bar {
			display: flex;
			align-items: center;
			width: 100%;
			height: 10px;
			border-radius: 8px;
			background-color: #f2f2f2;
			padding: 0 2px;
			.step-progress {
				box-sizing: border-box;
				border-left: 1px solid #dbdbdb;
				width: 20%;
				height: 100%;
				&:first-child {
					border-left: none;
				}
				&.filled {
					display: flex;
					align-items: center;
					border-right: none;
					.fill {
						height: 6px;
						width: 100%;
						border-radius: 5px;
						background-color: $main-gold;
					}
				}
			}
		}
	}
	.steps-wrapper {
		background-color: #fff;
		padding: 32px;
		max-width: 750px;
		margin: 50px auto 0 auto;
		@media (max-width: 400px) {
			padding: 16px;
		}
		.id-insurance-step {
			.question-block {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				line-height: 25px;
				font-size: 18px;

				&:last-of-type {
					margin-bottom: 83px;
				}

				.question {
					font-family: "Avenir-Heavy", serif;
					color: $main-gold;

					&__withTooltip {
						.Tooltip {
							top: 3px;
							margin: 0 0 0 5px;
						}
					}

					span {
						color: #979797;
						font-family: "Avenir-Book", serif;
						font-weight: normal;
					}
				}

				.answer {
					font-family: "Avenir-Book", serif;
					color: #000000;
					margin: 20px 0;
				}
			}
			.subtitle {
				margin-top: 15px;
			}
			.id-upload-block,
			.insurance-upload-block {
				margin-top: 40px;
				.subtitle {
					font-family: "Avenir-Book", serif;
					font-size: 11px;
					line-height: 15px;
					color: #bea678;
					margin-top: 0;
					margin-bottom: 10px;
				}
				.img-buttons {
					display: flex;
					@media (max-width: 380px) {
						flex-direction: column;
					}

					.view-img {
						display: flex;
						padding: 5px 10px;
						border: 2px solid $main-gold;
						background-color: #ffffff;
						text-transform: uppercase;
						box-sizing: border-box;
						align-items: center;
						cursor: pointer;
						font-size: 16px;
						min-width: 150px;
						justify-content: center;
						margin: 6px 0 6px 6px;
						@media (max-width: 380px) {
							margin-left: 0;
						}
						p {
							font-family: "Avenir-Book", serif;
							font-size: 11px;
							line-height: 15px;
							color: $main-gold;
						}
						img {
							height: 20px;
							margin-right: 5px;
							@media (max-width: 1140px) {
								height: 16px;
							}
							@media (max-width: 959px) {
								height: 20px;
							}
							@media (max-width: 400px) {
								height: 16px;
							}
						}
					}
				}

				.success-upload {
					display: flex;
					text-align: right;
					width: 100%;
					font-size: 16px;
					color: #4bb543;
					img {
						height: 16px;
						margin: 0 10px;
					}
				}
			}
		}
		.select-product-step {
			.title {
				font-family: "Avenir-Book", serif;
				font-size: 16px;
				line-height: 22px;
				color: #000000;
			}
			.content {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				.category {
					margin-right: 10px;
					box-sizing: border-box;
					@media (max-width: 800px) {
						margin-right: 0;
					}
				}
				.product-name {
					@media (max-width: 800px) {
						margin-top: 10px;
					}
				}
				.category,
				.product-name {
					width: calc(50% - 5px);
					font-size: 11px;
					@media (max-width: 800px) {
						width: 100%;
					}
				}
				.product-quantity {
					width: 100%;
					margin-top: 10px;
				}
			}
		}
		.prescription-step {
			display: flex;
			@media (max-width: 600px) {
				flex-direction: column-reverse;
				align-items: center;
			}
			.content {
				width: 60%;
				@media (max-width: 600px) {
					width: 100%;
				}
				.title {
					font-family: "Avenir-Book", serif;
					font-size: 16px;
					line-height: 22px;
					color: #000000;
				}
				.rx-number,
				.pharmacy-name,
				.pharmacy-phone {
					margin-top: 10px;
				}
				.bottle-image-upload-block {
					margin-top: 20px;
					.subtitle {
						font-family: "Avenir-Book", serif;
						font-size: 11px;
						line-height: 15px;
						color: $main-gold;
					}
					.success-upload {
						display: flex;
						text-align: right;
						width: 100%;
						font-size: 16px;
						color: #4bb543;
						img {
							height: 16px;
							margin: 0 10px;
						}
					}
					.img-buttons {
						display: flex;
						@media (max-width: 380px) {
							flex-direction: column;
						}
						.view-img {
							display: flex;
							padding: 5px 10px;
							border: 2px solid $main-gold;
							background-color: #ffffff;
							text-transform: uppercase;
							box-sizing: border-box;
							align-items: center;
							cursor: pointer;
							font-size: 16px;
							min-width: 140px;
							justify-content: center;
							margin: 6px 0 6px 6px;
							@media (max-width: 380px) {
								margin-left: 0;
							}
							p {
								font-family: "Avenir-Book", serif;
								font-size: 11px;
								line-height: 15px;
								color: $main-gold;
							}
							img {
								height: 20px;
								margin-right: 5px;
								@media (max-width: 1140px) {
									height: 16px;
								}
								@media (max-width: 959px) {
									height: 20px;
								}
								@media (max-width: 400px) {
									height: 16px;
								}
							}
						}
					}
				}
			}
			.help-image {
				width: calc(40% - 30px);
				max-width: calc(40% - 30px);
				max-height: 330px;
				margin-left: 30px;
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				@media (max-width: 850px) {
					max-height: 250px;
				}
				@media (max-width: 600px) {
					max-width: 300px;
					max-height: 100%;
					width: 100%;
					padding: 20px 40px;
					margin-left: 0;
				}
				@media (max-width: 500px) {
					width: 100%;
					padding: 20px 30px;
				}
				img {
					height: 100%;
					@media (max-width: 600px) {
						max-height: 100%;
						width: 100%;
					}
				}
			}
		}
		.shipping-info-step,
		.billing-info-step {
			.title {
				font-family: "Avenir-Book", serif;
				font-size: 16px;
				line-height: 22px;
				color: #000000;
				&.bold {
					font-family: "Avenir-Medium", serif;
				}
			}
		}
	}
	.exit-modal {
		max-width: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.title {
			font-family: "YesevaOne-Regular", serif;
			font-size: 25px;
			line-height: 29px;
			color: #000;
		}
		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			p {
				text-align: center;
				margin-top: 15px;
				margin-bottom: 10px;
				font-size: 16px;
				line-height: 22px;
				color: #000000;
				display: inline-block;
			}
		}
		.button-links {
			display: flex;
			width: 100%;
			justify-content: center;
			margin-top: 20px;

			button {
				&:first-child {
					margin-right: 30px;
				}
				font-family: "Avenir-Book", serif;
				background-color: $main-gold;
				outline: none;
				width: 130px;
				font-size: 18px;
				cursor: pointer;
				line-height: 25px;
				color: #ffffff;
				border: none;
				padding: 12px 0;
			}
		}
	}
	.steps-navigation {
		display: flex;
		margin-top: 40px;
		button {
			font-family: "Avenir-Book", serif;
			font-size: 18px;
			line-height: 25px;
			color: #ffffff;
			border: none;
			outline: none;
			background-color: $main-gold;
			padding: 12px 0;
			cursor: pointer;
			width: 120px;
			&.next {
				margin-left: auto;
			}
		}
	}
}
