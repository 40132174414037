@import '../../variables';

.transfer-rx-component {
	position: relative;
	font-family: "Avenir-Medium", serif;
	background-color: #dbdbdb;
	padding: 70px 0 0 0;
	@media (max-width: 959px) {
		flex-direction: column;
		padding-top: 80px;
	}
	.start {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: calc(5% + 70px);
		padding-right: calc(5% + 16px);
		padding-bottom: 50px;
		max-width: 1000px;
		margin: 0 auto;
		min-height: calc(100vh - 70px);
		@media (max-width: 959px) {
			flex-direction: column;
			padding-left: 5%;
			padding-right: 5%;
			min-height: initial;
		}
		.left-part {
			flex: 1;
			padding-right: 20px;
			@media (max-width: 959px) {
				padding: 0;
			}
			.title {
				display: flex;
				font-family: "YesevaOne-Regular", serif;
				align-items: flex-end;
				font-size: 64px;
				line-height: 74px;
				@media (max-width: 750px) {
					font-size: 46px;
					line-height: 52px;
				}
				span {
					display: flex;
					flex: 0 1;
				}
				.horizontal-line {
					min-width: 40px;
					border-top: 2px solid $main-gold;
					flex: auto;
					margin-left: 20px;
					margin-bottom: 20px;
				}
			}
			.content {
				text-align: left;
				padding: 20px 0 0 0;
				font-family: "Avenir-Medium", serif;
				font-size: 15px;
				letter-spacing: 0.02em;
				line-height: 1.4em;
				p.padding-top {
					padding-top: 15px;
				}
				.start-transfer-btn {
					font-family: "Avenir-Book", serif;
					font-size: 13px;
					line-height: 18px;
					color: #fff;
					padding: 8px 25px;
					border: none;
					outline: none;
					background-color: $main-gold;
					cursor: pointer;
					margin: 25px 0;
				}
			}
		}
		.right-part {
			flex: 1;
			padding-left: 20px;
			@media (max-width: 959px) {
				padding: 20px 0 0 0;
			}
			img {
				width: 100%;
				height: auto;
				@media (min-width: 1600px) {
					width: 130%;
				}
				@media (max-width: 959px) {
					max-width: 400px;
				}
				@media (max-width: 500px) {
					max-width: 240px;
				}
			}
		}
	}
	.steps {
		background-color: #cfcfcf;
		.step {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-left: calc(5% + 70px);
			padding-right: calc(5% + 16px);
			padding-bottom: 50px;
			max-width: 1000px;
			margin: 0 auto;
			@media (max-width: 959px) {
				flex-direction: column-reverse;
				padding-left: 5%;
				padding-right: 5%;
			}
			.left-part {
				flex: 1;
				padding: 30px 20px 0 0;
				@media (max-width: 959px) {
					padding: 20px 0 0 0;
				}
				img {
					max-width: 490px;
					width: 100%;
					height: auto;
				}
			}
			.right-part {
				flex: 1;
				padding-left: 20px;
				@media (max-width: 959px) {
					padding: 20px 0 0 0;
				}
				.title {
					display: flex;
					flex-direction: row;
					padding-bottom: 10px;
					justify-content: flex-start;
					align-items: flex-end;
					height: 106px;
					font-family: "YesevaOne-Regular", serif;
					.number {
						font-size: 80px;
						padding-right: 10px;
						border-right: 1px solid #000000;
						@media (max-width: 959px) {
							font-size: 70px;
						}
					}
					.text {
						font-size: 28px;
						padding-left: 15px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						line-height: 1.4em;
						@media (max-width: 959px) {
							font-size: 28px;
							padding-left: 10px;
						}
					}
				}
				.content {
					text-align: left;
					padding: 20px 0 0 0;
					font-family: "Avenir-Medium", serif;
					font-size: 15px;
					letter-spacing: 0.02em;
					line-height: 1.4em;
					max-width: 320px;
				}
			}
		}
		.step-even {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-left: calc(5% + 70px);
			padding-right: calc(5% + 16px);
			padding-bottom: 50px;
			max-width: 1000px;
			margin: 0 auto;
			@media (max-width: 959px) {
				flex-direction: column;
				padding-left: 5%;
				padding-right: 5%;
			}
			.left-part {
				flex: 1;
				padding-left: 20px;
				@media (max-width: 959px) {
					padding: 0;
				}
				.title {
					display: flex;
					flex-direction: row;
					padding-bottom: 10px;
					justify-content: flex-start;
					align-items: flex-end;
					height: 106px;
					font-family: "YesevaOne-Regular", serif;
					.number {
						font-size: 80px;
						padding-right: 10px;
						width: 99px;
						border-right: 1px solid #000000;
						@media (max-width: 959px) {
							font-size: 70px;
						}
					}
					.text {
						font-size: 28px;
						text-transform: capitalize;
						padding-left: 15px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						line-height: 1.4em;
						@media (max-width: 959px) {
							font-size: 28px;
							padding-left: 10px;
						}
					}
				}
				.content {
					text-align: left;
					padding: 20px 0 0 0;
					font-family: "Avenir-Medium", serif;
					font-size: 15px;
					letter-spacing: 0.02em;
					line-height: 1.4em;
					max-width: 320px;
				}
			}
			.right-part {
				flex: 1;
				padding-right: 20px;
				@media (max-width: 959px) {
					padding: 20px 0 0 0;
				}
				img {
					width: 100%;
					height: auto;
					max-width: 400px;
				}
			}
		}
	}
	.start-transfer-section {
		height: 220px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: url("../../static/images/transferRxDocBottles.png") calc(40% - 400px),
			linear-gradient(90deg, #dab68d 0%, #c59c61 100%);
		background-size: auto 100%;
		background-repeat: no-repeat;
		margin-bottom: 50px;
		@media (max-width: 860px) {
			background: linear-gradient(90deg, #dab68d 0%, #c59c61 100%);
			text-align: center;
		}

		.title {
			font-family: "YesevaOne-Regular", serif;
			font-size: 48px;
			line-height:56px;
			color: #fff;
			@media (max-width: 750px) {
				font-size: 37px;
				line-height: 42px;
			}
		}
		button {
			outline: none;
			border: 2px solid $main-gold;
			background-color: #ffffff;
			font-family: "Avenir-Book", serif;
			font-size: 14px;
			line-height: 19px;
			cursor: pointer;
			color: $main-gold;
			padding: 10px 45px;
			margin-top: 15px;
		}
	}
}
