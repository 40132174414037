@import '../../variables';

.forgot-pass-component {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 50px;
	font-family: "Avenir-Medium", serif;

	.forgot-left{
		background: #050402;
		width: 50vw;
		height: 100vh;
		padding: 0;
		overflow: hidden;
		display: block;
	}

	.forgot-right{
		display: flex;
		flex-direction: column;
		width: 50vw;
		height: auto;
		padding: 50px;
		overflow: auto;
		border-left: 0;
		position: relative;
	}

	.forgot-left-image{
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.forgot-logo-img{
		flex: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 10px 0;
	}

	.forgot-logo-img img {
		height: 135px;
		max-height: 100%;
	}

	.forgot-content{
		box-sizing: border-box;
		width: 100vw;
		height: 100vh;
		position: fixed;
		// z-index: 15;
		top: 0;
		left: 0;
		flex-direction: row;
		display: flex;
		background: #fff;
	}

	.shop-link{
		color: #bb9157;
		cursor: pointer;
		position: fixed;
		top: 0;
		left: 0;
		margin-left: 5%;
		margin-top: 27px;
		text-decoration: none;
	}

	.text{
		color: #bb9157;
		cursor: pointer;
		text-decoration: none !important;
		font-weight: bold;
	}























	.forgot-pass-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		.content {
			.header {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				margin-top: 40px;
				// height: 100px;
				font-size: 28px;
				line-height: 34px;
				color: #1d1d1d;
				font-family: "YesevaOne-Regular", serif;
			}
			.micro-header {
				display: flex;
				justify-content: center;
				text-align: center;
				align-items: center;
				font-size: 16px;
				line-height: 22px;
				padding-bottom: 40px;
				margin-top: 30px;
				color: #7b7b7b;
				a {
					color: #7b7b7b;
				}
			}
			.email {
				display: flex;
				height: 35px;
				align-items: center;
				border-bottom: 1px solid $main-gold;
				color: $main-gold;
				span {
					margin-right: 1em;
					font-size: 0.9em;
				}
				input {
					border: 0;
					outline: none;
					flex: auto;
					font-family: "Avenir-Medium", serif;
					font-size: 0.9em;
					&:-webkit-autofill {
						-webkit-box-shadow: 0 0 0 1000px #ffffff inset;
						box-shadow: 0 0 0 1000px #ffffff inset;
					}
				}
			}
			.password {
				position: relative;
				z-index: 2;
				display: flex;
				max-width: 300px;
				margin: 0 auto;
				height: 35px;
				align-items: center;
				border-bottom: 1px solid $main-gold;
				color: $main-gold;

				span {
					margin-right: 0.5em;
					font-size: 0.9em;
					width: 70px;
				}

				input {
					border: 0;
					outline: none;
					flex: auto;
					font-family: "Avenir-Medium", serif;
					font-size: 0.9em;

					&:-webkit-autofill {
						-webkit-box-shadow: 0 0 0 1000px #ffffff inset;
						box-shadow: 0 0 0 1000px #ffffff inset;
					}
				}
			}
			.password {
				.eye {
					cursor: pointer;
					background: transparent url("../../static/images/eye.svg") no-repeat center center;
					width: 18px;
					height: 18px;
					display: block;
					flex-shrink: 0;
					opacity: 0.7;

					&.crossed {
						background: transparent url("../../static/images/eye-crossed.svg") no-repeat center
							center;
					}
				}

				.check {
					background: transparent url("../../static/images/_confirmGreen.svg") no-repeat center
						center;
					width: 18px;
					flex-shrink: 0;
					height: 18px;
					display: block;
					opacity: 0.7;
				}
			}
			.alert {
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: "Avenir-Medium", serif;
				font-size: 14px;
				margin-top: 10px;
				line-height: 19px;
				color: #e26161;
			}
			.register {
				display: flex;
				justify-content: center;
				align-items: center;
				// height: 100px;
				margin-top: 50px;
				font-size: 0.9em;
				letter-spacing: 0.02em;
				font-family: "Avenir-Heavy", serif;
				button {
					border: 0;
					outline: none;
					cursor: pointer;
					color: $main-gold;
					letter-spacing: 0.02em;
					font-size: 1em;
					background-color: inherit;
					font-family: "Avenir-Heavy", serif;
					margin: 0;
					padding: 0;
				}
			}
		}
		.reset-button {
			flex: none;
			width: 250px;
			font-family: "Avenir-Heavy", serif;
			letter-spacing: 0.1em;
			font-size: 14px;
			line-height: 19px;
			padding: 0;
			margin: 0 auto;
			margin-top: 50px;
			outline: none;
			cursor: pointer;
			box-sizing: border-box;
			height: 42px;
			border-width: 2px;
			border-style: solid;
			border-radius: 0;
			border-color: $main-gold;
			background-color: $main-gold;
			color: #ffffff;
			&.dark {
				background-color: $main-gold;
				color: #ffffff;
			}
		}
		.back-to-login-button {
			flex: none;
			width: 250px;
			font-family: "Avenir-Heavy", serif;
			letter-spacing: 0.1em;
			font-size: 14px;
			line-height: 19px;
			padding: 0;
			margin: 0 auto;
			margin-top: 23px;
			outline: none;
			cursor: pointer;
			box-sizing: border-box;
			height: 42px;
			border-width: 2px;
			border-style: solid;
			border-radius: 0;
			border-color: $main-gold;
			background-color: #ffffff;
			color: $main-gold;
			&.dark {
				background-color: $main-gold;
				color: #ffffff;
			}
		}
	}
}

@media (max-width: 959px){
	.forgot-left {
		display: none !important;
	}

	.forgot-right{
		width: 100vw !important;
	}

}