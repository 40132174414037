@import '../../variables';

.page-101 {
	position: relative;
	font-family: "Avenir-Book", serif;
	background-color: #dbdbdb;
	padding: 70px 0 130px 0;
	@media (max-width: 959px) {
		flex-direction: column;
		padding-top: 80px;
		padding-bottom: 100px;
	}
	.start {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: calc(5% + 70px);
		padding-right: calc(5% + 16px);
		padding-bottom: 20px;
		max-width: 1000px;
		margin: 0 auto;
		min-height: calc(100vh - 190px);
		@media (max-width: 959px) {
			flex-direction: column;
			padding-left: 5%;
			padding-right: 5%;
			padding-bottom: 30px;
		}
		.left-part {
			flex: 1;
			padding-right: 20px;
			@media (max-width: 959px) {
				padding: 0;
			}
			.title {
				display: flex;
				font-family: "YesevaOne-Regular", serif;
				font-size: 64px;
				@media (max-width: 959px) {
					font-size: 46px;
				}
				.horizontal-line {
					border-top: 2px solid $main-gold;
					flex: auto;
					margin-left: 50px;
					margin-top: 30px;
					@media (max-width: 959px) {
						margin-top: 25px;
					}
				}
			}
			.content {
				text-align: left;
				padding: 20px 0 0 0;
				font-size: 14px;
				font-family: "Avenir-Book", serif;
				letter-spacing: 0.02em;
				line-height: 18px;
				span.bold {
					font-family: "Avenir-Heavy", serif;
				}
				p.answer {
					font-style: italic;
				}
				p.padding-top {
					padding-top: 10px;
				}
			}
		}
		.right-part {
			flex: 1;
			padding-left: 20px;
			@media (max-width: 959px) {
				padding: 20px 0 0 0;
			}
			img {
				width: 100%;
				height: auto;
				@media (min-width: 1600px) {
					width: 130%;
				}
			}
		}
	}
	.limit-line-wrapper {
		padding-left: calc(5% + 70px);
		padding-right: calc(5% + 16px);
		max-width: 1000px;
		margin: 0 auto;
		@media (max-width: 959px) {
			padding: 0 5%;
		}
		.limit-line {
			border-top: 2px solid $main-gold;
			height: 1px;
		}
	}
	.causes {
		padding: 20px 20px 70px 20px;
		max-width: 900px;
		margin: 0 auto;
		@media (max-width: 959px) {
			margin: 0 5%;
		}
		.causes-title {
			font-family: "Avenir-Heavy", serif;
			text-align: center;
			font-size: 16px;
			margin: 0 auto;
			line-height: 1.4em;
			@media (max-width: 959px) {
				width: 100%;
			}
		}
		.list {
			display: flex;
			flex-direction: row;
			padding-top: 40px;
			@media (max-width: 660px) {
				flex-direction: column;
			}
			.box {
				flex: 1;

				display: flex;
				justify-content: center;
				@media (max-width: 660px) {
					justify-content: flex-start;
				}
				ul {
					li {
						display: flex;
						align-items: flex-start;
						font-size: 15px;
						font-family: "Avenir-Book", serif;
						letter-spacing: 0.02em;
						line-height: 1.4em;
						max-width: 300px;
						.dot {
							margin-top: 6px;
							flex-shrink: 0;
							background-color: $main-gold;
							border-radius: 50%;
							height: 8px;
							width: 8px;
							margin-right: 10px;
						}
						p::first-letter {
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
	.stress-image-wrapper {
		background-color: #c49b65;

		background-size: contain;
		background-repeat: no-repeat;
		background-position-x: left;
		background-position-y: top;
		@media (max-width: 1040px) {
			flex-direction: column;
		}
		.stress-image {
			display: flex;
			flex-direction: row;
			height: 400px;
			margin: 0 auto;
			background-repeat: no-repeat;
			background-position: calc(50% - 500px);
			background-size: auto 100%;
			@media (max-width: 1500px) {
				height: 300px;
				background-position: left;
			}
			@media (max-width: 1040px) {
				flex-direction: column;
				height: auto;
				padding: 0;
			}

			&.ed {
				background-image: url(../../static/images/FactArticles/ed-goldbar.png);
				background-position: left;
				@media (max-width: 959px) {
					background-image: none;
				}
			}
			&.hl {
				background-image: url(../../static/images/FactArticles/hl-goldbar.png);
				@media (max-width: 959px) {
					background-image: none;
				}
			}
			&.cs {
				background-image: url(../../static/images/FactArticles/cs-goldbar.png);
				@media (max-width: 959px) {
					background-image: none;
				}
			}
			&.acne {
				background-image: url(../../static/images/FactArticles/acne-goldbar.png);
				@media (max-width: 959px) {
					background-image: none;
				}
			}

			.image {
				display: none;
				flex: auto;
				height: 100%;
				width: 100%;
				img {
					height: auto;
					max-height: 100%;
					max-width: 100%;
				}
				@media (max-width: 959px) {
					display: block;
				}
			}
			.stress-image-content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				font-family: "YesevaOne-Regular", serif;
				width: 70%;
				line-height: 1.4em;
				max-width: 550px;
				min-width: 475px;
				margin: 0 auto;

				@media (max-width: 1340px) {
					width: 50%;
				}
				@media (max-width: 1040px) {
					width: 90%;
					margin: 0 auto;
					text-align: center;
					padding: 35px 10px 35px 10px;
					min-width: initial;
				}
				@media (max-width: 460px) {
					font-size: 20px;
					min-width: initial;
				}
				p {
					margin-bottom: 30px;
				}
				.buttons {
					display: flex;
					@media (max-width: 550px) {
						flex-direction: column;
					}
					button {
						cursor: pointer;
						background-color: #fff;
						outline: none;
						border: none;
						padding: 16px 20px;
						color: $main-gold;
						font-family: "Avenir-Book", serif;
						font-size: 18px;
						line-height: 20px;
						min-width: 200px;
					}
					a {
						&:first-child {
							margin-right: 20px;
							@media (max-width: 550px) {
								margin-right: 0;
								margin-bottom: 20px;
							}
						}
					}
				}
			}
		}
	}
	.end {
		max-width: 900px;
		margin: 0 auto;
		text-align: left;
		padding: 110px 0 0 0;
		@media (max-width: 959px) {
			margin: 0 5%;
			padding-top: 90px;
		}
		.top-text {
			font-size: 15px;
			font-family: "Avenir-Book", serif;
			letter-spacing: 0.02em;
			line-height: 1.4em;
			text-align: left;
			padding-bottom: 20px;
			width: 70%;
			margin: 0 auto;
			p.padding-top {
				padding-top: 20px;
			}
			@media (max-width: 959px) {
				width: 100%;
			}
		}
		.bottom-text {
			font-size: 15px;
			font-family: "Avenir-Book", serif;
			letter-spacing: 0.02em;
			line-height: 1.4em;
			text-align: left;
			width: 70%;
			margin: 0 auto;
			padding-bottom: 30px;
			border-bottom: 2px solid $main-gold;
			@media (max-width: 959px) {
				width: 100%;
			}
			p.padding-top {
				padding-top: 20px;
			}
		}
	}
}

.page-video {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #000;
	padding: 70px 0 130px 0;
	background-color: #dbdbdb;
	.title {
		font-family: "YesevaOne-Regular", serif;
		font-size: 64px;
		line-height: 74px;
		margin: 50px 20px 40px 20px;
		@media (max-width: 750px) {
			font-size: 46px;
			line-height: 52px;
			margin: 30px 15px 20px 15px;
		}
	}
	.video-block {
		display: flex;
		justify-content: space-between;
		max-width: 1000px;
		padding: 0 30px;

		.description {
			width: 30%;
			font-family: "Avenir-Book", serif;
			font-size: 13px;
			line-height: 18px;
			p {
				margin-bottom: 20px;
			}
		}

		video {
			width: 60%;
		}

		@media (max-width: 800px) {
			flex-direction: column;
			.description {
				width: 100%;
			}

			video {
				width: 100%;
			}
		}
		@media (max-width: 600px) {
			padding: 0 15px;
		}
	}
	.stress-image-wrapper {
		width: 100%;
		height: 200px;
		background: linear-gradient(90deg, #dab68d 0%, #c59c61 100%);
		margin-top: 130px;
		.stress-image-content {
			display: flex;
			height: 100%;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0 20px;
			p {
				font-family: "YesevaOne-Regular", serif;
				color: #ffffff;
				font-size: 16px;
				letter-spacing: 0;
				line-height: 19px;
				text-align: center;
			}
			.buttons {
				margin-top: 15px;
				text-align: center;
				@media (max-width: 400px) {
					display: flex;
					flex-direction: column;
				}
				a {
					&:first-child {
						margin-right: 15px;
						@media (max-width: 400px) {
							margin-right: 0;
							margin-bottom: 15px;
						}
					}
					button {
						box-sizing: border-box;

						height: 47px;
						width: 200px;
						border: 2px solid $main-gold;
						background-color: #ffffff;
						cursor: pointer;
						font-family: "Avenir-Book", serif;
						color: $main-gold;
						font-size: 14px;
						letter-spacing: 0;
						line-height: 19px;
						outline: none;
						@media (max-width: 530px) {
							width: 160px;
						}
						@media (max-width: 400px) {
							width: 200px;
						}
					}
				}
			}
		}
	}
}
